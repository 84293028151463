import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import reportsService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export default {
  name: "Fulfillment Hold Release",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      selectedProject: "",
      projectData: [],
      apiObj: {
        pass: 0,
        userid: 5634,
        proj_key: 0,
        part_num: "",
        bu_key: 0,
        class_key: 0,
        ware_key: 0,
        oem_key: 0,
        location: "",
        lpn_key: 0,
        row_key: 0,
        bin1: "",
        bin2: "",
        PageNumber: 0,
        export: 0,
        guid: "",
      },
      showPartNum: "",
      hideSpecific: false,
      hideAll: false,
      partSearchKey: "",
      showPartNumDetails: false,
      showPartType: false,
      partResultList: [],
      partNum: "",
      partDesc: "",
      showBusiness: false,
      selectedBusiness: "",
      businessData: [],
      showClass: false,
      selectedClass: "",
      classData: [],
      showOEM: false,
      selectedOEM: "",
      OEMData: [],
      showLocation: false,
      selectedLocation: "",
      locationData: [],
      showLPN: false,
      selectedLPN: "",
      LPNData: [],
      showWarehouse: false,
      selectedWarehouse: "",
      warehouseData: [],
      showBinRow: false,
      selectedBinRow: "",
      binRowData: [],
      showBinRange: false,
      binRangeFrom: "",
      binRangeTo: "",
      disableRun: false,
      totalRecords: 0,
      searchPartsByLoc: "",
      partsByLocItems: [],
      pageCount: 0,
      page: 1,
      exportDisable: true,
      disableBinRangeFrom: true,
      disableBinRangeTo: true,
      export50kData: false,
      exportDialog: false,
      excelName: "",
      showData: false,
      debouncedGetPartList: [],
      headersPartsByLoc: [
        { text: "Project", align: "start", value: "Project", class: "primary customwhite--text" },
        { text: "Business", value: "Business", class: "primary customwhite--text" },
        { text: "Method", value: "Method", class: "primary customwhite--text" },
        { text: "SO PO Number", value: "SO_PO_Number", class: "primary customwhite--text" },
        { text: "Item", value: "Item", class: "primary customwhite--text" },
        { text: "Description", value: "Description", class: "primary customwhite--text" },
        { text: "Class", value: "Class", class: "primary customwhite--text" },
        { text: "Model", value: "Model", class: "primary customwhite--text" },
        { text: "OEM", value: "OEM", class: "primary customwhite--text" },
        { text: "OEM Part No", value: "OEM_Partno", class: "primary customwhite--text" },
        { text: "Cost", value: "Cost", class: "primary customwhite--text" },
        { text: "Part Type", value: "Part_Type", class: "primary customwhite--text" },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        { text: "Serial No", value: "Serial_No", class: "primary customwhite--text" },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        { text: "ICCID", value: "ICCID", class: "primary customwhite--text" },
        { text: "RLP ID", value: "License_Plate", class: "primary customwhite--text" },
        { text: "CRA", value: "CRA", class: "primary customwhite--text" },
        { text: "Grade", value: "Grade", class: "primary customwhite--text" },
        { text: "Quantity", value: "Qty", class: "primary customwhite--text" },
        { text: "Date Received", value: "Date_Rcvd", class: "primary customwhite--text" },
        { text: "Age", value: "Age", class: "primary customwhite--text" },
        { text: "Last Loc Date", value: "Last_Loc_Date", class: "primary customwhite--text" },
        { text: "Last Adj Date", value: "Last_Adj_Date", class: "primary customwhite--text" },
        { text: "Bin", value: "Bin", class: "primary customwhite--text" },
        { text: "PI Zone", value: "PI_Zone", class: "primary customwhite--text" },
        { text: "Location", value: "Location", class: "primary customwhite--text" },
        { text: "Warehouse", value: "Warehouse", class: "primary customwhite--text" },
        { text: "Warranty", value: "Warranty", class: "primary customwhite--text" },
        { text: "Warranty End", value: "Warranty_End", class: "primary customwhite--text" },
      ],
      json_fields: {
        ID: "id",
        Project: "Project",
        Business: "Business",
        Method: "Method",
        "SO PO Number": "SO_PO_Number",
        Item: "Item",
        Description: "Description",
        Class: "Class",
        Model: "Model",
        OEM: "OEM",
        "OEM Part Number": "OEM_Partno",
        Cost: "Cost",
        "Part Type": "Part_Type",
        BCN: "BCN",
        "Serial Number": "Serial_No",
        ESN: "ESN",
        ICCID: "ICCID",
        "RLP ID": "License_Plate",
        CRA: "CRA",
        Grade: "Grade",
        Quantity: "Qty",
        "Date Received": "Date_Rcvd",
        Age: "Age",
        "Last Loc Date": "Last_Loc_Date",
        "Last Adj Date": "Last_Adj_Date",
        Bin: "Bin",
        "PI Zone": "PI_Zone",
        Location: "Location",
        Warehouse: "Warehouse",
        Warranty: "Warranty",
        "Warranty End": "Warranty_End",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjectData();
    this.debouncedGetPartList = Utility.debounce(this.getPartList, 500);
  },
  computed: {
    isValidComparison() {
      return parseInt(this.binRangeFrom) < parseInt(this.binRangeTo);
    },
  },
  methods: {
    // to restrict the user to add number
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.projectData = [];
      this.showPartNum = "";
      this.hideSpecific = false;
      this.hideAll = false;
      this.partSearchKey = "";
      this.showPartNumDetails = false;
      this.showPartType = false;
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
      this.showBusiness = false;
      this.selectedBusiness = "";
      this.businessData = [];
      this.showClass = false;
      this.selectedClass = "";
      this.classData = [];
      this.showOEM = false;
      this.selectedOEM = "";
      this.OEMData = [];
      this.showLocation = false;
      this.selectedLocation = "";
      this.locationData = [];
      this.showLPN = false;
      this.selectedLPN = "";
      this.LPNData = [];
      this.showWarehouse = false;
      this.selectedWarehouse = "";
      this.warehouseData = [];
      this.showBinRow = false;
      this.selectedBinRow = "";
      this.binRowData = [];
      this.showBinRange = false;
      this.binRangeFrom = "";
      this.binRangeTo = "";
      this.disableRun = false;
      this.totalRecords = 0;
      this.searchPartsByLoc = "";
      this.partsByLocItems = [];
      this.pageCount = 0;
      this.page = 1;
      this.exportDisable = true;
      this.disableBinRangeFrom = true;
      this.disableBinRangeTo = true;
      this.export50kData = false;
      this.exportDialog = false;
      this.excelName = "";
      this.showData = false;
      this.getProjectData();
    },
    //Get Project data on page load
    async getProjectData() {
      this.apiObj.pass = 0;
      this.apiObj.userid = parseInt(this.userId);
      let showMessage = false;
      let projectData = await reportsService.partsByLocation("post", this.apiObj, showMessage);
      if (projectData.message !== "NA") {
        this.projectData = projectData;
      }
    },
    //Project Selected
    async projectSelected() {
      this.apiObj.pass = 1;
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      let showMessage = false;
      let busiData = await reportsService.partsByLocation("post", this.apiObj, showMessage);
      if (busiData.message !== "NA") {
        this.businessData = busiData;
        this.selectedBusiness = "";
        this.selectedWarehouse = "";
        this.selectedClass = "";
        this.selectedOEM = "";
        this.selectedLocation = "";
        this.selectedBinRow = "";
        this.selectedLPN = "";
        this.showPartNum = true;
        this.showBusiness = false;
        this.showWarehouse = false;
        this.showClass = false;
        this.showOEM = false;
        this.showLocation = false;
        this.showLPN = false;
        this.showBinRange = false;
        this.showBinRow = false;
        this.binRangeFrom = "";
        this.binRangeTo = "";
        this.disableRun = false;
        this.hideSpecific = false;
        this.hideAll = false;
        this.pageCount = 0;
        this.exportDisable = true;
        this.totalRecords = 0;
        this.partsByLocItems = [];
        this.showData = false;
        this.clearPartShip();
      }
    },
    //All Part Clicked
    allPartNumber() {
      this.showBusiness = true;
      this.showPartType = false;
      this.disableRun = false;
      this.hideSpecific = true;
      this.partSearchKey = "";
      this.showData = false;
    },
    //Part number search event
    async searchPartNum() {
      this.partSearchKey = this.partSearchKey?.toUpperCase();
      if (this.partSearchKey?.length > 2) {
        this.debouncedGetPartList();
      }
    },
    async getPartList() {
      this.showPartNumDetails = true;
      let SearchObj = {
        UserId: this.userId,
        SearchStr: this.partSearchKey,
        proj_key: this.selectedProject,
      };
      let partResultData = await reportsService.showPartNumDetailsData("post", SearchObj, false);
      this.partResultList = partResultData?.Resultset;
    },
    //Clearing part search results
    clearPartShip() {
      this.showPartDetails = false;
      this.partSearchKey = "";
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
    },
    //Part number once selected
    showPartDesc(item) {
      this.showPartDetails = true;
      this.partNum = item.PartNo;
      this.partDesc = item.Description;
      this.partSearchKey = item.PartNo;
      this.showPartNumDetails = false;
      this.showBusiness = true;
      this.disableRun = false;
      this.hideAll = true;
      this.showPartType = true;
      this.showData = false;
    },
    //Business selected
    async businessSelected() {
      this.apiObj.pass = 2;
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.bu_key = parseInt(this.selectedBusiness);
      let showMessage = false;
      let data = await reportsService.partsByLocation("post", this.apiObj, showMessage);
      if (data.message !== "NA") {
        this.selectedWarehouse = "";
        this.selectedClass = "";
        this.selectedOEM = "";
        this.selectedLocation = "";
        this.selectedBinRow = "";
        this.selectedLPN = "";
        this.showWarehouse = true;
        this.showClass = false;
        this.showOEM = false;
        this.showLocation = false;
        this.showLPN = false;
        this.showBinRange = false;
        this.showBinRow = false;
        this.binRangeFrom = "";
        this.binRangeTo = "";
        this.disableRun = false;
        this.warehouseData = data;
        this.showData = false;
      }
    },
    //Warehouse selected
    warehouseSelected() {
      this.selectedClass = "";
      this.selectedOEM = "";
      this.selectedLocation = "";
      this.selectedBinRow = "";
      this.selectedLPN = "";
      this.showClass = true;
      this.showOEM = false;
      this.showLocation = false;
      this.showLPN = false;
      this.disableRun = false;
      this.showBinRange = false;
      this.showBinRow = false;
      this.binRangeFrom = "";
      this.binRangeTo = "";
      this.showData = false;
      this.classData = this.warehouseData.filter((obj) => obj.ware_key == this.selectedWarehouse);
    },
    //Class selected
    classSelected() {
      this.selectedOEM = "";
      this.selectedLocation = "";
      this.selectedBinRow = "";
      this.selectedLPN = "";
      this.showOEM = true;
      this.showLocation = false;
      this.showLPN = false;
      this.showBinRange = false;
      this.showBinRow = false;
      this.binRangeFrom = "";
      this.binRangeTo = "";
      this.disableRun = false;
      this.showData = false;
      this.OEMData = this.classData.filter((obj) => obj.class_key == this.selectedClass);
    },
    //OEM selected
    OEMSelected() {
      this.selectedLocation = null;
      this.selectedBinRow = "";
      this.selectedLPN = "";
      this.showLocation = true;
      this.showLPN = false;
      this.disableRun = false;
      this.showBinRange = false;
      this.showBinRow = false;
      this.binRangeFrom = "";
      this.binRangeTo = "";
      this.showData = false;
      this.locationData = this.OEMData.filter((obj) => obj.oem_key == this.selectedOEM);
    },
    //Location selected
    async locationSelected() {
      this.apiObj.pass = 3;
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.bu_key = parseInt(this.selectedBusiness);
      this.apiObj.ware_key = parseInt(this.selectedWarehouse);
      this.apiObj.class_key = parseInt(this.selectedClass);
      this.apiObj.oem_key = parseInt(this.selectedOEM);
      this.apiObj.location = this.selectedLocation;
      let showMessage = false;
      let data = await reportsService.partsByLocation("post", this.apiObj, showMessage);
      if (data.message !== "NA") {
        this.selectedBinRow = "";
        this.selectedLPN = "";
        this.showLPN = false;
        this.disableRun = false;
        this.showBinRow = true;
        this.showBinRange = true;
        this.showLPN = true;
        this.binRangeFrom = "";
        this.binRangeTo = "";
        this.binRowData = data.Result.Bin_Info;
        this.LPNData = data.Result.LPN_Info;
        this.showData = false;
      }
    },
    //Bin Row selected
    binRowSelected() {
      if (this.selectedBinRow == 0) {
        this.selectedLPN = "";
        this.showBinRange = false;
        this.binRangeFrom = "";
        this.binRangeTo = "";
        this.showLPN = false;
        this.disableRun = true;
        this.disableBinRangeFrom = true;
        this.disableBinRangeTo = true;
        this.showData = false;
      } else {
        this.showLPN = false;
        this.showBinRange = true;
        this.binRangeFrom = "";
        this.binRangeTo = "";
        this.disableBinRangeFrom = false;
        this.disableBinRangeTo = false;
        this.disableRun = false;
        this.showData = false;
      }
    },
    //LPN Selected
    LPNSelected() {
      this.showBinRange = false;
      this.showBinRow = false;
      this.selectedBinRow = "";
      this.disableRun = true;
      this.showData = false;
    },
    //Run Report
    runReport(pageNum) {
      if (!this.isValidComparison && this.showBinRange) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Range End must be greater than the Range Start",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.binRangeTo = "";
        this.disableRun = false;
      } else {
        this.apiObj.pass = 4;
        this.apiObj.userid = parseInt(this.userId);
        this.apiObj.proj_key = parseInt(this.selectedProject);
        this.apiObj.part_num = this.partSearchKey;
        this.apiObj.ware_key = parseInt(this.selectedWarehouse == "" ? 0 : this.selectedWarehouse);
        this.apiObj.class_key = parseInt(this.selectedClass == "" ? 0 : this.selectedClass);
        this.apiObj.oem_key = parseInt(this.selectedOEM == "" ? 0 : this.selectedOEM);
        this.apiObj.location = this.selectedLocation;
        this.apiObj.lpn_key = parseInt(this.selectedLPN == "" ? 0 : this.selectedLPN);
        this.apiObj.row_key = parseInt(this.selectedBinRow == "" ? 0 : this.selectedBinRow);
        this.apiObj.bin1 = this.binRangeFrom;
        this.apiObj.bin2 = this.binRangeTo;
        this.apiObj.PageNumber = pageNum;
        this.apiObj.export = 0;
        this.apiObj.guid = "";
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .post("/rt/parts_by_location_new", this.apiObj)
          .then((response) => {
            if (response.status == 200) {
              let responseData = response.data.body;
              this.totalRecords = responseData[0].TotalRecords;
              this.pageCount = responseData[0].TotalPages;
              if (this.totalRecords !== 0) {
                for (var i = 0; i < responseData.length; i++) {
                  if (responseData[i].Date_Rcvd !== "" && responseData[i].Date_Rcvd !== null) {
                    responseData[i].Date_Rcvd = Utility.convertESTToLocal(responseData[i].Date_Rcvd);
                  }
                  if (responseData[i].Last_Loc_Date !== "" && responseData[i].Last_Loc_Date !== null) {
                    responseData[i].Last_Loc_Date = Utility.convertESTToLocal(responseData[i].Last_Loc_Date);
                  }
                  if (responseData[i].Last_Adj_Date !== "" && responseData[i].Last_Adj_Date !== null) {
                    responseData[i].Last_Adj_Date = Utility.convertESTToLocal(responseData[i].Last_Adj_Date);
                  }
                  if (responseData[i].Last_Trans !== "" && responseData[i].Last_Trans !== null) {
                    responseData[i].Last_Trans = Utility.convertESTToLocal(responseData[i].Last_Trans);
                  }
                  if (responseData[i].Warranty_End !== "" && responseData[i].Warranty_End !== null) {
                    responseData[i].Warranty_End = Utility.convertESTToLocal(responseData[i].Warranty_End);
                  }
                }
                this.partsByLocItems = responseData;
                this.exportDisable = false;
                this.showData = true;
              } else {
                this.pageCount = 0;
                this.exportDisable = true;
                this.totalRecords = 0;
                this.partsByLocItems = [];
                this.showData = false;
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: "There are no records",
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Bin Range to rule
    rangeTo() {
      if (this.binRangeTo !== "") {
        if (this.binRangeTo <= 100000000) {
          if (!this.isValidComparison) {
            let Alert = {
              type: "error",
              isShow: true,
              message: "Range End must be greater than the Range Start",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.binRangeTo = "";
            this.disableRun = false;
          } else {
            this.disableRun = true;
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Range End must be lesser than or equal to 100000000",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.binRangeTo = "";
          this.disableRun = false;
        }
      } else {
        this.disableRun = false;
      }
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    async exportToExcel() {
      if (this.totalRecords <= 50000) {
        this.startDownload();
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Parts By Location");
        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
        const colors = {
          grey: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        //Add Header Rows
        worksheet.addRow([
          "Project",
          "Business",
          "Method",
          "SO PO Number",
          "Item",
          "Description",
          "Class",
          "Model",
          "OEM",
          "OEM Part Number",
          "Cost",
          "Part Type",
          "BCN",
          "Serial Number",
          "ESN",
          "ICCID",
          "RLP ID",
          "CRA",
          "Grade",
          "Quantity",
          "Date Received",
          "Age",
          "Last Loc Date",
          "Last Adj Date",
          "Last Transaction",
          "Bin",
          "LPN",
          "PI Zone",
          "Location",
          "Warehouse",
          "Warranty",
          "Warranty End",
        ]);
        // Style configuration
        const headerStyle = {
          font: { bold: true },
          alignment: { horizontal: "center", vertical: "middle" },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          fill: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        // Apply styles to the header rows
        worksheet.getRow(1).eachCell((cell) => {
          cell.style = { ...headerStyle, fill: colors.grey };
          cell.border = borderStyle;
        });
        this.apiObj.export = 1;
        const response = await this.axios.post("/rt/parts_by_location_new", this.apiObj);
        let responseData = response.data.body;
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            Date_Rcvd: obj.Date_Rcvd !== "" ? Utility.convertESTToLocal(obj.Date_Rcvd) : "",
            Last_Loc_Date: obj.Last_Loc_Date !== "" ? Utility.convertESTToLocal(obj.Last_Loc_Date) : "",
            Last_Adj_Date: obj.Last_Adj_Date !== "" ? Utility.convertESTToLocal(obj.Last_Adj_Date) : "",
            Last_Trans: obj.Last_Trans !== "" ? Utility.convertESTToLocal(obj.Last_Trans) : "",
          };
        });
        this.export50kData = false;
        //Add your data rows
        newArr.forEach((item) => {
          const row = worksheet.addRow([
            item.Project,
            item.Business,
            item.Method,
            item.SO_PO_Number,
            item.Item,
            item.Description,
            item.Class,
            item.Model,
            item.OEM,
            item.OEM_Partno,
            item.Cost,
            item.Part_Type,
            item.BCN,
            item.Serial_No,
            item.ESN,
            item.ICCID,
            item.License_Plate,
            item.CRA,
            item.Grade,
            item.Qty,
            item.Date_Rcvd,
            item.Age,
            item.Last_Loc_Date,
            item.Last_Adj_Date,
            item.Last_Trans,
            item.Bin,
            item.LPN,
            item.PI_Zone,
            item.Location,
            item.Warehouse,
            item.Warranty,
            item.Warranty_End,
          ]);
          row.eachCell({ includeEmpty: true }, (cell) => {
            //Apply borders
            cell.border = borderStyle;
          });
        });
        //Download the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), Utility.excelFileName("Parts_By_Location"));
        this.finishDownload();
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Wnd the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
