import { render, staticRenderFns } from "./bin-tabular-report.vue?vue&type=template&id=1bbad0bf&scoped=true&"
import script from "./js/bin-tabular-report.js?vue&type=script&lang=js&"
export * from "./js/bin-tabular-report.js?vue&type=script&lang=js&"
import style0 from "./bin-tabular-report.vue?vue&type=style&index=0&id=1bbad0bf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bbad0bf",
  null
  
)

export default component.exports