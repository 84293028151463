<template>
  <div>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-10 pb-1">
        <v-card elevation="1" class="containerbox">
          <breadcrumbComp class="hidden-sm-and-down" :mainPage="mainPage" :subPage="subPage" :backSlash="backSlash"
            :pageUrl="pageUrl" :refresh="refresh" :resetFunction="resetFunction" :showAdd="showAdd" @addFav="addFav()" />
          <breadcrumbComp class="hidden-md-and-up" :subPage="subPage" :pageUrl="pageUrl" :refresh="refresh"
            :resetFunction="resetFunction" :showAdd="showAdd" @addFav="addFav()" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-10 py-1">
        <v-card elevation="1" class="containerbox">
          <v-card-text class="pt-5">
            <v-form ref="binValidateForm" v-model="binForm" v-on:submit.prevent lazy-validation>
              <v-row v-if="validateText">
                <!-- Bin TeXt Box -->
                <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-4">
                  <v-text-field color="primarytext" @input="binName=binName?.toUpperCase()" v-model="binName" :rules="max20Rule" label="Bin/Skid Location"
                    @keydown.enter="validate()" outlined clearable dense class="ma-0" persistent-placeholder>
                  </v-text-field>
                </v-col>
                <!-- Search button -->
                <v-col cols="12" sm="12" md="12" lg="1" class="pb-5 py-4">
                  <v-btn color="primarytext" :disabled="!binForm" @keydown.enter="validate()" outlined class="btn-style"
                    elevation="1" @click="validate()">
                    <v-icon dark left> mdi-magnify </v-icon> Search
                  </v-btn>
                </v-col>
                <!-- Empty -->
                <v-col cols="12" sm="12" md="12" lg="3" class="px-5 py-4">
                </v-col>
                <!-- Printer Mesage  -->
                <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-4 text-right">
                  <PrinterDetails />
                </v-col>
              </v-row>
            </v-form>
            <v-row v-if="binInfo">
              <v-col cols="12" sm="12" md="12" lg="12" class="px-5 py-4">
                <span class="primarytext--text font-weight-bold">
                  Bin :
                </span>
                &nbsp;
                <span class="secondary--text font-weight-bold">
                  {{ this.binName }}
                </span>
              </v-col>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" class="px-8 py-4">
                  <span class="primarytext--text font-weight-bold">
                    Total in Bin :
                  </span>
                  &nbsp;
                  <span class="secondary--text font-weight-bold">
                    {{ this.totalBin }}
                  </span>
                </v-col>
              </v-row>
            </v-row>
            <v-divder></v-divder>
            <v-row v-if="ShowAddtionalInfo">
              <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-4 mt-5">
                <v-data-table hide-default-footer :headers="infoHeader" :items="addInfo" item-key="lpn">
                </v-data-table>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8" class="px-5 py-4"></v-col>
            </v-row>
            <v-form ref="submitValidateForm" v-on:submit.prevent v-model="submitValidateForm" lazy-validation>
              <v-row v-if="displayProjectData">
                <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-4 mt-5">
                  <v-autocomplete color="primarytext" attach outlined class="ma-0" v-model="selectedProject"
                    @change="showLpn" dense :items="projectList" label="Project" :rules="required" item-text="Project"
                    item-value="ProjKey" required persistent-placeholder>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-4 mt-5">
                  <v-text-field color="primarytext" v-if="showLPN" v-model="LPN" label="New LPN" :rules="max20Rule"
                    outlined clearable dense class="ma-0" persistent-placeholder>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-4 mt-5"></v-col>
              </v-row>
              <v-row v-if="resetValidate">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <hr />
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="3" class="px-5 py-4 mt-5">
                  <v-text-field color="primarytext" v-model="stagingName" :rules="max20Rule" label="Staging Location"
                    @keydown.enter="binSubmit" @input="stagingName=stagingName?.toUpperCase()" outlined clearable dense class="ma-0" persistent-placeholder>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="3" class="px-5 py-4 mt-5">
                  <v-btn :disabled="!submitValidateForm" color="primarytext" outlined class="btn-style" elevation="1"
                    @click="binSubmit()">
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import breadcrumbComp from '../common/breadcrumb-comp.vue';
import AddFavourite from "./js/addFav";
import EncryptUtility from './js/encrypt-utility.js';
import Utility from './js/utility-service.js';
import PrinterDetails from '../common/printer-details';
export default {
  data() {
    return {
      required: [(v) => !!v || 'Field is required'],
      max20Rule: [
        (v) => (v || '').length <= 20 || "Max Length of 20 character",
        (v) => !!v || 'Field is required'
      ],
      route: "",
      binName: "",
      totalBin: "",
      actionList: "",
      resetValidate: false,
      mainPage: "",
      favouriteList: "",
      projectList: [],
      pageUrl: "",
      selectedProject: 0,
      LPN: "",
      subPage: "",
      refresh: "",
      backSlash: true,
      submitValidateForm: true,
      binInfo: false,
      validateText: true,
      displayProjectData: false,
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt('userID'),
      binForm: true,
      addInfo: [],
      showLPN: false,
      stagingName: "",
      infoHeader: [
        {
          text: 'LPN',
          align: 'start',
          value: 'lpn',
          class: 'primary customwhite--text',
        },
        {
          text: 'QTY',
          value: 'qty',
          class: 'primary customwhite--text',
        },
      ],
      ShowAddtionalInfo: false,
    }
  },
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  methods: {
    showLpn() {
      this.showLPN = true
    },
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route)
    },
    // Validate API call
    async validate() {
      if (this.$refs.binValidateForm.validate()) {
        let obj = {
          pass: 1,
          UserId: this.userId,
          bin: this.binName,
          lpn: "",
          staging_skid: "",
          proj_key: 0
        }
        let showMessage = false
        let response = await Utility.postValidate("post", obj, showMessage)
        if (response == {}) {
          this.validateText = true
        }
        if (response.Info != undefined || response.Info != null) {
          this.binInfo = true
          this.totalBin = response.Info[0].bincount
          this.resetValidate = true
          this.validateText = false
        }
        if (response?.AdditionalInfo?.length > 0) {
          this.ShowAddtionalInfo = true;
          let infoData = response.AdditionalInfo
          this.addInfo = infoData.map((x) => ({
            lpn: x.lpn,
            qty: x.qty,
          }));
          this.resetValidate = true
          this.selectedProject = 0
          this.validateText = false
        }
        else if (response?.Projects) {
          this.displayProjectData = true
          let projectData = response.Projects
          this.projectList = projectData.map((x) => ({
            ProjKey: x.proj_key,
            Project: x.project,
          }));
          this.validateText = false
          this.resetValidate = true
        }
      }
    },
    // Reset Funtion
    resetFunction() {
      this.binName = "";
      this.binInfo = false;
      this.validateText = true;
      this.ShowAddtionalInfo = false;
      this.resetValidate = false
      this.displayProjectData = false
      this.selectedProject = 0
      this.stagingName = ""
      this.LPN = ""
    },
    async binSubmit() {
      if (this.$refs.submitValidateForm.validate()) {
        let obj = {
          pass: 2,
          UserId: this.userId,
          bin: this.binName,
          lpn: this.LPN,
          staging_skid: this.stagingName,
          proj_key: this.selectedProject
        }
        let showMessage = true
        let response = await Utility.postValidate("post", obj, showMessage)
        //DO NOT REMOVE
        if (Object.keys(response).length === 0) {
          this.resetValidate = true
        }
        else {
          this.resetFunction()
        }
      }
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
</script>
<style scoped>