import Vue from 'vue';
import excel from 'vue-excel-export';
import breadcrumbComp from '../../../common/breadcrumb-comp.vue';
import EncryptUtility from '../../../../components/utility/js/encrypt-utility';
import AddFavourite from "../../../utility/js/addFav"
import Utility from '../../../../shared/utility';
import exportLimit from '../../../common/export-limit.vue';
import inventoryService from "../../js/inventory-service"
Vue.use(excel);
export default {
  data: () => ({
    mainPage: '',
    subPage: '',
    subPageUrl:"",
    appPageName:"",
    pageUrl:"",
    backSlashParent:true,
    excelFileName:"",
    compCalled: false,
    showAdd: false,
    favouriteList: [],
    isFormValid: false,
    actionList:[],
    totalRecords:0,
    showBinList:false,
    activeTab:0,
    tabs:["Part Type","Business","Parent Class","Sub Class","Warehouse","Location"],
    userId: EncryptUtility.localStorageDecrypt('userID'),
    refresh: true,
    projectList: [],
    scopeList:[],
    selectedItems:[],
    scopeDataList:[],
    reconciliationList:[],
    partTypeList:[],
    binList:[],
    businessList:[],
    parentList:[],
    warehouseList:[],
    locationList:[],
    subClassList:[],
    partArray:[],
    totalBusiness:0,
    totalparentClass:0,
    totalsubClass:0,
    totalWarehouse:0,
    totalWorkCenter:0,
    disableTab:true,
    disableSubClassTab:true,
    partNumberSwitch:false,
    binSwitch:false,
    allPart:false,
    allBin:false,
    loc:"",
    rowNum:"",
    binNumber:"",
    minBin:"",
    maxBin:"",
    search:"",
    rowDes:"",
    partNum:"",
    binNum:"",
    scopeListHeader:[
      {
        text: "Scope",
        align: "start",
        value: "Scope",
        class: "primary customwhite--text",
      },
      {
        text: "Project",
        align: "start",
        value: "Project",
        class: "primary customwhite--text",
      },
      {
        text: "Type",
        align: "start",
        value: "Type",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "actions",
        class: "primary customwhite--text",
      },
    ],
    partTypeHeader:[
        {
            text: "Part Type",
            align: "start",
            value: "Part Type",
            class: "primary customwhite--text",
          },
    ],
    warehouseHeader:[
        {
            text: "Warehouse",
            align: "start",
            value: "Warehouse",
            class: "primary customwhite--text",
          },
    ],
    businessHeader:[
        {
            text: "",
            align: "start",
            value: "Action",
            class: "primary customwhite--text",
          },
        {
            text: "Business",
            align: "start",
            value: "Business",
            class: "primary customwhite--text",
          },
    ],
    binHeader:[
        {
            text: "Bin",
            align: "start",
            value: "Bin",
            class: "primary customwhite--text",
          },
    ],
    locationHeader:[
        {
            text: "Location",
            align: "start",
            value: "Location",
            class: "primary customwhite--text",
          },
    ],
    subClassHeader:[{
        text: "Sub Class",
        align: "start",
        value: "Class",
        class: "primary customwhite--text",
      },
    ],
    parentHeader:[
        {
            text: "Parent Class",
            align: "start",
            value: "Parent Class",
            class: "primary customwhite--text",
          },
    ],
    required: [(v) => !!v || "Field is required"],
    controlValidataion: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(?:[ A-Za-z0-9\s\S]){5,25}$/.test(v) ||
          "Please enter at least one text or number, Field should be greater than 5 characters and less than 25 characters",
      ],
      accuracyValidation: [
        (v) => parseInt(v) >= 0 || "Field is required",
        (v) => /^[1-9][0-9]?(\.[0-9]{1,2})?$/.test(v) || "Between 1 to 99 Upto 2 decimal places are allowed",
      ],
      min1Rule: [
        (v) => parseInt(v) >= 0 || "Field should be greather than 0",
        (v) => !!v || "Field is required",
        
      ],
      max999Rule: [
        (v) => parseInt(v) <= 999 || "Field should be less than than 999",
        (v) => !!v || "Field is required",
        
      ],
      binRule:[
        (v) => !!v || "Field is required",(v) =>
          /^[A-Z]{1,2}[0-9]{1,3}$/.test(v) ||
          "Please enter the correct format Eg:-AA-111,AA11",
      ],
      only8CharAllowed: [(v) => !!v || "Field is required", (v) => (v || "").length == 8 || "Field should have 8 characters"],
    backSlash: true,
    saveObj:{
            Project:0,
            PiTypeKey:0,
            Control:"",
            AccuracyGoal:0,
            Unlock:"",
            Zone:"",
            PreCounts:false,
            ReconcileMethod:0,
            Serialized:false, 
            NonSerialized:false,
            Business:[],
            ParentClass:[],
            SubClass:[],
            Warehouse:[],
            Location:[],
            WorkCenter:[],
            PartNumber:[],
            Bin:[],        
    },
    totalPartCount:0,
    editObj:{
        controlID: 0,
        json: {
        ControlID: 0,
        Control: "",
        Project: "",
        PiTypeKey: "",
        StatusKey: "",
        Unlock: "",
        Zone: "",
        AccuracyGoal: "",
        PreCounts: "",
        ReconcileMethod: -1,
        PartType: [],
        Serialized: "N",
        NonSerialized: "N",
        Business: [],
        SubBusiness: [],
        ParentClass: [],
        SubClass: [],
        Warehouse: [],
        WorkCenter: [],
        PartNumber: []
        },
        user_key: 0,
        isnew: 1
      }
  }),
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.subPageUrl = data.subPageUrl;
    this.appPageName = data.appPageName;
    
  },
  async mounted() {
    let obj={
        user_id:this.userId,
        pass:1
    }
    let resp= await inventoryService.getAddData("post", obj);
    console.log(resp)
    this.projectList=resp.Projects
    this.scopeList=resp.Scope;
    this.reconciliationList=resp.Type
  },
  methods: {
    actionSetter(action) {
      return Utility.actionSetter(this.actionList, action)
    },
    
    async onChangeType(){
        this.editObj.json.Project=this.saveObj.Project
        this.editObj.json.PiTypeKey=this.saveObj.PiTypeKey
        this.editObj.user_key=this.userId
        console.log(this.editObj)
        let submitObj=JSON.parse(JSON.stringify(this.editObj))
        submitObj.json=JSON.stringify(submitObj.json)
        console.log(this.editObj)
        let resp= await inventoryService.postScopeEdit("post",submitObj,false)
        console.log(resp)
        this.partTypeList=resp.parttype
        this.businessList=resp.Business
        this.totalBusiness=this.businessList?.length
        this.totalPartCount= this.partTypeList.length

    },
    onChangeTab(index){
        console.log(index)
    },
    shouldDisableTab(tab) {
        const disabledTabs = ["Warehouse", "Location", "Parent Class", "Sub Class"];

        if (!this.disableTab || !disabledTabs.includes(tab)) {
            return false;
        }
        
        if (tab === "Sub Class") {
            return !this.editObj.ParentClass || this.editObj.ParentClass.length === 0; // Disabled by default unless ParentClass is selected
        }
        
        return true;
        
    },
    async onChangeBusinessSelect(){
        if(this.editObj.json.Business.length>0){
          this.editObj.json.ParentClass=[];
          this.editObj.json.SubClass=[];
          this.editObj.json.Warehouse=[];
          this.editObj.json.Location=[];
            this.disableTab=false
            let submitObj=JSON.parse(JSON.stringify(this.editObj))
            submitObj.json.PartType=submitObj.json.PartType.map(item => item.ptype_key)
            submitObj.json.Business=submitObj.json.Business.map(item => item.bu_key)
            submitObj.json=JSON.stringify(submitObj.json)
            console.log(submitObj)
            let resp= await inventoryService.postScopeEdit("post",submitObj,false)
            console.log(resp) 
            this.parentList=resp.Parentclass 
            this.locationList=resp.Location
            this.warehouseList=resp.Warehouse
            this.totalparentClass=this.parentList?.length,
            this.totalWarehouse=this.warehouseList?.length,
            this.totalWorkCenter=this.locationList?.length
    }
    else{
        this.disableTab=true
    }
    },
    async onChangeParentSelect(){
      this.editObj.json.SubClass=[]
        if(this.editObj.json.Business.length>0){
            this.disableTab=false
            let submitObj=JSON.parse(JSON.stringify(this.editObj))
            submitObj.json.PartType=submitObj.json.PartType.map(item => item.ptype_key)
            submitObj.json.Business=submitObj.json.Business.map(item => item.bu_key)
            submitObj.json.ParentClass=submitObj.json.ParentClass.map(item => item.parent_class_key)
            submitObj.json=JSON.stringify(submitObj.json)
            let resp= await inventoryService.postScopeEdit("post",submitObj,false)
            this.subClassList=resp.Class
            this.totalsubClass= this.subClassList.length
            console.log(this.totalsubClass)
    }
},

    async onClickValidatePart(){
        let scope = JSON.parse(JSON.stringify(this.saveObj))
        scope.NonSerialized?"Y":"N"
        scope.Serialized?"Y":"N"
        scope.PreCounts?"Y":"N"
        let validateObj={
            json:JSON.stringify(scope),
            user_id:this.userId,
            part_num:this.partNum
        }
    let resp= await inventoryService.postValidate("post",validateObj,false)
        resp?this.partArray.push(this.partNum):""
        
    },
    async onClickValidateBin(loc){
      let isValid = false;
      this.loc=loc

  if (loc === "Num") {
    isValid = await this.$refs.numForm.validate(); // Validate 'Num' form
    this.rowDes="";
    this.minBin="";
    this.maxBin="";
    this.$refs?.binRangeForm?.resetValidation()
  } else {
    isValid = await this.$refs.binRangeForm.validate(); // Validate 'Other' form
    this.binNum=""
    this.$refs?.numForm?.resetValidation()
  }
  if(isValid)
        {let binObj={
            pass:loc=="Num"?2:1,
            controlID:0,
            row:this.rowDes,
            low:this.minBin,
            high:this.maxBin,
            bin:this.binNumber
        }
        let resp= await inventoryService.postValidateBin("post",binObj,false)
        this.binList=resp
        if(this.binList.length>0){
            this.showBinList=true
            this.selectedItems=this.binList
        }}

    },
    onChangeBin(){
        console.log(this.selectedItems)
    },
    async onClickSubmit(){
        const validateSelection = (key, tabId, message) => {
            console.log(key, tabId, message)
            console.log(this.editObj?.json[key]?.length)
            if (this.editObj?.json[key]?.length== 0) {
                document.getElementById(tabId).click();
              let Alert = {
            type: "error",
            isShow: true,
            message: message,
          };
          this.$store.commit("ConfigModule/Alert", Alert);    
        }
        else{
            return false
        }
        };
        if (validateSelection("PartType", "tab-0", "Select at least one Part Type")) ; 
        if (validateSelection("Business", "tab-1", "Select at least one Business")) ;
        if(this.disableTab){
        if (validateSelection("ParentClass", "tab-2", "Select at least one Parent Class")) ;
        if (validateSelection("SubClass", "tab-3", "Select at least one Sub Class")) ;
        if (validateSelection("Warehouse", "tab-4", "Select at least one Warehouse")) ;
        if (validateSelection("Location", "tab-5", "Select at least one Location")) ;}
        if (this.$refs.addScopeForm.validate()) {
            const mapKeys = (items, key) => items.map(item => item[key]);
        
            const jsonObj = {
                Project: this.saveObj.Project,
                PiTypeKey: this.saveObj.PiTypeKey,
                ControlID: 0,
                Control: this.saveObj.Control,
                AccuracyGoal: this.saveObj.AccuracyGoal,
                Unlock: this.saveObj.Unlock,
                Zone: this.saveObj.Zone,
                PreCounts: this.saveObj.PreCounts ? "Y" : "N",
                ReconcileMethod: this.saveObj.ReconcileMethod,
                Serialized: this.saveObj.Serialized ? "Y" : "N",
                NonSerialized: this.saveObj.NonSerialized ? "Y" : "N",
                PartType: this.totalPartCount== this.editObj?.json?.PartType.length?["All"]:
                mapKeys(this.editObj?.json?.PartType, "ptype_key"),
                Business: this.totalBusiness== this.editObj?.json?.Business.length?["All"]:mapKeys(this.editObj?.json?.Business, "bu_key"),
                ParentClass:this.totalparentClass== this.editObj?.json?.ParentClass.length?["All"]: mapKeys(this.editObj?.json?.ParentClass, "parent_class_key"),
                SubClass:this.totalsubClass== this.editObj?.json?.SubClass.length?["All"] :mapKeys(this.editObj?.json?.SubClass, "class_key"),
                Warehouse:this.totalWarehouse== this.editObj?.json?.Warehouse.length?["All"] :mapKeys(this.editObj?.json.Warehouse, "ware_key"),
                WorkCenter:this.totalWorkCenter== this.editObj?.json?.Location.length?["All"] :mapKeys(this.editObj?.json.Location, "loc"),
                PartNumber: this.allPart ? ["All"] : this.partArray,
                Bin: this.allBin ? ["All"] : this.selectedItems?.map(item => item.bin_key)
            };
            const submitObj = {
                user_id: this.userId,
                json: JSON.stringify(jsonObj)
            };
    
            const resp = await inventoryService.submitScope("post", submitObj, false);
            let Alert = {
              type: "success",
              isShow: true,
              message: resp[0].Message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            if(resp[0]?.control_id){
              this.$router.push(`/scope-manager-edit/${btoa(resp[0].control_id.toString())}`);
            }
        }
        
}


   

  
    },
    components: {
    breadcrumbComp,
    exportLimit,
  },
};