import Utility from "../../../shared/utility";
import EncryptUtility from "./encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav";
import PrinterDetails from "../../common/printer-details";
export default {
  data() {
    return {
      backSlash: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      isPrinter: true,
      searchVal: "",
      mainPage: "",
      subPage: "",
      pageUrl: "",
      actionList: [],
      showAdd: false,
      favouriteList: [],
      unitVal: "",
      wcMovementDialog: false,
      wcMovementList: [],
      workCenterSelected: "",
      search: "",
      headers: [
        {
          text: "Select",
          align: "start",
          value: "IsEligible",
        },
        {
          text: "Label",
          value: "Label",
        },
        {
          text: "Project",
          value: "Project",
        },
        {
          text: "RLP Location",
          value: "RLP Location",
        },
        {
          text: "Description",
          value: "Description",
        },
        {
          text: "Warehouse",
          value: "Warehouse",
        },
        {
          text: "Bin",
          value: "Bin",
        },
        {
          text: "SKU",
          value: "SKU",
        },
        {
          text: "Unit Identifier",
          value: "Unit Identifier",
        },
        {
          text: "Error_Reason",
          value: "Error_Reason",
        },
      ],
      headersWCAllowList: [
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Ware",
          value: "ware",
        },
      ],
      workCenterDetails: [],
      isShow: false,
      wcAllowedList: [],
      inTransitLPN: "",
      stagingLocation: "",
      lpnDetails: [],
      wcLocationDet: [],
      primaryLabelSize: "",
      primaryPrinterType: "",
      validatedWC: false,
      selectWC: false,
      primaryName: EncryptUtility.localStorageDecrypt("primaryName"),
      max36Rule: [(v) => (v || "").length <= 36 || "Max Length must be 36 characters or less"],
      max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Max Length must be 50 characters or less"],
      max50RuleNum: [
        (v) => !!v || "Field is required",
        (v) => (v || "").length <= 50 || "Max Length must be 50 characters or less",
        (v) => /^[0-9 -]+$/.test(v) || "LPN must be valid.",
      ],
      max3Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 3 || "Max Length must be 3 characters or less"],
      isFormValid: false,
      showPrimary: true,
    };
  },
  async created() {
    if (EncryptUtility.localStorageDecrypt("primaryPrinterType") !== null && EncryptUtility.localStorageDecrypt("primaryPrinterType") !== undefined) {
      this.primaryPrinterType = EncryptUtility.localStorageDecrypt("primaryPrinterType");
    }
    if (EncryptUtility.localStorageDecrypt("primaryLabelSize") !== null && EncryptUtility.localStorageDecrypt("primaryLabelSize") !== undefined) {
      this.primaryLabelSize = EncryptUtility.localStorageDecrypt("primaryLabelSize");
    }
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {},
  methods: {
    //Add or remove Favourtite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //reset function main
    resetFunction() {
      this.searchVal = "";
      this.wcMovementList = [];
      this.wcAllowedList = [];
      this.unitVal = "";
      this.validatedWC = false;
      this.isShow = false;
      this.wcMovementDialog = false;
      this.workCenterDetails = [];
      this.wcLocationDet = [];
      this.lpnDetails = [];
      this.inTransitLPN = "";
      this.stagingLocation = "";
      this.selectWC = false;
      this.$refs.WCMovementValidateForm.resetValidation();
      this.$refs.WCDetailsForm.resetValidation();
      this.isFormValid = false;
    },
    //Text change on all text fields to get unit name
    validateUnit(item) {
      this.unitVal = item;
    },
    //Search button click event
    onSearchClick(item) {
      if (item !== "" && item !== undefined) {
        this.unitVal = item;
      }
      this.validateWorkcenterMove(this.unitVal);
    },

    //Validate Workcenter Movement API
    validateWorkcenterMove(unit) {
      if (this.searchVal == "") {
        var Alert = {
          type: "error",
          isShow: true,
          message: "Please enter the search value",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else {
        this.wcMovementList = [];
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .get(`/cp/wc_movements_validate?unit=${unit}&user_key=${this.userId}`)
          .then((response) => {
            if (response.status == 200) {
              let responseData = JSON.parse(response.data.body.message);
              this.wcMovementList = responseData;
              if (this.wcMovementList.length == 1) {
                if (this.wcMovementList[0].IsEligible == 1) {
                  this.isShow = true;
                  this.workCenterDetails = [];
                  this.workCenterDetails = this.wcMovementList[0];
                  this.getAllowedWCMovementList();
                  this.selectWC = true;
                  this.validatedWC = true;
                } else {
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: this.wcMovementList[0].Error_Reason,
                  };
                  this.resetFunction();
                  this.validatedWC = false;
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
              } else {
                this.wcMovementDialog = true;
              }

              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Workcenter Movement selection
    selectedWorkCenterMovement(item) {
      this.workCenterDetails = [];
      this.workCenterDetails = item;
      this.selectWC = true;
    },
    //Workcenter Details popup close event
    selectWCCloseDialog() {
      this.wcMovementDialog = false;
      this.isShow = true;
      this.getAllowedWCMovementList();
    },
    //Workcenter Details popup close event
    closeDialog() {
      this.wcMovementDialog = false;
      this.selectWC = false;
      this.resetFunction();
    },
    //Get Allowed Workcenter Movement List
    getAllowedWCMovementList() {
      this.wcAllowedList = [];
      let mainKey = this.workCenterDetails.main_id;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/wc_movements_allowed_list?main_key=${mainKey}&user_key=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.wcAllowedList = responseData;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get next available LPN
    getNextAvalbleLPN() {
      this.lpnDetails = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/co/common_next_available_lpn?user_key=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.lpnDetails = responseData;
            this.inTransitLPN = "";
            this.inTransitLPN = this.lpnDetails[0].lpn;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Change in LPN
    transitLPNChange(item) {
      this.inTransitLPN = "";
      this.inTransitLPN = item;
    },
    //Change in Staging Location
    stagingLocationChange(item) {
      this.stagingLocation = "";
      this.stagingLocation = item;
    },
    //Workcenter Movement Submit button event
    changeWorkcenter() {
      this.wcLocationDet = [];
      this.wcLocationDet = this.selectedWCLoc;
      if (this.$refs.WCDetailsForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        if (this.wcLocationDet.length !== 0) {
          let wcMovementObj = {
            user_key: parseInt(this.userId),
            main_key: parseInt(this.workCenterDetails.main_id),
            ware_key_n: parseInt(this.wcLocationDet[0].ware_key),
            loc_n: this.wcLocationDet[0].loc_adj_to,
            cos_grade_n: this.workCenterDetails["Cosmetic Grade"],
            lpn: this.inTransitLPN,
            bin: this.stagingLocation,
            lbl_size: this.primaryLabelSize,
            printer_type: this.primaryPrinterType,
          };
          this.axios
            .post("/cp/wc_movements_submit", wcMovementObj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                console.log("response", response);
                let responseData = response?.data?.body?.message;
                console.log("responseData", responseData);
                if (this.primaryName !== null && this.primaryName !== undefined) {
                  console.log("responseData-if", this.primaryName, responseData);
                  PrinterDetails.printLabelData(responseData, this.primaryName);
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.resetFunction();
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.body.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              console.log("Inside catch");
              console.log("error", error);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        } else {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please select any one of the Workcenter Allowed list",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
