import { render, staticRenderFns } from "./usb-asset-tracking-details.vue?vue&type=template&id=28cf6ed2&scoped=true&"
import script from "./js/usb-asset-tracking-details.js?vue&type=script&lang=js&"
export * from "./js/usb-asset-tracking-details.js?vue&type=script&lang=js&"
import style0 from "./usb-asset-tracking-details.vue?vue&type=style&index=0&id=28cf6ed2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28cf6ed2",
  null
  
)

export default component.exports