import Vue from 'vue';
import excel from 'vue-excel-export';
import breadcrumbComp from '../../common/breadcrumb-comp.vue';
import EncryptUtility from '../../../components/utility/js/encrypt-utility';
import AddFavourite from "../../utility/js/addFav"
import Utility from '../../../shared/utility';
import exportLimit from '../../common/export-limit.vue';
import ReadOnlyDialog from '../../common/read-only-dialog.vue';
import inventoryService from "../js/inventory-service"
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
Vue.use(excel);
export default {
  data: () => ({
    mainPage: '',
    subPage: '',
    excelFileName:"",
    compCalled: false,
    backSlash:true,
    showAdd: false,
    favouriteList: [],
    isFormValid: false,
    actionList:[],
    totalRecords:0,
    userId: EncryptUtility.localStorageDecrypt('userID'),
    refresh: true,
    controlList:[],
    tableList:[],
    showTable:false,
    partNum :"",
    json_fields:{
        Bin:"Bin",
        Qty:"Qty",
        Unit_Identifier:"Unit Identifier",
        Warehouse:"Warehouse",
        Counted: "Counted", 
        By:"By",
        Status:"Status",
    },
    control:"",
    dialogHeader:"Bin Count Details",
    commonDialog:false,
    binDetailsFileName:"",
    tableHeader:[
        {
            text: "Bin",
            align: "start",
            value: "Bin",
            class: "primary customwhite--text",
          },
          {
            text: "Part Number",
            align: "start",
            value: "Part Number",
            class: "primary customwhite--text",
          },
          {
            text: "Qty",
            align: "start",
            value: "Qty",
            class: "primary customwhite--text",
          },
              

    ],
    
  }),
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route)
    this.actionList = data.actionList
    this.mainPage = data.mainPage
    this.favouriteList = data.favouriteList
    this.pageUrl = data.pageUrl
    this.subPage = data.subPage
    this.showAdd = data.showAdd
  },
  async mounted() {
   this.controlList=await this.getPageData("load")
 
  },
  methods: {
    actionSetter(action) {
      return Utility.actionSetter(this.actionList, action)
    },
    fileName() {
      this.excelFileName=Utility.excelFileName("Bin_Tabular_List")
      return 
      },
    async getPageData(loc){
        let obj={
            user_id: this.userId,
            pass: loc=='load'?1:2,
            controlID: loc=="load"?0:this.control
          }
          let resp=await inventoryService.getBinTabularData("post",obj,false)
          return resp
    },
   async onClickRun(){
    let obj={
        user_id: this.userId,
        pass: 2,
        controlID: this.control,
        part_num: this.partNum
      }
      console.log(obj)
        this.tableList=await inventoryService.postBinPart("post",obj,false)
        console.log(this.tableList)
        this.totalRecords= this.tableList.length
        this.showTable=true;
    },
    closeDialog(){
        this.commonDialog=false
    },
  
    async exportToExcel () {
        if (this.totalRecords <= 15000) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet(" Bin Details Report");
          const borderStyle = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          };
          const colors = {
            primary: { type: "pattern", pattern: "solid", fgColor: { argb: "005ba8" } },
            white: { type: "pattern", pattern: "solid", fgColor: { argb: "FFFFFF" } },
          };
          //Add Header Rows
          worksheet.addRow([
            "Bin",
            "Qty",
            "Part Number",

          ]);
          // Style configuration
          const headerStyle = {
            font: { bold: true, },
            alignment: { horizontal: "center", vertical: "middle" },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "005ba8" } },
          };
          // Apply styles to the header rows
          worksheet.getRow(1).eachCell((cell) => {
            cell.style = { ...headerStyle, fill: colors.primary };
            cell.border = borderStyle;
          });
          //Add your data rows
          this.tableList.forEach((item) => {
            const row = worksheet.addRow([
              item.Bin,
              item.Qty,
              item["Part Number"],
            ]);
            row.eachCell({ includeEmpty: true }, (cell) => {
              //Apply borders
              cell.border = borderStyle;
            });
          });
          //Download the Excel file
          const buffer = await workbook.xlsx.writeBuffer();
          saveAs(new Blob([buffer]), Utility.excelFileName("Bin_Scan_Part"));
        } else {

          return true;
        }
      },
      onClickCancel(){
        this.control=""
        this.binNum=""
        this.showTable=false
      }
    },
    components: {
    breadcrumbComp,
    exportLimit,
    ReadOnlyDialog
  },
}; 