import Vue from 'vue';
import excel from 'vue-excel-export';
import breadcrumbComp from '../../../common/breadcrumb-comp.vue';
import EncryptUtility from '../../../../components/utility/js/encrypt-utility';
import AddFavourite from "../../../utility/js/addFav"
import Utility from '../../../../shared/utility';
import exportLimit from '../../../common/export-limit.vue';
import inventoryService from "../../js/inventory-service"
Vue.use(excel);
export default {
  data: () => ({
    mainPage: '',
    subPage: '',
    excelFileName:"",
    compCalled: false,
    showAdd: false,
    favouriteList: [],
    isFormValid: false,
    actionList:[],
    totalRecords:0,
    json_fields: {
      Scope: 'Scope',
      Project: 'Project',
      Type: 'Type',
      Status: 'Status ',
    },
    userId: EncryptUtility.localStorageDecrypt('userID'),
    refresh: true,
    projectList: [],
    scopeList:[],
    statusList: [],
    scopeDataList:[],
    search:"",
    scopeListHeader:[
      {
        text: "Scope",
        align: "start",
        value: "Scope",
        class: "primary customwhite--text",
      },
      {
        text: "Project",
        align: "start",
        value: "Project",
        class: "primary customwhite--text",
      },
      {
        text: "Type",
        align: "start",
        value: "Type",
        class: "primary customwhite--text",
      },
      {
        text: "Status",
        align: "start",
        value: "Status",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "actions",
        class: "primary customwhite--text",
      },
    ],

    backSlash: true,
    searchObj:{
        user_id:0,
        proj_id:0,
        status_id:0,
        pi_type_id:0,
        search:""
    }
  }),
  async created() {
    this.searchObj.user_id=this.userId
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route)
    this.actionList = data.actionList
    this.mainPage = data.mainPage
    this.favouriteList = data.favouriteList
    this.pageUrl = data.pageUrl
    this.subPage = data.subPage
    this.showAdd = data.showAdd
  },
  async mounted() {
    let resp= await inventoryService.getInitalList("get", this.userId);
    this.projectList=resp.Projects
    this.statusList=resp.Scope;
    this.scopeList=resp.Type;
  },
  methods: {
    actionSetter(action) {
      return Utility.actionSetter(this.actionList, action)
    },

    onClickAddScope(){
        this.$router.push(`/scope-manager-add`);
      },

    async onClickSearch(){
      let resp=await inventoryService.postSearch("post",this.searchObj,false)
      this.scopeDataList=resp;
      this.totalRecords=resp?.length
  
    },
    onClickCancel(){
      this.searchObj={
        search:"",
        user_id:this.userId,
        proj_id:0,
        status_id:0,
        pi_type_id:0,
    };
    this.scopeDataList=[];
    this.totalRecords=0
    },
    fileName() {
      this.excelFileName=Utility.excelFileName("Scope")
      return 
      },
    onClickEdit(item){
      console.log(item)
      this.$router.push(`/scope-manager-edit/${btoa(item.control_id.toString())}`);
    }
    },
    components: {
    breadcrumbComp,
    exportLimit,
  },
};