import Utility from "../../../shared/utility";
import companyAddressComp from "../../company/app-business-company-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
export default {
  name: "businessComp",
  required: [(v) => !!v || "Field is required"],
  props: {
    name: String,
    compCalled: Boolean,
    projectData: Array,
    bu_id: String,
    actionList: Array,
    edit: Boolean,
  },
  data: () => ({
    tabNum: 0,
    showUpdate: false,
    max100Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 100 || "Max Length must be 100 characters or less"],
    max10Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 10 || "Max Length must be 10 characters or less"],
    max20Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 20 || "Max Length must be 20 characters or less"],
    max5Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 5 || "Max Length must be 5 characters or less"],
    numberRule: [(v) => parseInt(v) >= 0 || "Field is required"],
    max10Alphanumeric: [
      (v) => !v || /^[a-zA-Z0-9_]*$/i.test(v) || "Only Alphanumberic allowed",
      (v) => (v || "").length <= 10 || "Max Length must be 10 characters or less",
    ],
    max100Alphanumeric: [
      (v) => !v || /^[a-zA-Z0-9_]*$/i.test(v) || "Only Alphanumberic allowed",
      (v) => (v || "").length <= 100 || "Max Length must be 100 characters or less",
    ],
    max100RuleReq: [
      // (v) => parseInt(v) == 0 || "Field must be 0",
      (v) => parseInt(v) < 100 || "Field should be less than 100 ",
      (v) => parseInt(v) >= 0 || "Field should be equal or greather than 0",
    ],
    businessName: "",
    businessCode: "",
    ntfCode: "NTF",
    ntfDescription: "NO TROUBLE FOUND",
    internal: true,
    showSOCheckBox: false,
    outLineText: "",
    disableAdd: true,
    edit: false,
    manual_allocation: false,
    bill2_key: "",
    searchWorkCenter: "",
    businessId: 0,
    buApiKey: "",
    contact_key: "",
    bill2_KeyPass: "",
    selectedFailCode: "",
    selectedInternalFailCode: "",
    contact_KeyPass: "",
    outboundProxy: "",
    auditRate: "",
    retrun_to_flag: false,
    isActive: true,
    Shipped_KeyPass: "",
    ship_Key: "",
    poPrefix: "",
    soPrefix: "",
    disableFailure: false,
    update: false,
    returnProxy: "",
    deleteBusinessDialog: false,
    shippLableCheck: false,
    companyInfo: [],
    failureList: [],
    tab: [1, 2, 3, 4, 5],
    seeBeyond: false,
    userId: EncryptUtility.localStorageDecrypt("userID"),
    fob: "",
    selectedProject: "",
    allowAddBusiness: false,
    list: [
      { name: "Allowed WorkCenters", active: false },
      { name: "Rules", active: false },
      { name: "Contact", active: false },
      { name: "Bill-To", active: false },
    ],
    selectedPayment: 0,
    allowedExepected: false,
    headersWork: [
      {
        text: "Active",
        align: "start",
        value: "Active",
      },
      {
        text: "Workcenters",
        value: "Workcenter",
      },
    ],
    paymentTerms: [],
    internalRepair: 0,
    bill2List: [],
    contactList: [],
    workCenterList: [],
    postal: "",
    validate: true,
    isFormValid: false,
    valid: false,
    shiploc_key_flag: false,
    bill2_key_flag: false,
    contact_key_flag: false,
  }),
  mounted() {
    // for Update
    if (this.name != "newBusiness") {
      this.businessId = this.bu_id;
      this.getBusinessDetails();
      // To get the Payment List
      this.getPaymentList(this.businessId);
      //Get work center than for add
      this.getWorkCenter(this.businessId, 1, "");
    } else {
      this.edit = true;
      //for Add work center
      this.getWorkCenter(0, 1, "");
      this.getPaymentList(0);
    }
  },
  methods: {
    getId(value, name, shiploc_key_flag, contact_key_flag, bill2_key_flag, showCheckBox) {
      if (name == "Contact") {
        (this.valid = true)
        this.contact_key = value;
        this.contact_key_flag = contact_key_flag;
      } else if (name == "Bill-To") {
        (this.bill2_key = value), (this.valid = true);
        this.bill2_key_flag = bill2_key_flag;
      } else if (name == "Shipped-From") {
        this.ship_Key = value;
        this.valid = true;
        this.shiploc_key_flag = shiploc_key_flag;
      }
      this.showSOCheckBox = showCheckBox;
    },
    //To check the value of internal failure loop these helps us enable or disable internal loop fail code
    checkInternalValue(value) {
      if (value > 0) {
        this.internal = false;
      } else {
        this.internal = true;
        this.selectedInternalFailCode = "";
      }
    },
    //Get Paytment List
    getPaymentList(id) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios.get(`/cp/business_master_details?bu_id=${id}&user_id=${this.userId}`).then((response) => {
        if (response.status == 200) {
          let responseData = response.data.body.message;
          // to store the drop down list of payment terms
          this.paymentTerms = JSON.parse(responseData).Lists;
          // to store the drop down list of fail code
          this.failureList = JSON.parse(responseData).FailInfo;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        } else {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: response.data.message,
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      });
    },

    disableTextbox() {
      this.outboundProxy = this.businessName
      this.returnProxy = this.businessName
      if (this.outLineText == "success" && this.businessName.length > 1 && this.businessCode.length > 1) {
        this.disableAdd = false
      }
      else {
        this.disableAdd = true
      }
    },
    //Get list of workstations
    getWorkCenter(businessId, passValue, object) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      //Data to get workcenter List
      let workcenterPram = {
        UserId: this.userId,
        bu_id: businessId,
        pass: passValue,
        Wc_Json: object,
      };
      this.axios.post("/cp/business_workcentre_list", workcenterPram).then((response) => {
        if (response.status == 200) {
          let responseData = response.data.body.message;
          this.workCenterList = JSON.parse(responseData);
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        } else {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: response.data.message,
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      });
    },
    //Add Busissines
    onClickAdd() {
      if (this.disableFailure) {
        if (parseInt(this.internalRepair) < 2147483647) {
          this.allowAddBusiness = true;
        } else {
          this.allowAddBusiness = false;
        }
      } else {
        this.allowAddBusiness = true;
      }
      if (this.$refs.businessAddForm.validate() && this.allowAddBusiness) {
        let workCenters = [];
        if (!this.contact_key) {
          document.getElementById("tab3").click();
          let Alert = {
            type: "error",
            isShow: true,
            message: "Contact Address is empty",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
        if (!this.bill2_key) {
          document.getElementById("tab4").click();
          let Alert = {
            type: "error",
            isShow: true,
            message: "Bill to address is empty",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
        if (!this.ship_Key) {
          document.getElementById("tab5").click();
          let Alert = {
            type: "error",
            isShow: true,
            message: "Return To address is empty",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
        if (this.ship_Key && this.contact_key && this.bill2_key) {
          this.workCenterList.forEach((element) => {
            workCenters.push({ loc: element.loc, active: element.Active });
          });

          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };

          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let addObject = {
            BuJson: JSON.stringify({
              bu_id: this.businessId,
              proj_key: this.selectedProject,
              contact_key: this.contact_key,
              bu_name: this.businessName,
              bu_code: this.businessCode,
              active: this.isActive ? "1" : "0",
              ntf_code: this.ntfCode,
              ntf_desc: this.ntfDescription,
              ntf_key: 1,
              rcv_scrap_key: this.selectedFailCode != undefined && this.selectedFailCode != null ? this.selectedFailCode : "",
              repair_loop_num: this.internalRepair != undefined && this.internalRepair != null ? this.internalRepair : 0,
              repair_loop_failc_key:
                this.selectedInternalFailCode != undefined && this.selectedInternalFailCode != null ? this.selectedInternalFailCode : "",
              bill2_key: this.bill2_key,
              pterms: this.selectedPayment,
              fob: this.fob,
              ei_on: this.seeBeyond,
              proxy_name: this.outboundProxy,
              rtn_proxy_name: this.returnProxy,
              expected_sn: this.allowedExepected ? "1" : "0",
              pick_audit_rate: this.auditRate,
              UserId: parseInt(this.userId),
              workcenters: workCenters,
              shiploc_key: this.ship_Key,
              default_so_prefix: this.soPrefix,
              default_po_prefix: this.poPrefix,
              shiploc_key_flag: this.shiploc_key_flag,
              bill2_key_flag: this.bill2_key_flag,
              contact_key_flag: this.contact_key_flag,
              retrun_to_flag: this.retrun_to_flag,
              api_key: this.buApiKey,
              manual_allocation: this.manual_allocation
            }),
          };
          {
            this.axios
              .post("/cp/business_upsert", addObject)
              .then((response) => {
                if (response.status == 200 || response.status == 204) {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  this.compCalled = false;
                  this.$emit("businessChange");
                  this.name = "";
                  this.deleteBusinessDialog = false;
                  let Alert = {
                    type: "success",
                    isShow: true,
                    message: response.data.body.message,
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: response.data.body.message,
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              })
              .catch((error) => {

                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: Utility.apiError(error),
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.errorMessage = error.message;
              });
          }
        }


      }
    },
    //To pre-populate the values
    assignValues() {
      this.businessName = this.companyInfo[0].business;
      this.businessCode = this.companyInfo[0].bu_code;
      this.isActive = this.companyInfo[0].active;
      this.selectedProject = this.companyInfo[0].proj_key;
      this.outboundProxy = this.companyInfo[0].proxy_name;
      this.returnProxy = this.companyInfo[0].rtn_proxy_name;
      this.ntfCode = this.companyInfo[0].ntf_code;
      this.ntfDescription = this.companyInfo[0].ntf_desc;
      this.allowedExepected = this.companyInfo[0].expected_sn;
      this.auditRate = this.companyInfo[0].pick_audit_rate;
      this.fob = this.companyInfo[0].fob;
      this.bill2_KeyPass = this.companyInfo[0].b_custno;
      this.contact_KeyPass = this.companyInfo[0].c_custno;
      this.contact_key = this.companyInfo[0].c_comp_id;
      this.bill2_key = this.companyInfo[0].b_comp_id;
      this.ship_Key = this.companyInfo[0].shiploc_key;
      this.Shipped_KeyPass = this.companyInfo[0].shiploc_key;
      this.shippLableCheck = this.companyInfo[0].isShipLocDiff;
      this.soPrefix = this.companyInfo[0].default_so_prefix;
      this.poPrefix = this.companyInfo[0].default_po_prefix;
      this.internalRepair = this.companyInfo[0].repair_loop_num;
      this.buApiKey = this.companyInfo[0].api_key;
      this.manual_allocation = this.companyInfo[0].manual_allocation;
    },
    //On Switch Change
    onSwitchChange() {
      let workCenters = [];
      this.workCenterList.forEach((element) => {
        workCenters.push({ loc: element.loc, active: element.Active });
      });
      let obj = JSON.stringify({ workcenters: workCenters });
      //switch Change of new Warehouse
      if (this.name == "newWarehouse") {
        this.getWorkCenter(0, 2, obj);
      } else {
        //swicth change for edit
        this.getWorkCenter(this.businessId, 2, obj);
      }
    },
    //On click Cancel
    onClickCancel() {
      if (this.deleteBusinessDialog) {
        this.deleteBusinessDialog = false;
      } else {
        this.compCalled = false;
        this.$emit("hide");
        this.name = "";
      }
    },
    //Delete Business
    deleteBusiness() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let deleteBusiness = {
        bu_id: parseInt(this.bu_id),
        UserId: parseInt(this.userId),
      };
      this.axios
        .post("/cp/business_delete", deleteBusiness)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.deleteBusinessDialog = false;
            this.onClickCancel();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //To Get Business details
    getBusinessDetails() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/business_details?bu_id=${this.businessId}&user_id=${this.userId}`)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let details = JSON.parse(response.data.body.message);
            this.companyInfo = details.CompInfo;
            this.assignValues(this.companyInfo);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //TO allow edit on Click of an icon
    allowEdit() {
      this.edit = true;
      this.showUpdate = true;
      if (this.failureList.length > 0) {
        this.disableFailure = true;
      }
    },
    //Close the popup
    closeDialog() {
      this.compCalled = false;
      this.$emit("hide");
      this.name = "";
      this.deleteBusinessDialog = false;
    },
    //To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList !== null) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    checkBuiness(pass, value) {
      this.businessName=this.businessName?.toUpperCase()
      if (pass == 2) {
        this.businessCode = this.businessCode?.toUpperCase()
        this.buApiKey = this.businessCode
      }
      if (value.length > 1) {
        {
          let buObj = {
            user_id: this.userId,
            pass: pass == 1 ? 1 : 2,
            bu_code: pass == 1 ? "" : value,
            business: pass == 1 ? value : this.businessName
          }
          this.axios
            .post("/cp/business_check_unique", buObj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {

                this.outLineText = "success"
              } else {
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.outLineText = "error"
              }
            })
            .catch((error) => {
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.outLineText = "error"
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      }
    },

  },
  components: {
    companyAddressComp,
  },
};
