<template>
  <div>
    <!-- S:Bread crumbs -->
    <v-row>
      <v-col sm="12" md="12" lg="12" class="pa-10">
        <v-card elevation="1" class="containerbox">
          <breadcrumbComp class="hidden-sm-and-down" :mainPage="mainPage" :backSlash="backSlash"
            :backSlashParent="backSlashParent" :subPage="subPage" :subPageUrl="subPageUrl" :pageUrl="pageUrl"
            :appPageName="appPageName" :pageName="pageName" :showEdit="showEdit" :showDelete="showDelete"
            @editClicked="editClick()" @deleteClicked="deleteClick()" back="true" :loginHistory="loginHistory"
            :actionHistory="actionHistory" @userHistoryClicked="userHistory()"
            @userActionHistoryClicked="userActionHistory()" :showUpdate="showUpdate" @updateFunction="updateFunction"/>

          <breadcrumbComp class="hidden-md-and-up" :backSlashParent="backSlashParent" :pageUrl="pageUrl"
            :appPageName="appPageName" :pageName="pageName" :showEdit="showEdit" :showDelete="showDelete"
            @editClicked="editClick()" @deleteClicked="deleteClick()" back="true" :loginHistory="loginHistory"
            :actionHistory="actionHistory" @userHistoryClicked="userHistory()"
            @userActionHistoryClicked="userActionHistory()" />

          <!-- E:Breadcrumbs -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="pa-5">
              <v-card outlined color="transparent">
                <v-card-text class="pb-0 pt-0" v-if="this.userData[0] !== undefined">
                  <!-- To call the child component and pass the data to child component -->
                  <userForm :edit="edit" :pageName="pageName" :pemail="this.userData[0].Email"
                    :pfirstName="this.userData[0].FName" :plastName="this.userData[0].LName"
                    :pinstallers="this.userData[0].InstallId" :ptheme="this.userData[0].ThemeCode"
                    :pphone="this.userData[0].Phone" :prole="this.userData[0].RoleInfo" :puserId="userIdNum"
                    :active="this.userData[0].IsActive" :pShowResetPwdByAdmin="showResetPwdByAdmin"
                    :editActive="editActive" :locked="this.userData[0].Locked" :pShowUnlockUser="showUnlockUser"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Delete Menu -->
    <v-row justify="center">
      <v-col sm="12" md="6" lg="6">
        <v-dialog v-model="deleteMenuDialog" max-width="500px">
          <v-card class="add-menu containerbox">
            <v-card-title class="text-subtitle-1 mx-auto">Are you sure you want to delete this item?</v-card-title>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primarytext" outlined dense class="ma-2 btn-style" elevation="1"
                @click="deleteUser()">OK</v-btn>
              <v-btn color="secondary" outlined dense class="ma-2 btn-style" elevation="1"
                @click="onClickCancel()">Cancel
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <!--User Login History-->
    <v-row>
      <v-col sm="11" md="11" lg="11">
        <v-dialog v-model="loginHistoryDialog" width="70%" persistent>
          <v-card class="overflow-x-hidden overflow-y-hidden containerbox">
            <v-card-title class="subtitle-2 primary customwhite--text">
              User Login History
              <v-spacer></v-spacer>
              <v-btn outlined class="pa-0" dense small title="Close" color="customwhite" @click="closeDialog()">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-row>
              <v-col sm="12" md="12" lg="12" cols="12">
                <v-data-table id="acchistory" v-model="acchistory" item-key="Activity" :headers="acchisheaders"
                  :items="acchistoryitems" :items-per-page="5" class="pa-10">
                  <template v-slot:item="props">
                    <tr>
                      <td>
                        {{ props.item.AppCode }}
                      </td>
                      <td v-if="props.item.Success">
                        <span class="successMsg">Success</span>
                      </td>
                      <td v-else>
                        <span class="failMsg">Fail</span>
                      </td>
                      <td>
                        {{ props.item.CreatedDate }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col sm="12" md="12" lg="12" class="pb-3 text-xs-right pr-5 pb-5 pt-5">
                <v-btn class="ma-2 btn-style float-right" outlined color="secondary" elevation="1"
                  @click="closeDialog()">
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <!--User Action History-->
    <v-row>
      <v-col sm="11" md="11" lg="11">
        <v-dialog v-model="actionHistoryDialog" width="70%" persistent>
          <v-card class="overflow-x-hidden overflow-y-hidden containerbox">
            <v-card-title class="subtitle-2 primary customwhite--text">
              User Activity
              <v-spacer></v-spacer>
              <v-btn outlined class="pa-0" dense small title="Close" color="customwhite" @click="closeDialog()">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-row>
              <v-col sm="12" md="12" lg="12" cols="12">
                <v-data-table v-model="actionHistory" item-key="Activity" :headers="actionHeaders"
                  :items="actionHistoryItems" :items-per-page="5" class="pa-10">
                </v-data-table>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col sm="12" md="12" lg="12" class="pb-3 text-xs-right pr-5 pb-5 pt-5">
                <v-btn class="ma-2 btn-style float-right" outlined color="secondary" elevation="1"
                  @click="closeDialog()">
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Utility from '../../shared/utility';
import userForm from './app-user-form-comp.vue';
import EncryptUtility from '../utility/js/encrypt-utility';
import breadcrumbComp from '../common/breadcrumb-comp.vue';
import AddFavourite from "../utility/js/addFav"
import { bus } from "../../main";
export default {
  data: () => ({
    pageName: 'Update User',
    userId: EncryptUtility.localStorageDecrypt('userID'),
    userIdNum: '',
    mainPage: "",
    onUpdateClicked:false,
    subPage: "",
    subPageUrl: "",
    pageUrl: "",
    appPageName: "",
    userData: [],
    backSlashParent: true,
    backSlash: true,
    data: [],
    edit: true,
    showDelete: false,
    showEdit: true,
    actionList: [],
    route: '',
    deleteMenuDialog: false,
    editActive: true,
    showResetPwdByAdmin: false,
    showUnlockUser: false,
    editRoles: true,
    loginHistory: false,
    acchistoryitems: [],
    loginHistoryDialog: false,
    userEmail: "",
    actionHistoryDialog: false,
    actionHistoryItems: [],
    actionHistory: false,
    showUserLoginHistory: false,
    showUpdate:false,
    showUserActivityHistory: false,
    acchisheaders: [
      { text: "App Code", align: "start", value: "AppCode" },
      { text: "Status", value: "Success" },
      { text: "Created Date", value: "CreatedDate" },
    ],
    actionHeaders: [
      { text: "App User Name", align: "start", value: "app_user_name" },
      { text: "Comments", value: "comments" },
      { text: "Created By", value: "created_by" },
      { text: "Created Data", value: "created_date" }
    ],
  }),
  async created() {
    this.primaryName = this.$cookies.get("primaryName");
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data?.actionList;
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    this.partsID = atob(this.$route.params.id);
    this.showEdit = this.actionSetter("EditUser");
    this.showResetPwdByAdmin = this.actionSetter("ResetUserPasswordByAdmin");
    this.editActive = this.actionSetter("EditUser_Active");
    this.editRoles = this.actionSetter("EditUser_EditRoles");
    this.showUnlockUser = this.actionSetter("UnlockUser");
    this.showUserLoginHistory = this.actionSetter("ShowUserLoginHistory");
    this.showUserActivityHistory = this.actionSetter("ShowUserActivity");
  },
  mounted() {
    //get User Id from URL
    this.userIdNum = atob(this.$route.params.id);
    let userID = atob(this.$route.params.id);
    this.getUserDetails(userID);
  },
  methods: {
    //Login History button click event
    userHistory() {
      this.getUserHistory();
      this.loginHistoryDialog = true;
    },
    //User Action History button click event
    userActionHistory() {
      this.getUserActionHistory();
      this.actionHistoryDialog = true;
    },
    updateFunction(){
      this.onUpdateClicked=true
      bus.$emit("onClickUserUpdate", this.onUpdateClicked);
    },
    //Close Dialog function for the popup
    closeDialog() {
      if (this.loginHistoryDialog) {
        this.loginHistoryDialog = false;
        this.acchistoryitems = [];
      } else if (this.actionHistoryDialog) {
        this.actionHistoryDialog = false;
      }
    },
    //Getting User History
    getUserHistory() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`ap/login_history?email=${this.userEmail}&UserId=${this.userIdNum}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            if (responseData !== null) {
              const newArr = responseData.map((obj) => {
                return {
                  ...obj,
                  CreatedDate: Utility.convertESTToLocal(obj.CreatedDate),
                };
              });
              this.acchistoryitems = newArr;
              if (this.showUserLoginHistory) {
                this.loginHistory = true;
              }
            } else {
              this.loginHistory = false;
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Action history of the user
    getUserActionHistory() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchObject = {
        Pass: 1,
        UserId: parseInt(this.userIdNum),
        ActionUserId: 0,
        Comments: "",
      };
      this.axios
        .post('/up/user_action', searchObject)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            if (responseData !== null) {
              const newArr = responseData.map((obj) => {
                return {
                  ...obj,
                  created_date: Utility.convertESTToLocal(obj.created_date),
                };
              });
              this.actionHistoryItems = newArr;
              if (this.showUserActivityHistory) {
                this.actionHistory = true;
              }
            } else {
              this.actionHistory = false;
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Page Level action 
    actionSetter(action) {
      if (this.actionList !== null) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action,
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
    //Get Page Level action List
    getPageAction(url, userId) {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      this.axios
        .get(
          `/ap/user_page_actions?user_id=${userId}&page_url=${url}`, //URL Hard coded
        )
        .then((response) => {
          if (response.status == 200) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.actionList = responsedata.Action;
            this.mainPage = responsedata.GrandParentName
            this.subPage = responsedata.ParentName
            this.subPageUrl = responsedata.ParentUrl
            this.pageUrl = responsedata.PageUrl
            this.appPageName = responsedata.PageName
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            var Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          var Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //TO get the user Details with Respect to User ID
    editClick() {
      this.showEdit=false;
      this.showUpdate=true
      this.edit = false;
      if (!this.edit && this.editRoles) {
        //CSS Fixes to removed disabled roles
        let tblChkBxCss = document.getElementById("tblRoles");
        tblChkBxCss.classList.remove("roleChkBox-disable");
        tblChkBxCss.classList.add("roleChkBox-enable");
      }
    },
    //Delete button click event
    deleteClick() {
      this.deleteMenu()
    },
    // Get user details
    getUserDetails(userID) {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      this.axios
        .get(`/up/user_details_roles?userId=${userID}&ExecUserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            if (responseData !== undefined && responseData !== null) {
              this.userData = responseData;
              this.userEmail = this.userData[0].Email;
              this.getUserHistory();
              this.getUserActionHistory();
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            var Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          var Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    deleteMenu() {
      this.deleteMenuDialog = true;
    },
    editUser() {
      this.edit = false;
    },
    //To delete User
    deleteUser() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      let userID = parseInt(atob(this.$route.params.id));
      let userData = {
        userId: userID,
      };
      this.axios
        .post('/up/user_delete', userData)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'success',
              isShow: true,
              message: response.data.message,
            };
            this.$router.push('/app-user');
            this.deletedialog = false;
            this.$store.commit('ConfigModule/Alert', Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.deleteMenuDialog = false;
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //To hide Delete Dialog
    onClickCancel() {
      this.deleteMenuDialog = false;
    },
  },
  //Decalre the component user
  components: {
    userForm,
    breadcrumbComp,
  },
};
</script>

<style scoped>