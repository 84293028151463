// import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import AddFavourite from "../../utility/js/addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import inventoryService from "./inventory-service.js";
import html2pdf from "html2pdf.js";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      controlID: "",
      controlIDList: [],
      disableControlID: false,
      binRow: "",
      binRowList: [],
      disableBinRow: true,
      disableScanLog: true,
      disableClearUnit: true,
      showBins: false,
      binList: [],
      pdfName: "",
      multiplePDF: "",
      printAuditObj: {
        user_id: 0,
        pass: 0,
        controlID: 0,
        row: "",
        bin_Key: 0,
      },
      binHeaders: [
        { text: "Bin", align: "start", value: "Bin", class: "primary customwhite--text" },
        { text: "Counted", value: "Counted", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getControlIDList();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.controlID = "";
      this.controlIDList = [];
      this.disableControlID = false;
      this.binRow = "";
      this.binRowList = [];
      this.disableBinRow = true;
      this.disableScanLog = true;
      this.disableClearUnit = true;
      this.showBins = false;
      this.binList = [];
      this.binObj = [];
      this.selectedBins = [];
      this.pdfName = "";
      this.multiplePDF = "";
      this.printAuditObj = {
        user_id: 0,
        pass: 0,
        controlID: 0,
        row: "",
        bin_Key: 0,
      };
      this.getControlIDList();
    },
    //Control Dropdowns API call
    async getControlIDList() {
      this.printAuditObj.pass = 1;
      this.printAuditObj.user_id = parseInt(this.userId);
      let data = await inventoryService.postPrintAuditSheets("post", this.printAuditObj, false);
      if (data.message !== "NA") {
        this.controlIDList = data;
      }
    },
    //Change in control id
    async changeControlID() {
      this.printAuditObj.pass = 2;
      this.printAuditObj.user_id = parseInt(this.userId);
      this.printAuditObj.controlID = parseInt(this.controlID);
      let data = await inventoryService.postPrintAuditSheets("post", this.printAuditObj, false);
      if (data.message !== "NA") {
        this.disableControlID = true;
        this.binRowList = data;
        this.disableBinRow = false;
        this.disableScanLog = false;
      }
    },
    //Change in Bin row
    async changeBinRow() {
      this.printAuditObj.pass = 3;
      this.printAuditObj.user_id = parseInt(this.userId);
      this.printAuditObj.controlID = parseInt(this.controlID);
      this.printAuditObj.row = this.binRow;
      let data = await inventoryService.postPrintAuditSheets("post", this.printAuditObj, false);
      if (data.message !== "NA") {
        this.showBins = true;
        this.binList = data;
        this.disableBinRow = true;
        this.disableClearUnit = false;
      }
    },
    //Print Sheets button click
    async printSheets() {
      if (this.selectedBins !== undefined) {
        for (let i = 0; i < this.selectedBins.length; i++) {
          this.printAuditObj.pass = 4;
          this.printAuditObj.user_id = parseInt(this.userId);
          this.printAuditObj.controlID = parseInt(this.controlID);
          this.printAuditObj.row = this.binRow;
          this.printAuditObj.bin_Key = this.selectedBins[0].bin_id;
          let data = await inventoryService.postPrintAuditSheets("post", this.printAuditObj, false);
          if (data.message !== "NA") {
            this.multiplePDF = document.createElement("div");
            let response = this.createPage(data?.Grid, data?.Header[0]);
            this.multiplePDF.appendChild(response);
          }
        }
        this.printRecords(this.multiplePDF);
        this.resetFunction();
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select any Bin",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Export pdf
    printRecords(response) {
      this.startDownload();
      this.pdfName =
        "Print_Audit_Sheets" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(response, {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
      this.finishDownload();
    },
    createPage(response, headerData) {
      let container = document.createElement("div");
      let headerDiv = document.createElement("div");
      headerDiv.style.marginBottom = "20px";
      headerData = this.transformHeaderData(headerData);
      for (let key in headerData) {
        let p = document.createElement("p");
        p.style.margin = "0";
        p.style.fontSize = "14px";
        p.textContent = `${key}: ${headerData[key]}`;
        headerDiv.appendChild(p);
      }
      container.appendChild(headerDiv);
      let tblTag = document.createElement("table");
      tblTag.setAttribute("cellpadding", "0");
      tblTag.setAttribute("cellspacing", "0");
      tblTag.style.border = "1px solid black";
      tblTag.style.borderRight = "none";
      tblTag.style.borderTop = "none";
      tblTag.style.width = "100%";
      let headers = ["Bin", "Part#", "Quantity", "BCN", "SerialNo", "Logged", "Logged By"];
      let trHeader = document.createElement("tr");
      headers.forEach((header) => {
        let th = document.createElement("th");
        th.style.border = "1px solid black";
        th.style.padding = "10px";
        th.style.borderLeft = "none";
        th.style.borderBottom = "none";
        th.style.backgroundColor = "#005ba8";
        th.style.color = "white";
        th.textContent = header;
        trHeader.appendChild(th);
      });
      tblTag.appendChild(trHeader);
      response.forEach((row) => {
        let tr = document.createElement("tr");
        headers.forEach((header) => {
          let td = document.createElement("td");
          td.style.border = "1px solid black";
          td.style.padding = "10px";
          td.style.borderLeft = "none";
          td.style.borderBottom = "none";
          td.textContent = row[header] || "";
          tr.appendChild(td);
        });
        tblTag.appendChild(tr);
      });
      container.appendChild(tblTag);
      container.style.paddingBottom = "20px";
      return container;
    },
    transformHeaderData(headerArray) {
      const header = headerArray;
      return {
        BIN: header.bin || "",
        "Printed By": header.username || "",
        "Printed Date": header.printdate || "",
        "Audited By": header.auditby || "",
        "Audited Date": header.auditdate || "",
      };
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
