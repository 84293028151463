import breadcrumbComp from "../../../src/components/common/breadcrumb-comp.vue";
import ReadMore from "../../../src/components/common/read-more.vue";
import btoService from "./bto-service"
import EncryptUtility from "../../components/utility/js/encrypt-utility";
import Utility from "../../shared/utility";
import AddFavourite from "../../components/utility/js/addFav";
export default {
 
  data() {
    return {
        mainPage:"",
        actionList:"",
        favouriteList:"",
        pageUrl:"",
        subPage:"",
        showAdd:"",
        backSlash:true,
        refresh: true,
        btoSearch:"",    
        totalRecords:0,
        expanded: [],
        selectedProject:[],
        selectedBusiness:[],
        disableRun:true,
        userId: EncryptUtility.localStorageDecrypt("userID"),
        projectList:[],
        disableBusiness:true,
        searchFilter:"",
        btoListData:[],
        businessList:[],
        btoListHeader: [
            { text: '', value: 'data-table-expand', class: "primary customwhite--text", },
            { text: "Project/Business", value: "project", class: "primary customwhite--text", },
            { text: "BTO/KIT #", value: "bono", class: "primary customwhite--text", },
            { text: "RLP Order", value: "sono", class: "primary customwhite--text", },
            { text: "Customer ID", value: "RITM", class: "primary customwhite--text" },
            { text: "Part Number", value: "part_num", class: "primary customwhite--text", },
            { text: "Unit ID", value: "unit_id", class: "primary customwhite--text" },
            { text: "Status", value: "status", class: "primary customwhite--text" },
            { text: "Bin/LPN", value: "bin", class: "primary customwhite--text" },
            { text: "Source Date", value: "assign_date", class: "primary customwhite--text" },
            { text: "Action", value: "actions", class: "primary customwhite--text" },
          ],
          filterObj:{
            UserID: EncryptUtility.localStorageDecrypt("userID"),
            ddl_pass: 2,
            projkey_list: "[]",
            bukey_list: "[]",
          }
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  async mounted() {
    let projectData=await btoService.getBtoListDropDown("post",this.filterObj,false)
    this.projectList=projectData
    console.log(EncryptUtility.localStorageDecrypt("btoSearch"))
    if (EncryptUtility.localStorageDecrypt("btoSearch")) {
      let jsonData=JSON.parse(EncryptUtility.localStorageDecrypt("btoSearch"))
      this.selectedProject=JSON.parse(jsonData.json).projkey_list
      this.selectedBusiness=JSON.parse(jsonData.json).bukey_list
      this.onChangeProject()
      this.onClickRun()
      this.disableRun=false
    }
    
  },
  methods: {
    actionSetter(permission) {
      return Utility.actionSetter(this.actionList, permission)
    },
    async onClickSearch(){
        let resp= await btoService.getBtoListQuickSearch("get",this.userId,this.btoSearch)
        this.tableData(resp) 
    },
    addFav() {
        this.showAdd = !this.showAdd;
        AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
      },
    clearTable(){
        this.totalRecords=0;
        this.btoListData=[];
    },
    async onChangeProject(){
        this.filterObj.projkey_list=JSON.stringify(this.selectedProject)
        this.disableBusiness=false;
        this.filterObj.ddl_pass=3;
        let buinessData=await btoService.getBtoListDropDown("post",this.filterObj,false);
        this.businessList=buinessData;  
        console.log(this.filterObj.projkey_list)  
    },
    async onChangeBusiness(){
      this.disableRun=false
    },
    tableData(resp){
        this.btoListData=resp
        if(resp){
          console.log(resp)
        this.totalRecords=resp.length
        this.expanded = this.btoListData.filter(item => item.config_notes).map(item => item.line_id);
        this.btoListData?.forEach((element) => {
            element.assign_date = Utility.convertESTToLocal(element.assign_date);
          });}
        else{
            this.clearTable()
        }
    },
    async onClickRun(){
      let jsonObj={
        projkey_list:this.selectedProject,
        bukey_list:this.selectedBusiness
      }
        let obj={
            user_id:this.userId,
            json:JSON.stringify(jsonObj)
        }
        EncryptUtility.localStorageEncrypt("btoSearch", JSON.stringify(obj));
        console.log()
        let resp=await btoService.getBtoFilterSearch("post",obj,false);
        this.tableData(resp)     
        
    },
    clearObject(){
        this.filterObj={
            UserID: EncryptUtility.localStorageDecrypt("userID"),
            ddl_pass: 2,
            projkey_list: "[]",
            bukey_list: "[]",
          }
          this.selectedProject=[]
          this.selectedBusiness=[]
          

    },
    clearFilter(){
        this.clearTable();
        this.clearObject();
    },
    resetPage(){
        this.clearFilter();
        this.btoSearch=""
        EncryptUtility.localStorageEncrypt("btoSearch", "");
        this.disableRun=true
    },
    onClickDetails(item){
      let bto_id=item.bono;
      this.$router.push(`/bto-details/${btoa(bto_id.toString())}`);
    }


   
   
  },
  components: {
    breadcrumbComp,
    ReadMore
  },
};
