import Utility from "../../../shared/utility.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import exportLimit from "../../common/export-limit.vue";
import usbService from "../js/usb-services";
import router from "../../../router/router";
import html2pdf from "html2pdf.js";
import PrinterDetails from "../../common/printer-details";
import VueJsonToCsv from "vue-json-to-csv";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      searchField: "",
      selectedEvent: 0,
      eventList: [],
      shippedDevices: false,
      selectedAssetAction: "",
      assetActionList: [],
      showData: false,
      totalRecords: 0,
      assetTrackingList: [],
      searchAssetTracking: "",
      export50kData: false,
      pdfName: "",
      totalData: [],
      excelName: "",
      showPrimary: true,
      csvName: "",
      totalLotDetails: [],
      json_fields: {
        "Serial Number": "unit_indentifier",
        "RLP Order": "order",
        "Current Event": "current_event_code",
        Action: "TrackingTypeDescription",
        "RLP Location": "RLP_location_description",
        "Date Received": "rcvdate",
      },
      csv_fields: {
        unit_indentifier: { title: "Serial Number" },
        order: { title: "RLP Order" },
        current_event_code: { title: "Current Event" },
        TrackingTypeDescription: { title: "Action" },
        RLP_location_description: { title: "RLP Location" },
        rcvdate: { title: "Receive Date" },
      },
      apiObj: {
        UserID: 0,
        SearchStr: "",
        EventCodeID: 0,
        IncludeShipped: 0,
        StartIndex: 0,
        PageSize: 0,
        TrackingTypeCode: "",
      },
      assetTrackingHeaders: [
        { text: "Serial Number", value: "unit_indentifier", class: "primary customwhite--text" },
        { text: "RLP Order", value: "order", class: "primary customwhite--text" },
        { text: "Current Event", value: "current_details", class: "primary customwhite--text" },
        { text: "Action", value: "TrackingTypeDescription", class: "primary customwhite--text" },
        { text: "RLP Location", value: "RLP_location_description", class: "primary customwhite--text" },
        { text: "Date Received", value: "rcvdate", class: "primary customwhite--text" },
        { text: "Edit", value: "actions", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getEventCodes();
    this.getAssetActions();
    if (this.$route.params.submit !== undefined) {
      if (atob(this.$route.params.submit) == "submitted") {
        this.resetFunction();
      }
    } else {
      this.getSearch();
    }
  },
  mounted() {
    router.afterEach((to) => {
      if (!to.path.includes("usb-asset-tracking") && !to.path.includes("usb-asset-tracking-details")) {
        EncryptUtility.localStorageRemoveItem("SearchField");
        EncryptUtility.localStorageRemoveItem("SelectedEvent");
        EncryptUtility.localStorageRemoveItem("ShippedDevices");
        EncryptUtility.localStorageRemoveItem("SelectedAssetAction");
      }
    });
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.searchField = "";
      this.selectedEvent = 0;
      this.eventList = [];
      this.shippedDevices = false;
      this.selectedAssetAction = "";
      this.assetActionList = [];
      this.showData = false;
      this.totalRecords = 0;
      this.assetTrackingList = [];
      this.searchAssetTracking = "";
      this.export50kData = false;
      this.pdfName = "";
      this.totalData = [];
      this.excelName = "";
      this.csvName = "";
      this.totalLotDetails = [];
      this.apiObj = {
        UserID: 0,
        SearchStr: "",
        EventCodeID: 0,
        IncludeShipped: 0,
        StartIndex: 0,
        PageSize: 0,
        TrackingTypeCode: "",
      };
      this.getEventCodes();
      this.getAssetActions();
      EncryptUtility.localStorageRemoveItem("SearchField");
      EncryptUtility.localStorageRemoveItem("SelectedEvent");
      EncryptUtility.localStorageRemoveItem("ShippedDevices");
      EncryptUtility.localStorageRemoveItem("SelectedAssetAction");
    },
    getSearch() {
      if (
        EncryptUtility.localStorageDecrypt("SearchField") !== undefined ||
        EncryptUtility.localStorageDecrypt("SelectedAssetAction") !== undefined ||
        EncryptUtility.localStorageDecrypt("SelectedEvent") !== undefined ||
        EncryptUtility.localStorageDecrypt("ShippedDevices") !== undefined
      ) {
        this.searchField = EncryptUtility.localStorageDecrypt("SearchField");
        let intEvent = EncryptUtility.localStorageDecrypt("SelectedEvent");
        this.selectedEvent = parseInt(intEvent);
        let boolShip = EncryptUtility.localStorageDecrypt("ShippedDevices");
        this.shippedDevices = boolShip == "true" ? true : false;
        this.selectedAssetAction = EncryptUtility.localStorageDecrypt("SelectedAssetAction");
        this.onSearch();
      }
    },
    //Get Event Codes
    async getEventCodes() {
      let data = await usbService.getEventCodes("get");
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.eventList = data;
        }
      }
    },
    //Get Asset Action
    async getAssetActions() {
      let data = await usbService.getAssetActions("get");
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.assetActionList = data;
        }
      }
    },
    //On search button
    async onSearch() {
      this.apiObj.UserID = parseInt(this.userId);
      this.apiObj.SearchStr = this.searchField;
      this.apiObj.EventCodeID = parseInt(this.selectedEvent);
      this.apiObj.IncludeShipped = Boolean(this.shippedDevices) == true ? 1 : 0;
      this.apiObj.StartIndex = 1;
      this.apiObj.PageSize = 1;
      this.apiObj.TrackingTypeCode = this.selectedAssetAction;
      let showMessage = false;
      let data = await usbService.assetTrackingSearch("post", this.apiObj, showMessage);
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.showData = true;
          this.totalRecords = data.length;
          const newArr = data.map((obj) => {
            return {
              ...obj,
              rcvdate: Utility.convertESTToLocal(obj.rcvdate),
            };
          });
          this.assetTrackingList = newArr;
          EncryptUtility.localStorageEncrypt("SearchField", this.searchField.toString());
          EncryptUtility.localStorageEncrypt("SelectedEvent", this.selectedEvent.toString());
          EncryptUtility.localStorageEncrypt("ShippedDevices", this.shippedDevices.toString());
          EncryptUtility.localStorageEncrypt("SelectedAssetAction", this.selectedAssetAction.toString());
        } else {
          this.showData = false;
          this.totalRecords = 0;
          this.assetTrackingList = [];
        }
      } else {
        this.showData = false;
        this.totalRecords = 0;
        this.assetTrackingList = [];
      }
    },
    //Redirecting to details page
    editAssetTracking(item) {
      this.$router.push({
        name: "USBAssetTrackingDetails",
        params: {
          mainKey: btoa(item.main_key.toString()),
        },
      });
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //To export the Excel file
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        this.apiObj.UserID = parseInt(this.userId);
        this.apiObj.SearchStr = this.searchField;
        this.apiObj.EventCodeID = parseInt(this.selectedEvent);
        this.apiObj.IncludeShipped = this.shippedDevices == true ? 1 : 0;
        this.apiObj.StartIndex = 1;
        this.apiObj.PageSize = 1;
        this.apiObj.TrackingTypeCode = this.selectedAssetAction;
        this.excelName =
          "USB_Asset_Tracking_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.post("/usb/assettracking_search_results", this.apiObj);
        let responseData = JSON.parse(response.data.body.message);
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            rcvdate: Utility.convertESTToLocal(obj.rcvdate),
          };
        });
        this.export50kData = false;
        return newArr;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Export pdf
    async printDetails() {
      this.startDownload();
      let response = this.assetTrackingList;
      response.forEach((item) => {
        delete item["current_event_code"];
        delete item["current_event_description"];
        delete item["main_key"];
        delete item["inWIP"];
        delete item["inException"];
      });
      this.totalLotDetails = [];
      this.totalLotDetails = this.buildHtmlTable(response);
      this.pdfName =
        "USB_Asset_Tracking_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(this.totalLotDetails, {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
      this.finishDownload();
    },
    buildHtmlTable(response) {
      this.tblTag = document.createElement("table");
      this.trTag = document.createElement("tr");
      this.thTag = document.createElement("th");
      this.tdTag = document.createElement("td");
      let table = this.tblTag.cloneNode(false);
      table.setAttribute("cellpadding", "0");
      table.setAttribute("cellspacing", "0");
      table.style.border = "1px solid black";
      table.style.borderRight = "none";
      table.style.borderTop = "none";
      let columns = this.addAllColumnHeaders(response, table);
      columns = columns.filter((column) => column !== "tid");
      for (var i = 0, maxi = response.length; i < maxi; ++i) {
        var tr = this.trTag.cloneNode(false);
        for (var j = 0, maxj = columns.length; j < maxj; ++j) {
          var td = this.tdTag.cloneNode(false);
          td.style.border = "1px solid black";
          td.style.padding = "10px";
          td.style.borderLeft = "none";
          td.style.borderBottom = "none";
          var cellValue = response[i][columns[j]];
          td.appendChild(document.createTextNode(cellValue || ""));
          tr.appendChild(td);
        }
        table.appendChild(tr);
      }
      return table;
    },
    addAllColumnHeaders(response, table) {
      let columnSet = [];
      let tr = this.trTag.cloneNode(false);
      for (var i = 0, l = response.length; i < l; i++) {
        for (var key in response[i]) {
          if (columnSet.indexOf(key) === -1) {
            if (key != "tid") {
              columnSet.push(key);
              var th = this.thTag.cloneNode(false);
              th.style.border = "1px solid black";
              th.setAttribute("padding", "5px");
              th.style.borderLeft = "none";
              th.style.borderBottom = "none";
              th.style.backgroundColor = "#005ba8";
              th.style.color = "white";
              if (key == "unit_indentifier") key = "Serial Number";
              if (key == "order") key = "RLP Order";
              if (key == "current_details") key = "Current Event";
              if (key == "TrackingTypeDescription") key = "Action";
              if (key == "RLP_location_description") key = "RLP Location";
              if (key == "rcvdate") key = "Date Received";
              th.appendChild(document.createTextNode(key));
              tr.appendChild(th);
            }
          }
        }
      }
      table.appendChild(tr);
      return columnSet;
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Wnd the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //CSV Export functionality
    csvFileName() {
      this.csvName = "USB_Asset_Tracking_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_");
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    exportLimit,
    PrinterDetails,
    VueJsonToCsv,
  },
};
