import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import inventoryService from "../js/inventory-service";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      showActiveScopes: false,
      activeScopeItems: [],
      selectedScope: [],
      selectedScopeItems: [],
      binVal: "",
      disableBin: true,
      newLPN: "",
      disableNewLPN: true,
      serialNo: "",
      disableSerialNo: true,
      quantity: "",
      disableQuantity: true,
      disableSubmit: true,
      showSelectedScopeDetails: false,
      sessionData: [],
      binData: [],
      showCountNewBin: false,
      showReconcileBin: false,
      showBinCounts: false,
      showSessionCounts: false,
      quarantineMissData: [],
      showBinPopup: false,
      binCountItems: [],
      showSessionPopup: false,
      sessionCountItems: [],
      errorTitle: "",
      errorMessage: "",
      errorStatus: "",
      showErrorPopup: false,
      errorClass: "",
      unlockVal: "",
      count: 0,
      wareDetails: [],
      showWareSelection: false,
      disableAcceptAnyway: false,
      reconcileData: [],
      quarantineItems: [],
      showQuarantineUnits: false,
      excelName: "",
      exportDialog: false,
      export50kData: false,
      submitObj: {
        controlID: 0,
        sessionID: 0,
        UserId: 0,
        bin: "",
        lpn: "",
        unit: "",
        qty: 0,
        pass: 0,
        ware_key: 0,
        loc: "",
      },
      wareDetailsHeaders: [
        { text: "Warehouse", align: "start", value: "ware", class: "primary customwhite--text" },
        { text: "Location", value: "description", class: "primary customwhite--text" },
      ],
      quarantineHeaders: [
        { text: "Part#", align: "start", value: "Part#", class: "primary customwhite--text" },
        { text: "Original Bin", value: "Original Bin", class: "primary customwhite--text" },
        { text: "Quarantine Bin", value: "Quarantine Bin", class: "primary customwhite--text" },
        { text: "Was On LPN", value: "Was On LPN", class: "primary customwhite--text" },
        { text: "Location", value: "Location", class: "primary customwhite--text" },
        { text: "Quantity", value: "Qty", class: "primary customwhite--text" },
      ],
      activeScopeHeaders: [{ text: "Active Scopes", align: "center", value: "Active_Scopes", class: "primary customwhite--text" }],
      scopeSelectedHeaders: [
        { text: "Type", align: "start", value: "Type", class: "primary customwhite--text" },
        { text: "Project", value: "Project", class: "primary customwhite--text" },
        { text: "Part Types", value: "Part Types", class: "primary customwhite--text" },
        { text: "Business", value: "Business", class: "primary customwhite--text" },
        { text: "Serialized", value: "Serialized", class: "primary customwhite--text" },
        { text: "Non-Serialized", value: "Non-Serialized", class: "primary customwhite--text" },
        { text: "Parent Classes", value: "Parent Classes", class: "primary customwhite--text" },
        { text: "Sub Classes", value: "Sub-Classes", class: "primary customwhite--text" },
        { text: "Warehouses", value: "Warehouses", class: "primary customwhite--text" },
        { text: "Locations", value: "Locations", class: "primary customwhite--text" },
        { text: "Parts", value: "Parts", class: "primary customwhite--text" },
        { text: "Bins", value: "Bins", class: "primary customwhite--text" },
      ],
      binCountHeaders: [
        { text: "Unit", align: "start", value: "Unit", class: "primary customwhite--text" },
        { text: "Part#", value: "Part#", class: "primary customwhite--text" },
        { text: "Bin", value: "Bin", class: "primary customwhite--text" },
        { text: "LPN", value: "LPN", class: "primary customwhite--text" },
        { text: "Quantity", value: "Qty", class: "primary customwhite--text" },
      ],
      sessionCountHeaders: [
        { text: "Unit", align: "start", value: "Unit", class: "primary customwhite--text" },
        { text: "Part#", value: "Part#", class: "primary customwhite--text" },
        { text: "Bin", value: "Bin", class: "primary customwhite--text" },
        { text: "LPN", value: "LPN", class: "primary customwhite--text" },
        { text: "Quantity", value: "Qty", class: "primary customwhite--text" },
      ],
      lpnRule: [
        (v) => (v || "").length <= 39 || "Max Length of 39 character",
        (v) => !!v || "Field is required",
        (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000",
      ],
      json_fields: {
        "Part#": "Part#",
        "Original Bin": "Original Bin",
        "Quarantine Bin": "Quarantine Bin",
        "Was On LPN": "Was On LPN",
        Location: "Location",
        Quantity: "Qty",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getActiveScopes();
  },
  computed: {
    totalBin() {
      const sums = {
        Qty: 0,
      };
      this.binCountItems.forEach(({ Qty }) => {
        sums.Qty += Qty;
      });
      return sums;
    },
    totalSession() {
      const sums = {
        Qty: 0,
      };
      this.binCountItems.forEach(({ Qty }) => {
        sums.Qty += Qty;
      });
      return sums;
    },
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.showActiveScopes = false;
      this.activeScopeItems = [];
      this.selectedScope = [];
      this.selectedScopeItems = [];
      this.binVal = "";
      this.disableBin = true;
      this.newLPN = "";
      this.disableNewLPN = true;
      this.serialNo = "";
      this.disableSerialNo = true;
      this.quantity = "";
      this.disableQuantity = true;
      this.disableSubmit = true;
      this.showSelectedScopeDetails = false;
      this.sessionData = [];
      this.showCountNewBin = false;
      this.showReconcileBin = false;
      this.showBinCounts = false;
      this.showSessionCounts = false;
      this.quarantineMissData = [];
      this.showBinPopup = false;
      this.binCountItems = [];
      this.showSessionPopup = false;
      this.sessionCountItems = [];
      this.errorTitle = "";
      this.errorMessage = "";
      this.errorStatus = "";
      this.showErrorPopup = false;
      this.errorClass = "";
      this.unlockVal = "";
      this.binData = [];
      this.wareDetails = [];
      this.count = 0;
      this.showWareSelection = false;
      this.disableAcceptAnyway = false;
      this.reconcileData = [];
      this.quarantineItems = [];
      this.showQuarantineUnits = false;
      this.excelName = "";
      this.exportDialog = false;
      this.export50kData = false;
      this.submitObj = {
        controlID: 0,
        sessionID: 0,
        UserId: 0,
        bin: "",
        lpn: "",
        unit: "",
        qty: 0,
        pass: 0,
        ware_key: 0,
        loc: "",
      };
      this.$refs.scanUnitForm.resetValidation();
      this.getActiveScopes();
    },
    //Get Active Scopes
    async getActiveScopes() {
      let data = await inventoryService.getActiveScopes("get", this.userId);
      if (data.message !== "NA") {
        this.activeScopeItems = data;
        this.showActiveScopes = true;
      }
    },
    //On selecting scope on popup
    async onClickScope(item) {
      let obj = {
        UserId: parseInt(this.userId),
        ControlID: parseInt(item.control_id),
      };
      let showMsg = false;
      let data = await inventoryService.postActiveScopesSelected("post", obj, showMsg);
      if (data.message !== "NA") {
        this.selectedScope = item;
        this.showActiveScopes = false;
        this.showSelectedScopeDetails = true;
        this.selectedScopeItems = data.HeaderInfo;
        this.sessionData = data.SessionInfo;
        this.disableBin = false;
      }
    },
    //Change in Bin value
    async changeBin() {
      let obj = {
        UserId: parseInt(this.userId),
        controlID: parseInt(this.selectedScope.control_id),
        session_id: parseInt(this.sessionData[0].session_id),
        pass: 1,
        bin: this.binVal,
        lpn: "",
        unit: "",
        accept: false,
      };
      let showMsg = false;
      let data = await inventoryService.postActiveScopesSubmit("post", obj, showMsg);
      console.log("changeBin-data", data);
      if (data.message !== "NA") {
        if (this.count > 1) {
          this.disableAcceptAnyway = false;
        } else {
          this.disableAcceptAnyway = true;
        }
        if (data?.NextInfo[0]?.istatus == "RECOUNT") {
          this.errorClass = "";
          this.errorTitle = data?.NextInfo[0]?.title;
          this.errorMessage = data?.NextInfo[0]?.imessage;
          this.errorStatus = data?.NextInfo[0]?.istatus;
          this.errorClass = this.errorStatus + "Style";
          this.showErrorPopup = true;
        } else {
          this.binData = data?.BinInfo;
          if (data?.NextInfo[0]?.enable_lpn == "1") {
            this.disableNewLPN = false;
            this.disableBin = true;
            this.showCountNewBin = true;
            this.showBinCounts = true;
            this.showSessionCounts = true;
            if (this.actionSetter("ShowReconcileBin")) {
              this.showReconcileBin = true;
            }
          } else {
            this.disableNewLPN = true;
            this.disableBin = false;
            this.showCountNewBin = false;
            this.showReconcileBin = false;
            this.showBinCounts = false;
            this.showSessionCounts = false;
          }
        }
      }
    },
    //Cancel recount popup
    cancelRecount() {
      this.binVal = "";
      this.errorClass = "";
      this.showErrorPopup = false;
      this.$refs.scanUnitForm.resetValidation();
    },
    //Count New Bin click
    async onClickCountNewBin() {
      this.binVal = "";
      this.disableBin = false;
      this.newLPN = "";
      this.disableNewLPN = true;
      this.serialNo = "";
      this.disableSerialNo = true;
      this.quantity = "";
      this.disableQuantity = true;
      this.disableSubmit = true;
      this.binData = [];
      this.showCountNewBin = false;
      this.showReconcileBin = false;
      this.showBinCounts = false;
      this.showSessionCounts = false;
      this.$refs.scanUnitForm.resetValidation();
    },
    //Reconcile bin button click
    async onClickReconcileBin() {
      let obj = {
        UserId: parseInt(this.userId),
        controlID: parseInt(this.selectedScope.control_id),
        sessionID: parseInt(this.sessionData[0].session_id),
        pass: 1,
        bin: this.binVal,
      };
      let showMsg = false;
      let data = await inventoryService.postReconcileBin("post", obj, showMsg);
      if (data.message !== "NA") {
        console.log("onClickReconcileBin-data", data);
        this.errorClass = "";
        this.errorTitle = data?.HeaderInfo[0]?.title;
        this.errorMessage = data?.HeaderInfo[0]?.imessage;
        this.reconcileData = data?.HeaderInfo;
        this.showErrorPopup = true;
        this.showQuarantineMiss = true;
        if (data?.NextInfo !== undefined) {
          if (data?.NextInfo[0]?.istatus == "OK") {
            this.moveUnitsToQuarantine();
          }
        }
      }
    },
    //Bin Counts button click
    async onClickBinCounts() {
      let obj = {
        userid: parseInt(this.userId),
        controlID: parseInt(this.selectedScope.control_id),
        bin: this.binVal,
      };
      let showMsg = false;
      let data = await inventoryService.postBinCounts("post", obj, showMsg);
      if (data !== undefined) {
        if (data.message !== "NA") {
          console.log("onClickBinCounts-data", data);
          this.binCountItems = data;
          this.showBinPopup = true;
        }
      }
    },
    //Session Counts button click
    async onClickSessionCounts() {
      let obj = {
        userid: parseInt(this.userId),
        controlID: parseInt(this.selectedScope.control_id),
        session_id: parseInt(this.sessionData[0].session_id),
      };
      let showMsg = false;
      let data = await inventoryService.postSessionsCounts("post", obj, showMsg);
      if (data !== undefined) {
        if (data.message !== "NA") {
          console.log("onClickSessionCounts-data", data);
          this.sessionCountItems = data;
          this.showSessionPopup = true;
        }
      }
    },
    //Quarantine popup yes button click event
    async moveUnitsToQuarantine() {
      let obj = {
        UserId: parseInt(this.userId),
        controlID: parseInt(this.selectedScope.control_id),
        sessionID: parseInt(this.sessionData[0].session_id),
        pass: 2,
        bin: this.binVal,
      };
      let showMsg = false;
      let data = await inventoryService.postReconcileBin("post", obj, showMsg);
      if (data.message !== "NA") {
        console.log("moveUnitsToQuarantine-data", data);
        this.quarantineItems = data?.DetailInfo;
        this.showQuarantineUnits = true;
        this.errorClass = "";
        this.showErrorPopup = false;
      }
    },
    //Password matches reload Bin info
    async reloadBinInfo() {
      let obj = {
        UserId: parseInt(this.userId),
        controlID: parseInt(this.selectedScope.control_id),
        bin: this.binVal,
      };
      let showMsg = false;
      let data = await inventoryService.postScanBinInfo("post", obj, showMsg);
      if (data.message !== "NA") {
        console.log("reloadBinInfo-data", data);
        this.binData = data;
        this.errorClass = "";
        this.showErrorPopup = false;
        this.serialNo = "";
        this.quantity = "";
        this.$refs.scanUnitForm.resetValidation();
      }
    },
    //Accept anyway button click event
    async acceptAnyway() {
      let obj = {
        UserId: parseInt(this.userId),
        controlID: parseInt(this.selectedScope.control_id),
        session_id: parseInt(this.sessionData[0].session_id),
        pass: 3,
        bin: this.binVal,
        lpn: this.newLPN,
        unit: this.serialNo,
        accept: true,
      };
      let showMsg = false;
      let data = await inventoryService.postActiveScopesSubmit("post", obj, showMsg);
      if (data.message !== "NA") {
        console.log("acceptAnyway-data", data);
        this.errorClass = "";
        this.errorTitle = data?.NextInfo[0]?.title;
        this.errorMessage = data?.NextInfo[0]?.imessage;
        this.errorStatus = data?.NextInfo[0]?.istatus;
        this.errorClass = this.errorStatus + "Style";
        this.disableSubmit = data?.NextInfo[0]?.enable_submit == "0" ? true : false;
        this.disableQuantity = data?.NextInfo[0]?.enable_qty == "0" ? true : false;
        if (this.disableQuantity || this.disableSubmit) {
          this.disableSerialNo = false;
        } else {
          this.disableSerialNo = true;
        }
        if (!this.disableSubmit) {
          this.scanUnitsSubmitPass2();
        }
        if (data?.NextInfo[0]?.imessage !== null && data?.NextInfo[0]?.imessage !== "") {
          if (this.count > 1) {
            this.disableAcceptAnyway = false;
          } else {
            this.disableAcceptAnyway = true;
          }
          this.showErrorPopup = true;
        } else {
          this.showErrorPopup = false;
        }
      }
    },
    //Clear Retry method
    clearRetry() {
      this.errorClass = "";
      this.serialNo = "";
      this.showErrorPopup = false;
      this.$refs.scanUnitForm.resetValidation();
    },
    //Recount button click event
    async recountButton() {
      let obj = {
        UserId: parseInt(this.userId),
        controlID: parseInt(this.selectedScope.control_id),
        session_id: parseInt(this.sessionData[0].session_id),
        pass: 99,
        bin: this.binVal,
      };
      let showMsg = false;
      let data = await inventoryService.postActiveScopesSubmit("post", obj, showMsg);
      if (data.message !== "NA") {
        console.log("recountButton-data", data);
        this.binData = data?.BinInfo;
        if (data?.NextInfo[0]?.enable_lpn == "1") {
          this.disableNewLPN = false;
          this.disableBin = true;
          this.showCountNewBin = true;
          this.showBinCounts = true;
          this.showSessionCounts = true;
          if (this.actionSetter("ShowReconcileBin")) {
            this.showReconcileBin = true;
          }
        } else {
          this.disableNewLPN = true;
          this.disableBin = false;
          this.showCountNewBin = false;
          this.showReconcileBin = false;
          this.showBinCounts = false;
          this.showSessionCounts = false;
        }
        this.errorClass = "";
        this.showErrorPopup = false;
      }
    },
    //Change in LPN Number
    async changeLPNNumber() {
      let obj = {
        UserId: parseInt(this.userId),
        controlID: parseInt(this.selectedScope.control_id),
        session_id: parseInt(this.sessionData[0].session_id),
        pass: 2,
        bin: this.binVal,
        lpn: this.newLPN,
        unit: "",
        accept: false,
      };
      let showMsg = false;
      let data = await inventoryService.postActiveScopesSubmit("post", obj, showMsg);
      if (data !== undefined) {
        if (data.message !== "NA" && data.message !== null) {
          this.disableSerialNo = false;
          this.disableNewLPN = true;
          this.count = 0;
        } else {
          this.disableSerialNo = true;
          this.disableNewLPN = false;
        }
      }
    },
    //Change in Part/BCN/Serial No./ESN
    async changePartNumber() {
      let obj = {
        UserId: parseInt(this.userId),
        controlID: parseInt(this.selectedScope.control_id),
        session_id: parseInt(this.sessionData[0].session_id),
        pass: 3,
        bin: this.binVal,
        lpn: this.newLPN,
        unit: this.serialNo,
        accept: false,
      };
      let showMsg = false;
      let data = await inventoryService.postActiveScopesSubmit("post", obj, showMsg);
      if (data.message !== "NA") {
        this.errorClass = "";
        this.errorTitle = data?.NextInfo[0]?.title;
        this.errorMessage = data?.NextInfo[0]?.imessage;
        this.errorStatus = data?.NextInfo[0]?.istatus;
        this.errorClass = this.errorStatus + "Style";
        this.disableSubmit = data?.NextInfo[0]?.enable_submit == "0" ? true : false;
        this.disableQuantity = data?.NextInfo[0]?.enable_qty == "0" ? true : false;
        this.count = this.count + 1;
        console.log("count", this.count);
        this.disableSerialNo = !this.disableQuantity;
        if (!this.disableQuantity && data?.WareLocInfo?.length > 1) {
          this.wareDetails = data?.WareLocInfo;
          this.showWareSelection = true;
          this.disableSerialNo = true;
        }
        if (!this.disableSubmit) {
          this.quantity = 0;
          this.scanUnitsSubmitPass2();
        }
        if (data?.NextInfo[0]?.imessage !== null && data?.NextInfo[0]?.imessage !== "") {
          if (this.count > 1) {
            this.disableAcceptAnyway = false;
          } else {
            this.disableAcceptAnyway = true;
          }
          this.showErrorPopup = true;
        } else {
          this.showErrorPopup = false;
        }
      }
    },
    //Scan Units Submit call
    async scanUnitsSubmit() {
      console.log("selectedWare", this.selectedWare);
      if (this.quantity !== "" && this.quantity !== undefined) {
        if (this.$refs.scanUnitForm.validate()) {
          if (this.showWareSelection) {
            if (this.selectedWare !== undefined && this.selectedWare.length !== 0) {
              this.submitObj.UserId = parseInt(this.userId);
              this.submitObj.controlID = parseInt(this.selectedScope.control_id);
              this.submitObj.session_id = parseInt(this.sessionData[0].session_id);
              this.submitObj.pass = 1;
              this.submitObj.bin = this.binVal;
              this.submitObj.lpn = this.newLPN;
              this.submitObj.unit = this.serialNo;
              this.submitObj.qty = parseInt(this.quantity);
              this.submitObj.ware_key = parseInt(this.selectedWare[0]?.ware);
              this.submitObj.loc = this.selectedWare[0]?.loc;
              let showMsg = false;
              let data = await inventoryService.postScanUnitsSubmit("post", this.submitObj, showMsg);
              if (data.message !== "NA") {
                console.log("scanUnitsSubmit", data);
                if (data[0]?.istatus == "SUBMIT") {
                  this.scanUnitsSubmitPass2();
                } else if (data[0]?.istatus == "EXTRA") {
                  this.errorClass = "";
                  this.errorTitle = data[0]?.title;
                  this.errorMessage = data[0]?.imessage;
                  this.errorStatus = data[0]?.istatus;
                  this.errorClass = this.errorStatus + "Style";
                  this.showErrorPopup = true;
                }
              }
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "Please select any ware from table",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          } else {
            this.submitObj.UserId = parseInt(this.userId);
            this.submitObj.controlID = parseInt(this.selectedScope.control_id);
            this.submitObj.session_id = parseInt(this.sessionData[0].session_id);
            this.submitObj.pass = 1;
            this.submitObj.bin = this.binVal;
            this.submitObj.lpn = this.newLPN;
            this.submitObj.unit = this.serialNo;
            this.submitObj.qty = parseInt(this.quantity);
            this.submitObj.ware_key = 0;
            this.submitObj.loc = "";
            let showMsg = false;
            let data = await inventoryService.postScanUnitsSubmit("post", this.submitObj, showMsg);
            if (data.message !== "NA") {
              console.log("scanUnitsSubmit", data);
              if (data[0]?.istatus == "SUBMIT") {
                this.scanUnitsSubmitPass2();
              } else if (data[0]?.istatus == "EXTRA") {
                this.errorClass = "";
                this.errorTitle = data[0]?.title;
                this.errorMessage = data[0]?.imessage;
                this.errorStatus = data[0]?.istatus;
                this.errorClass = this.errorStatus + "Style";
                this.showErrorPopup = true;
              }
            }
          }
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Quantity is required",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Scan Units Submit with pass 2
    async scanUnitsSubmitPass2() {
      if (this.selectedWare !== "" && this.selectedWare !== undefined) {
        this.submitObj.UserId = parseInt(this.userId);
        this.submitObj.controlID = parseInt(this.selectedScope.control_id);
        this.submitObj.session_id = parseInt(this.sessionData[0].session_id);
        this.submitObj.pass = 2;
        this.submitObj.bin = this.binVal;
        this.submitObj.lpn = this.newLPN;
        this.submitObj.unit = this.serialNo;
        this.submitObj.qty = parseInt(this.quantity);
        this.submitObj.ware_key = parseInt(this.selectedWare[0]?.ware);
        this.submitObj.loc = this.selectedWare[0]?.loc;
      } else {
        this.submitObj.UserId = parseInt(this.userId);
        this.submitObj.controlID = parseInt(this.selectedScope.control_id);
        this.submitObj.session_id = parseInt(this.sessionData[0].session_id);
        this.submitObj.pass = 2;
        this.submitObj.bin = this.binVal;
        this.submitObj.lpn = this.newLPN;
        this.submitObj.unit = this.serialNo;
        this.submitObj.qty = parseInt(this.quantity);
        this.submitObj.ware_key = 0;
        this.submitObj.loc = "";
      }
      let showMsg = true;
      let data = await inventoryService.postScanUnitsSubmit("post", this.submitObj, showMsg);
      if (data.message !== "NA") {
        this.count = 0;
        this.selectedWare = [];
        this.disableSubmit = true;
        this.reloadBinInfo();
      }
    },
    //Click OK on error popup
    clickOKError() {
      this.errorClass = "";
      this.showErrorPopup = false;
      this.serialNo = "";
      this.$refs.scanUnitForm.resetValidation();
    },
    //Unlock Check
    unlockCheck() {
      if (this.sessionData[0].unlock == this.unlockVal) {
        this.showErrorPopup = false;
        this.errorClass = "";
        this.serialNo = "";
        this.unlockVal = "";
        this.disableSerialNo = false;
        this.disableSubmit = true;
        this.quantity = "";
        this.reloadBinInfo();
      } else {
        this.unlockVal = "";
        let Alert = {
          type: "error",
          isShow: true,
          message: "Password entered is incorrect",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //File Name
    fileName() {
      this.excelName =
        "Units_Move_Quarantine_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    //to check if its a number with hyphen of not
    lpnNumFormat(event, num) {
      this.newLPN = Utility.formatLPNNumber(event, num);
    },
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList !== null && this.actionList?.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
  },
};
