import { render, staticRenderFns } from "./ooba-rate-settings.vue?vue&type=template&id=563255bb&scoped=true&"
import script from "./js/ooba-rate-settings.js?vue&type=script&lang=js&"
export * from "./js/ooba-rate-settings.js?vue&type=script&lang=js&"
import style0 from "./ooba-rate-settings.vue?vue&type=style&index=0&id=563255bb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563255bb",
  null
  
)

export default component.exports