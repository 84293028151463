import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import exportLimit from "../../common/export-limit.vue";
import moment from "moment";
import reportService from "./reports-service";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export default {
  name: "salesOrderShipment",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      specificSelected: "",
      showData: false,
      exportDialog: false,
      export50kData: false,
      hideSpecific: false,
      showGo: true,
      showTable: false,
      disableDate: false,
      showPart: false,
      partNum: "",
      partDesc: "",
      mainPage: "",
      excelName: "",
      page: 1,
      displayCost: "",
      totalPages: 0,
      subPage: "",
      restForm: false,
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      sDate: "",
      eDate: "",
      selectedProject: "",
      selectedBusiness: "",
      selectedWarehouse: "",
      selectedClass: "",
      selectedOem: "",
      selectedSODetails: "",
      partSearchKey: "",
      partResultList: [],
      debouncedGetPartList: [],
      showPartNumDetails: false,
      businessList: [],
      detailsData: [],
      warehouseList: [],
      classList: [],
      uniqueClassList: [],
      uniqueOEM: [],
      oemList: [],
      soList: [],
      uniqueSODetails: [],
      reportData: [],
      startDate: false,
      showWarehouse: false,
      showClass: false,
      showOem: false,
      soType: false,
      showSearch: false,
      endDate: false,
      showAllForm: false,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      projectList: [],
      totalRecords: 0,
      showStartDate: true,
      showEndDate: true,
      filterObj: {
        user_id: 0,
        pass: 0,
        lcsdate: "",
        lcedate: "",
        proj_id: 0,
        part_num: "",
        bu_id: 0,
        class_id: 0,
        ware_id: 0,
        oem_id: 0,
        sotype_id: 0,
        showcost: false,
        RecordCount: 0,
        PageNumber: 1,
        export: 0,
        guid: "",
      },
      headerReport: [
        { text: "Project", align: "start", value: "Project", class: "primary customwhite--text" },
        { text: "Business", value: "Business", class: "primary customwhite--text" },
        { text: "SO No", value: "SO_Number", class: "primary customwhite--text" },
        { text: "Line No", value: "Line_No", class: "primary customwhite--text" },
        { text: "RMA", value: "RMA", class: "primary customwhite--text" },
        { text: "Order No", value: "Order_No", class: "primary customwhite--text" },
        { text: "Item", value: "Item", class: "primary customwhite--text" },
        { text: "OEM Part", value: "OEM_Part", class: "primary customwhite--text" },
        { text: "Description", value: "Description", class: "primary customwhite--text" },
        { text: "Cost", value: "Cost", class: "primary customwhite--text" },
        { text: "Qty Ord", value: "Qty_Ord", class: "primary customwhite--text" },
        { text: "Qty Ship", value: "Qty_Ship", class: "primary customwhite--text" },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        { text: "Serial No", value: "Serial_No", class: "primary customwhite--text" },
        { text: "Date Ship", value: "Date_Ship", class: "primary customwhite--text" },
        { text: "Order Type", value: "Order_Type", class: "primary customwhite--text" },
        { text: "Warehouse", value: "Warehouse", class: "primary customwhite--text" },
        { text: "Location", value: "Location", class: "primary customwhite--text" },
        { text: "Emp ID", value: "Emp ID", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.filterObj.user_id = this.userId;
    this.debouncedGetPartList = Utility.debounce(this.getPartList, 500);
  },
  mounted() {},
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //To export the Excel file
    async exportToExcel() {
      if (this.totalRecords <= 15000) {
        this.startDownload();
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sales Order Shipments");
        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
        const colors = {
          grey: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        //Add Header Rows
        worksheet.addRow([
          "Project",
          "Business",
          "SO Number",
          "Line Number",
          "Customer PO",
          "RMA",
          "Order Number",
          "Original SO",
          "Original RMA",
          "VRMA",
          "Customer ID",
          "Customer Name",
          "Address 1",
          "Address 2",
          "City",
          "State",
          "Zip Code",
          "Country",
          "ATTN",
          "Item",
          "OEM Part",
          "Description",
          "Model",
          "Class",
          "OEM",
          "Cost",
          "Qty Ord",
          "Qty Ship",
          "BCN",
          "Serial Number",
          "ESN",
          "License Plate",
          "Auth Date",
          "Date Ship",
          "Carrier",
          "Frt bill",
          "Ret bill",
          "RMA Type",
          "Customer SO Type",
          "Order Type",
          "Warehouse",
          "Location",
          "Employee ID",
        ]);
        // Style configuration
        const headerStyle = {
          font: { bold: true },
          alignment: { horizontal: "center", vertical: "middle" },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          fill: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        // Apply styles to the header rows
        worksheet.getRow(1).eachCell((cell) => {
          cell.style = { ...headerStyle, fill: colors.grey };
          cell.border = borderStyle;
        });
        this.filterObj.export = 1;
        const response = await this.axios.post("/rt/sales_order_shipments_new_export", this.filterObj);
        let responseData = response.data.body;
        responseData?.forEach((element) => {
          element.Date_Ship = Utility.convertESTToLocal(element.Date_Ship);
          element.Auth_Date = Utility.convertESTToLocal(element.Auth_Date);
        });
        this.export50kData = false;
        //Add your data rows
        responseData.forEach((item) => {
          const row = worksheet.addRow([
            item.Project,
            item.Business,
            item.SO_Number,
            item.Line_No,
            item.Cust_Po,
            item.RMA,
            item.Order_No,
            item.Original_SO,
            item.Original_RMA,
            item.VRMA,
            item.Cust_ID,
            item.Customer_Name,
            item.Addr1,
            item.Addr2,
            item.City,
            item.State,
            item.Zip_Code,
            item.Country,
            item.ATTN,
            item.Item,
            item.OEM_Part,
            item.Description,
            item.Model,
            item.Class,
            item.OEM,
            item.Cost,
            item.Qty_Ord,
            item.Qty_Ship,
            item.BCN,
            item.Serial_No,
            item.ESN,
            item.License_Plate,
            item.Auth_Date,
            item.Date_Ship,
            item.Carrier,
            item.Frt_bill,
            item.Ret_bill,
            item.RMA_Type,
            item.Cust_SO_Type,
            item.Order_Type,
            item.Warehouse,
            item.Location,
            item.Emp_ID,
          ]);
          row.eachCell({ includeEmpty: true }, (cell) => {
            //Apply borders
            cell.border = borderStyle;
          });
        });
        //Download the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), Utility.excelFileName("Sales_Order_Shipments"));
        this.finishDownload();
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //File Name for Export

    // on CLick COntinue
    async onClickContinue() {
      this.filterObj.pass = 0;
      !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
      this.filterObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.filterObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      let projectData = await reportService.soShipmentFilter("post", this.filterObj, false);
      if (projectData?.length > 0) {
        this.projectList = projectData;
        this.restForm = true;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Data Available change the Start Date",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.restForm = false;
      }
    },
    //on Change Project
    onChangeProject() {
      this.filterObj.proj_id = this.selectedProject;
      this.filterObj.pass = 1;
      this.showAllForm = false;
      this.showGo = false;
      this.showPart = true;
      this.disableDate = true;
      this.getBusinessData();
    },
    async getBusinessData() {
      let businessData = await reportService.soShipmentFilter("post", this.filterObj, false);
      this.businessList = businessData;
    },
    async onChangeBusiness() {
      this.hideSpecific = true;
      this.filterObj.pass = 2;
      this.filterObj.bu_id = this.selectedBusiness;
      this.detailsData = await reportService.soShipmentFilter("post", this.filterObj, false);
      const unquieWarehouse = [...new Map(this.detailsData.map((item) => [item["ware"], item])).values()];
      this.warehouseList = unquieWarehouse.map((x) => ({
        ware: x.ware,
        ware_id: x.ware_key,
      }));
      this.showWarehouse = true;
      this.showClass = false;
      this.showOEM = false;
      this.soType = false;
      this.displayCost = false;
      this.showSearch = false;
      this.oemList = [];
      this.classList = [];
      this.oemList = [];
      this.soList = [];
      this.showCost = false;
      this.selectedWarehouse = "";
      this.selectedBusinessOem = "";
      this.selectedSODetails = "";
    },
    onChangeWarehouse(ware_id) {
      this.filterObj.war_id = ware_id;
      this.uniqueClassList = this.detailsData.filter((x) => x.ware_key == ware_id);
      const uniqueClass = [...new Map(this.uniqueClassList.map((item) => [item["class"], item])).values()];
      this.classList = uniqueClass.map((x) => ({
        class: x.class,
        class_id: x.class_key,
      }));
      this.showClass = true;
      this.showData = false;
      this.showOEM = false;
      this.soType = false;
      this.displayCost = false;
      this.showSearch = false;
      this.oemList = [];
      this.soList = [];

      this.showCost = false;
      this.selectedSODetails = "";
      this.selectedClass = "";
      this.selectedOem = "";
    },
    onChangeClass(class_id) {
      this.filterObj.class_id = class_id;
      this.uniqueOEM = this.uniqueClassList.filter((x) => x.class_key == class_id);
      const uniqueClass = [...new Map(this.uniqueOEM.map((item) => [item["oem"], item])).values()];
      this.oemList = uniqueClass.map((x) => ({
        oem: x.oem,
        oem_id: x.oem_key,
      }));

      this.showOEM = true;
      this.soType = false;
      this.displayCost = false;
      this.showSearch = false;
      this.soList = [];
      this.showCost = false;
      this.showData = false;
      this.showSearch = false;
      this.selectedSODetails = "";
      this.selectedOem = "";
    },
    onChangeOEM(oem_id) {
      this.filterObj.oem_id = oem_id;
      this.uniqueSODetails = this.uniqueClassList.filter((x) => x.oem_key == oem_id);
      const uniqueSoDesc = [...new Map(this.uniqueSODetails.map((item) => [item["so_desc"], item])).values()];
      this.soList = uniqueSoDesc.map((x) => ({
        so_desc: x.so_desc,
        so_type_id: x.sotype_key,
      }));
      this.soType = true;
      this.displayCost = true;
      this.showSearch = false;
      this.showData = false;
      this.showCost = false;
      this.selectedSODetails = "";
      this.selectedSODetails = "";
    },

    async onClickSearch(PageNumber) {
      this.filterObj.pass = 3;
      this.filterObj.PageNumber = PageNumber;
      this.filterObj.bu_id = this.selectedBusiness;
      this.filterObj.class_id = this.selectedClass;
      this.filterObj.ware_id = this.selectedWarehouse;
      this.filterObj.oem_id = this.selectedOem;
      this.filterObj.sotype_id = this.selectedSODetails;
      this.filterObj.part_num = this.partSearchKey;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .post("/rt/sales_order_shipments_new", this.filterObj)
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body;
            this.totalRecords = responseData[0]?.TotalRecords;
            this.totalPages = responseData[0]?.TotalPages;
            this.reportData = responseData;
            if (this.reportData?.length > 0) {
              this.showData = true;
              this.reportData?.forEach((element) => {
                element.Date_Ship = Utility.convertESTToLocal(element.Date_Ship);
              });
            } else {
              this.reportData = [];
              let Alert = {
                type: "error",
                isShow: true,
                message: "No Record Found",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            this.$vuetify.goTo(0);
            this.showTable = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.selectedProject = "";
      this.disableDate = false;
      (this.partNum = ""), (this.showData = false);
      this.showGo = true;
      (this.partDesc = ""), (this.selectedBusiness = "");
      this.partSearchKey = "";
      this.restForm = false;
      this.showPart = false;
      this.showWarehouse = false;
      this.specificSelected = false;
      this.showClass = false;
      this.showOEM = false;
      this.soType = false;
      this.displayCost = false;
      this.showAllForm = false;
      this.showSearch = false;
      this.businessList = [];
      this.projectList = [];
      this.warehouseList = [];
      this.oemList = [];
      this.classList = [];
      this.oemList = [];
      this.soList = [];
      this.showTable = false;
      this.showCost = false;
      this.selectedWarehouse = "";
      this.selectedBusinessOem = "";
      this.selectedSODetails = "";
      this.hideSpecific = false;
      this.reportData = [];
      this.totalRecords = 0;
      this.totalPages = 1;
      this.filterObj = {
        user_id: this.userId,
        pass: 1,
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: 0,
        part_num: "",
        bu_id: 0,
        class_id: 0,
        ware_id: 0,
        oem_id: 0,
        sotype_id: 0,
        showcost: false,
        RecordCount: 0,
        PageNumber: 1,
        export: 0,
        guid: "",
      };
    },
    onClickSpecificPartNumber() {
      this.specificSelected = true;
    },
    async searchPartNum() {
      this.partSearchKey = this.partSearchKey?.toUpperCase();
      if (this.partSearchKey.length > 2) {
        this.debouncedGetPartList();
      }
    },
    async getPartList() {
      this.showPartNumDetails = true;
      let SearchObj = {
        UserId: this.userId,
        SearchStr: this.partSearchKey,
        proj_key: this.selectedProject,
      };
      let partResultData = await reportService.showPartNumDetailsData("post", SearchObj, false);
      this.partResultList = partResultData?.Resultset;
    },

    async showPartDesc(item) {
      this.showPartDetails = true;
      this.partNum = item.PartNo;
      this.partDesc = item.Description;
      this.partSearchKey = item.PartNo;
      this.showPartNumDetails = false;
      this.filterObj.part_num = this.partNum;
      let dataExists = await reportService.soShipmentFilter("post", this.filterObj, false);
      if (!dataExists) {
        this.showAllForm = false;
      } else {
        this.showAllForm = true;
      }
    },

    clearPartShip() {
      this.showPartDetails = false;
      this.partSearchKey = "";
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
