import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "./encrypt-utility";
import AddFavourite from "./addFav";
import Utility from "./utility-api-code-manager";
import BackToTop from "../../common/back-to-top.vue";
import Tier2Comp from "../code-manager-tier2-comp.vue";
export default {
  data: () => ({
    mainPage: "",
    subPage: "",
    pageUrl: "",
    showTier2Dialog: false,
    symptomCodeEdit:false,
    editObservedDamageCode:false,
    changeStatusTemp: [],
    backSlash: true,
    favouriteList: [],
    tier1ParentFCEdit: "",
    tab: [1, 2, 3, 4, 5, 6,7],
    showAdd: false,
    refresh: true,
    userId: EncryptUtility.localStorageDecrypt("userID"),
    panel: [0, 1, 2, 3, 4, 5],
    selectedProject: "",
    projectData: [],
    selectedBusiness: "",
    businessData: [],
    projectSelected: false,
    pass: 0,
    businessSelected: false,
    routeLoc: [],
    failCode: [],
    failCodeDesc: [],
    locations: [],
    parentClass: [],
    tier1FailureCode: "",
    tier1FailureCodeEdit: false,
    observedDamage: false,
    popStatus: false,
    symptomCodeFlag:false,
    tier1FailureCodeRename: "",
    tier1FailureCodeItems: [],
    tier2FailureCodeItems: [],
    statusChangeDialog: false,
    popupTitlePart: "",
    tier2FailureCode: "",
    description: "",
    selectedtier1Code: "",
    selectedRoute: "",
    selectedParentClass: "",
    selectedSubClass: "",
    subClassItems: ["ALL"],
    TBE: false,
    BIT: false,
    ENG: false,
    FRM: false,
    REP: false,
    IRP: false,
    ALN: false,
    FTT: false,
    INS: false,
    OBA: false,
    forceOW: false,
    bypassFAE: false,
    partReq: false,
    cardHeader: "",
    tier1Details: false,
    showAddDialog: false,
    repairDetails: false,
    receivingDetails: false,
    observedDetails: false,
    repairCode: "",
    repairCodeDesc: "",
    symptomCodeItems: [],
    symptomCode: "",
    symptomCodeDesc: "",
    receivingConditionItems: [],
    receivingCondition: "",
    receivingConditionDesc: "",
    selectedRouteReceive: "",
    observedProbCodeItems: [],
    observedProbCode: "",
    observedProbDesc: "",
    selectedRouteObserve: "",
    ForceOW: false,
    observedDamageItems: [],
    observedDamageCode: "",
    observedDamageDesc: "",
    gradeReasonItems: [],
    gradeBReason: "",
    gradeBReasonDesc: "",
    MSRItems: [],
    MSRCode: "",
    MSRDesc: "",
    FAECode: "",
    FAEDesc: "",
    PSCItems: [],
    PSCCode: "",
    PSCDesc: "",
    strikeCode: "",
    strikeDesc: "",
    NTFRRItems: [],
    NTFRRCode: "",
    NTFRRDesc: "",
    changeStatusItem: [],
    symptomValid: false,
    search: "",
    tier1Valid: false,
    tier2Valid: false,
    repairValid: false,
    receivingCondValid: false,
    observedProbValid: false,
    observedDamValid: false,
    gradeReasValid: false,
    MSRValid: false,
    FAEValid: false,
    PSCValid: false,
    strikeValid: false,
    NTFRRValid: false,
    valid: false,
    editItem: [],
    tier2FailureCodeEdit: false,
    tier2FailureCodeRename: false,
    repairCodesEdit: false,
    repairCodesRename: "",
    repairCodesDesc: "",
    receivingCondEdit: false,
    receCondRename: "",
    receCondDesc: "",
    observedProbEdit: false,
    obsProbRename: "",
    observedDamageDescEdit:"",
    observedDamageCodeEdit:"",
    obsProbDesc: "",
    symptomCodeNameEdit:"",
    symptomCodeDescEdit:"",
    required: [(v) => (v !== null && v !== undefined) || "Field is required"],
    max100Rule: [
      (v) => !!v || "Field is required",
      (v) => (v || "").length <= 100 || "Max Length of 100 character",
    ],
    max10Rule: [
      (v) => !!v || "Field is required",
      (v) => (v || "").length <= 10 || "Max Length of 10 character",
    ],
    headersTier1FailureCodes: [
      {
        text: "Status",
        align: "start",
        value: "active",
        width: "25%",
        class: "primary customwhite--text",
      },
      {
        text: "Tier 1 Failure Code",
        value: "Description",
        width: "40%",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        width: "35%",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersTier2FailureCodes: [
      {
        text: "Status",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "Tier 2 Failure Code",
        value: "Code",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        class: "primary customwhite--text",
      },
      {
        text: "Tier 1 Code",
        value: "parent_fc",
        class: "primary customwhite--text",
      },
      {
        text: "Route",
        value: "Route",
        class: "primary customwhite--text",
      },
      {
        text: "Parent Class",
        value: "Class",
        class: "primary customwhite--text",
      },
      {
        text: "Sub-Class",
        value: "part_class",
        class: "primary customwhite--text",
      },
      {
        text: "TBE",
        value: "TBE",
        class: "primary customwhite--text",
      },
      {
        text: "BIT",
        value: "T02",
        class: "primary customwhite--text",
      },
      {
        text: "ENG",
        value: "A03",
        class: "primary customwhite--text",
      },
      {
        text: "FRM",
        value: "R06",
        class: "primary customwhite--text",
      },
      {
        text: "REP",
        value: "R01",
        class: "primary customwhite--text",
      },
      {
        text: "IRP",
        value: "R05",
        class: "primary customwhite--text",
      },
      {
        text: "ALN",
        value: "R02",
        class: "primary customwhite--text",
      },
      {
        text: "FTT",
        value: "T03",
        class: "primary customwhite--text",
      },
      {
        text: "INS",
        value: "I01",
        class: "primary customwhite--text",
      },
      {
        text: "OBA",
        value: "A02",
        class: "primary customwhite--text",
      },
      {
        text: "Force OW",
        value: "iw_to_ow",
        class: "primary customwhite--text",
      },
      {
        text: "Bypass FAE",
        value: "fae_bypass",
        class: "primary customwhite--text",
      },
      {
        text: "Part Request",
        value: "use4issue",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersRepairCodes: [
      {
        text: "Status",
        align: "start",
        value: "active",
        width: "17%",
        class: "primary customwhite--text",
      },
      {
        text: "Repair Code",
        value: "Code",
        width: "33%",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        width: "27%",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        width: "23%",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersSymptomCodes: [
      {
        text: "Status",
        align: "start",
        value: "active",
        width: "10%",
        class: "primary customwhite--text",
      },
      {
        text: "Symptom Code",
        value: "Code",
        width: "20%",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        width: "20%",
        class: "primary customwhite--text",
      },
      {
        text: "TBE",
        value: "TBE",
        width: "5%",
        class: "primary customwhite--text",
      },
      {
        text: "BIT",
        value: "T02",
        class: "primary customwhite--text",
      },
      {
        text: "ENG",
        value: "A03",
        class: "primary customwhite--text",
      },
      {
        text: "REP",
        value: "R01",
        class: "primary customwhite--text",
      },
      {
        text: "IRP",
        value: "R05",
        class: "primary customwhite--text",
      },
      {
        text: "ALN",
        value: "R02",
        class: "primary customwhite--text",
      },
      {
        text: "FTT",
        value: "T03",
        class: "primary customwhite--text",
      },
      {
        text: "INS",
        value: "I01",
        class: "primary customwhite--text",
      },
      {
        text: "OBA",
        value: "A02",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        width: "5%",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersReceivingCondition: [
      {
        text: "Status",
        align: "start",
        value: "active",
        width: "10%",
        class: "primary customwhite--text",
      },
      {
        text: "Receiving Condition",
        value: "Code",
        width: "25%",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        width: "25%",
        class: "primary customwhite--text",
      },
      {
        text: "Route",
        value: "Route",
        width: "15%",
        class: "primary customwhite--text",
      },
      {
        text: "Force OW",
        value: "iw_to_ow",
        width: "15%",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        width: "10%",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersObservedProbCode: [
      {
        text: "Status",
        align: "start",
        value: "active",
        width: "10%",
        class: "primary customwhite--text",
      },
      {
        text: "Observed Problem Code",
        value: "Code",
        width: "25%",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        width: "25%",
        class: "primary customwhite--text",
      },
      {
        text: "Route",
        value: "Route",
        width: "15%",
        class: "primary customwhite--text",
      },
      {
        text: "Force OW",
        value: "iw_to_ow",
        width: "15%",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        width: "10%",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersObservedDamage: [
      {
        text: "Status",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "Observed Damage Code",
        value: "Code",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersGradeReason: [
      {
        text: "Status",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "Grade B Reason",
        value: "Code",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersMSR: [
      {
        text: "Status",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "MSR Code",
        value: "Code",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        class: "primary customwhite--text",
      },
      {
        text: "Route",
        value: "Route",
        class: "primary customwhite--text",
      },
      {
        text: "Force OW",
        value: "iw_to_ow",
        class: "primary customwhite--text",
      },
      {
        text: "Bypass FAE",
        value: "BypassFAE",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersFAE: [
      {
        text: "Status",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "FAE Code",
        value: "Code",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        class: "primary customwhite--text",
      },
      {
        text: "Route",
        value: "Route",
        class: "primary customwhite--text",
      },
      {
        text: "Force OW",
        value: "iw_to_ow",
        class: "primary customwhite--text",
      },
      {
        text: "Bypass FAE",
        value: "BypassFAE",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersPSC: [
      {
        text: "Status",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "PSC Code",
        value: "Code",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        class: "primary customwhite--text",
      },
      {
        text: "Route",
        value: "Route",
        class: "primary customwhite--text",
      },
      {
        text: "Force OW",
        value: "iw_to_ow",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersStrike: [
      {
        text: "Status",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "Strike Code",
        value: "Code",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        class: "primary customwhite--text",
      },
      {
        text: "Route",
        value: "Route",
        class: "primary customwhite--text",
      },
      {
        text: "Force OW",
        value: "iw_to_ow",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    headersNTFRR: [
      {
        text: "Status",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "NTF-RR Code",
        value: "Code",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "Description",
        class: "primary customwhite--text",
      },
      {
        text: "Route",
        value: "Route",
        class: "primary customwhite--text",
      },
      {
        text: "Force OW",
        value: "iw_to_ow",
        class: "primary customwhite--text",
      },
      {
        text: "Edit",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getProjectList();
  },
  methods: {
    //On Ckick of T2 FC button
    onClickT2FailCode() {
      this.showTier2Dialog = true;
    },
    // to set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList?.filter(
          (entry) => entry.SystemName === action
        );
        return filter !== undefined && filter !== null && filter?.length == 1
          ? true
          : false;
      } else return false;
    },
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.getProjectList();
      this.selectedProject = "";
      this.projectData = [];
      this.selectedBusiness = "";
      this.businessData = [];
      this.projectSelected = false;
      this.pass = 0;
      this.businessSelected = false;
      this.routeLoc = [];
      this.failCode = [];
      this.failCodeDesc = [];
      this.locations = [];
      this.parentClass = [];
      this.tier1FailureCode = "";
      this.tier1FailureCodeEdit = false;
      this.popStatus = false;
      this.tier1FailureCodeRename = "";
      this.tier1FailureCodeItems = [];
      this.tier2FailureCodeItems = [];
      this.statusChangeDialog = false;
      this.popupTitlePart = "";
      this.tier2FailureCode = "";
      this.description = "";
      this.selectedtier1Code = "";
      this.selectedRoute = "";
      this.selectedParentClass = "";
      this.selectedSubClass = "";
      this.subClassItems = [];
      this.TBE = false;
      this.BIT = false;
      this.ENG = false;
      this.FRM = false;
      this.REP = false;
      this.IRP = false;
      this.ALN = false;
      this.FTT = false;
      this.INS = false;
      this.OBA = false;
      this.forceOW = false;
      this.bypassFAE = false;
      this.partReq = false;
      this.repairCode = "";
      this.repairCodeDesc = "";
      this.symptomCodeItems = [];
      this.symptomCode = "";
      this.symptomCodeDesc = "";
      this.receivingConditionItems = [];
      this.receivingCondition = "";
      this.receivingConditionDesc = "";
      this.selectedRouteReceive = "";
      this.observedProbCodeItems = [];
      this.observedProbCode = "";
      this.observedProbDesc = "";
      this.selectedRouteObserve = "";
      this.ForceOW = false;
      this.observedDamageItems = [];
      this.observedDamageCode = "";
      this.observedDamageDesc = "";
      this.gradeReasonItems = [];
      this.gradeBReason = "";
      this.gradeBReasonDesc = "";
      this.MSRItems = [];
      this.MSRCode = "";
      this.MSRDesc = "";
      this.FAECode = "";
      this.FAEDesc = "";
      this.PSCItems = [];
      this.PSCCode = "";
      this.PSCDesc = "";
      this.strikeItems = [];
      this.strikeCode = "";
      this.strikeDesc = "";
      this.NTFRRItems = [];
      this.NTFRRCode = "";
      this.NTFRRDesc = "";
      this.changeStatusItem = [];
      this.symptomValid = false;
      this.tier1Valid = false;
      this.tier2Valid = false;
      this.repairValid = false;
      this.receivingCondValid = false;
      this.observedProbValid = false;
      this.observedDamValid = false;
      this.gradeReasValid = false;
      this.MSRValid = false;
      this.FAEValid = false;
      this.PSCValid = false;
      this.strikeValid = false;
      this.NTFRRValid = false;
      this.editItem = [];
      this.valid = false;
      this.tier2FailureCodeEdit = false;
      this.tier2FailureCodeRename = false;
      this.repairCodesEdit = false;
      this.repairCodesDesc = "";
      this.repairCodesRename = "";
      this.receivingCondEdit = false;
      this.receCondDesc = "";
      this.receCondRename = "";
      this.observedProbEdit = false;
      this.obsProbRename = "";
      this.obsProbDesc = "";
      this.$refs.CodeManagerForm?.resetValidation();
      this.$refs.Tier1AddForm?.resetValidation();
      this.$refs.Tier2AddForm?.resetValidation();
      this.$refs.RepairAddForm?.resetValidation();
      this.$refs.SymptomAddForm?.resetValidation();
      this.$refs.ReceivingCondAddForm?.resetValidation();
      this.$refs.GradeReasAddForm?.resetValidation();
      this.$refs.ObservedProbAddForm?.resetValidation();
      this.$refs.ObservedDamAddForm?.resetValidation();
      this.$refs.MSRAddForm?.resetValidation();
      this.$refs.FAEAddForm?.resetValidation();
      this.$refs.PSCAddForm?.resetValidation();
      this.$refs.StrikeAddForm?.resetValidation();
      this.$refs.NTFRRAddForm?.resetValidation();
      this.$refs.Tier1EditForm?.resetValidation();
    },
    // Get Project List
    async getProjectList() {
      this.pass = 1;
      let response = await Utility.getProjectList(this.pass, this.userId);
      if (response !== null && response !== undefined)
        this.projectData = response.ProjectInfo;
    },
    // Get Business List
    async getBusinessList() {
      let obj = {
        UserId: parseInt(this.userId),
        ProjKey: parseInt(this.selectedProject),
        BuKey: 0,
        IncludeEmptyBU: 0,
        WithinSameLocation: 0, // Do not Delete
        loca_key: 0,
      };
      let showMessage = false;
      let response = await Utility.getBusinessList(obj, showMessage);
      if (response !== null && response !== undefined) {
        this.projectSelected = true;
        this.businessData = response;
      }
    },
    // Get page data
    async onBusinessSelect() {
      this.pass = 2;
      this.routeLoc = [];
      this.failCode = [];
      this.failCodeDesc = [];
      this.locations = [];
      this.parentClass = [];
      let obj = {
        pass: parseInt(this.pass),
        UserId: parseInt(this.userId),
        proj_key: parseInt(this.selectedProject),
        bu_key: parseInt(this.selectedBusiness),
      };
      let showMessage = false;
      let response = await Utility.onBusinessSelect(obj, showMessage);
      if (response !== null && response !== undefined) {
        this.businessSelected = true;
        this.routeLoc = response.DisplayString;
        this.failCode = response.FailCode;
        this.failCodeDesc = response.FailcodeDesc;
        this.locations = response.Locations;
        this.parentClass = response.ParentClass;
          // Define a map of item arrays
          const typeMap = {
            "Tier 1 Failure Code": "tier1FailureCodeItems",
            "Failure Code": "tier2FailureCodeItems",
            "Repair": "repairCodesItems",
            "Symptom": "symptomCodeItems",
            "Observed Damage (Repair)": "observedDamageItems",
            "Grade B Reason": "gradeReasonItems",
            "Receiving Condition": "receivingConditionItems",
            "Observed Problem": "observedProbCodeItems",
            "NTF-RR": "NTFRRItems",
            "Strike": "strikeItems",
            "PSC": "PSCItems",
            "FAE": "FAEItems",
            "MSR": "MSRItems",
          }      
          // Initialize all the arrays dynamically
          for (const key in typeMap) {
            this[typeMap[key]] = [];
          }
        
          // Iterate through the failCode list and assign items to the corresponding array
          this.failCode.forEach((obj) => {
            const arrayName = typeMap[obj.Type];
            if (arrayName) {
              this[arrayName].push(obj);
            }
          });
        }
        
    },

    onClickAddAddBtn(flag) {
      this.showAddDialog = true;
      const flagSettings = {
        tier1: {
          cardHeader: "Add Tier 1 Failure Code",
          tier1Details: true,
          receivingDetails: false,
          repairDetails: false,
          observedDetails: false,
          observedDamage: false,
          symptomCodeFlag:false
        },
        RepairCode: {
          cardHeader: "Add Repair Code",
          tier1Details: false,
          receivingDetails: false,
          repairDetails: true,
          observedDetails: false,
          observedDamage: false,
          symptomCodeFlag:false
        },
        Receiving: {
          cardHeader: "Add Receiving Condition",
          tier1Details: false,
          receivingDetails: true,
          repairDetails: false,
          observedDetails: false,
          observedDamage: false,
          symptomCodeFlag:false
        },
        Observed: {
          cardHeader: "Add Observed Problem",
          tier1Details: false,
          receivingDetails: false,
          repairDetails: false,
          observedDetails: true,
          observedDamage: false,
          symptomCodeFlag:false
        },
        Damage: {
          cardHeader: "Add Observed Damage (Repair)",
          tier1Details: false,
          receivingDetails: false,
          repairDetails: false,
          observedDetails: false,
          observedDamage: true,
          symptomCodeFlag:false
        },
        symptomCodeFlag:{
          cardHeader: "Add Symptom Code",
          tier1Details: false,
          receivingDetails: false,
          repairDetails: false,
          observedDetails: false,
          observedDamage: false,
          symptomCodeFlag:true
        }
      };

      const settings = flagSettings[flag];
      if (settings) {
        this.cardHeader = settings.cardHeader;
        this.tier1Details = settings.tier1Details;
        this.receivingDetails = settings.receivingDetails;
        this.repairDetails = settings.repairDetails;
        this.observedDetails = settings.observedDetails;
        this.observedDamage = settings.observedDamage;
        this.symptomCodeFlag = settings.symptomCodeFlag;
      }
    },

    closeAddDialog() {
      this.showAddDialog = false;
      this.repairDetails = false;
      this.observedDetails = false;
      this.tier1Details = false;
      this.receivingDetails = false;
      this.cardHeader = "";
      this.observedDamageCode="";
      this.observedDamageDesc="";
      this.tier1FailureCode = "";
      this.repairCode = "";
      this.repairCodeDesc = "";
      this.receivingCondition = "";
      this.receivingConditionDesc = "";
      this.selectedRouteReceive = "";
      this.observedProbCode = "";
      this.observedProbDesc = "";
      this.selectedRouteObserve = "";
      this.ForceOW = false;
    },
    //Parent Class change in Tier 2 Items
    async parentClassChange(item) {
      this.pass = 6;
      let obj = {
        pass: parseInt(this.pass),
        UserId: parseInt(this.userId),
        bu_key: parseInt(this.selectedBusiness),
        parent_class_key: parseInt(item),
      };
      let showMessage = false;
      let response = await Utility.onBusinessSelect(obj, showMessage);
      if (response !== null && response !== undefined) {
        this.subClassItems = response.PartClass;
      }
    },
    //Status Change of Elements
    //Change Tier 1 Failure Code Parent Status
    changeTier1FCStatus(item) {
      this.changeStatusItem = [];
      this.statusChangeDialog = true;
      this.popupTitlePart =
        "Do you want to change status of this Tier 1 Failure Code?";
      this.changeStatusTemp = item;
    },
    //Change Tier 2 Failure Code Status
    changeTier2FCStatus(item) {
      this.changeStatusItem = [];
      this.changeStatusTemp = [];
      this.changeStatusItem = item;
      this.statusChangeDialog = true;
      this.popupTitlePart =
        "Do you want to change status of this Tier 2 Failure Code?";
      this.changeStatusTemp = item;
    },
    //Change Repair Code Status
    changeRepairCodeStatus(item) {
      this.changeStatusItem = [];
      this.statusChangeDialog = true;
      this.popupTitlePart = "Do you want to change status of this Repair Code?";
      this.changeStatusTemp = item;
    },
    //Change status of Symptom Code
    changeSymptomCodeStatus(item) {
      this.changeStatusItem = [];
      this.changeStatusTemp = item;
      this.statusChangeDialog = true;
      this.popupTitlePart =
        "Do you want to change status of this Symptom Code?";
    },
    //Change status of Receiving Condition
    changeReceivingConditionStatus(item) {
      this.changeStatusItem = [];
      this.statusChangeDialog = true;
      this.popupTitlePart =
        "Do you want to change status of this Receiving Condition?";
      this.changeStatusTemp = item;
    },
    //Change status of Observed problem
    changeObservedProbCodeStatus(item) {
      this.changeStatusItem = [];
      this.popupTitlePart =
        "Do you want to change status of this Observed Problem?";
      this.statusChangeDialog = true;
      this.changeStatusTemp = item;
    },
    //Change Observed Damage (Repair) status
    changeObservedDamageStatus(item) {
      this.changeStatusItem = [];
      this.statusChangeDialog = true;
      this.popupTitlePart =
        "Do you want to change status of this Observed Damage (Repair)?";
      this.changeStatusTemp = item;
    },
    //Change Grade Reason status
    changeGradeReasonStatus(item) {
      this.changeStatusItem = [];
      this.statusChangeDialog = true;
      this.popupTitlePart =
        "Do you want to change status of this Grade Reason?";
      this.changeStatusTemp = item;
    },
    //Change MSR status
    changeMSRStatus(item) {
      this.changeStatusItem = [];
      this.statusChangeDialog = true;
      this.popupTitlePart = "Do you want to change status of this MSR?";
      this.changeStatusTemp = item;
    },
    //Change FAE status
    changeFAEStatus(item) {
      this.changeStatusItem = [];
      this.statusChangeDialog = true;
      this.popupTitlePart = "Do you want to change status of this FAE?";
      this.changeStatusTemp = item;
    },
    //Change status of PSC
    changePSCStatus(item) {
      this.changeStatusItem = [];
      this.statusChangeDialog = true;
      this.popupTitlePart = "Do you want to change status of this PSC?";
      this.changeStatusTemp = item;
    },
    //Change Strike status
    changeStrikeStatus(item) {
      this.changeStatusItem = [];
      this.statusChangeDialog = true;
      this.popupTitlePart = "Do you want to change status of this Strike?";
      this.changeStatusTemp = item;
    },
    //Change of NTF-RR status
    changeNTFRRStatus(item) {
      this.changeStatusItem = [];
      this.statusChangeDialog = true;
      this.popupTitlePart = "Do you want to change status of this NTF-RR?";
      this.changeStatusTemp = item;
    },
    //Change Status of Items
    async changeStatus() {
      this.changeStatusItem = this.changeStatusTemp;

      this.pass = 3;
      let obj = {
        pass: parseInt(this.pass),
        UserId: parseInt(this.userId),
        failc_id: parseInt(this.changeStatusItem["failc_id"]),
        type: this.changeStatusItem["Type"],
      };
      let showMessage = true;
      let response = await Utility.statusChangeofItem(obj, showMessage);
      if (response !== null && response !== undefined) {
        this.statusChangeDialog = false;
        this.onBusinessSelect();
      }
    },
    //End of Change Status of elements

    //Edit functionality of elements
    //Edit Tier 1 Failure Code Parent Status
    editTier1FailureCode(item) {
      this.editItem = [];
      this.tier1FailureCodeEdit = true;
      this.editItem = item;
      this.popStatus = item.active;
      this.tier1FailureCodeRename = item.Description;
    },
    //Edit Tier 1 Failure Code Parent Status
    editTier2FailureCode(item) {
      this.editItem = [];
      this.tier1FailureCodeEdit = false;
      this.tier1ParentFCEdit = item.parent_fc_key;
      this.tier2FailureCodeEdit = true;
      this.editItem = item;
      this.popStatus = item.active;
      this.tier2FailureCodeDesc = item.Description;
      this.tier2FailureCodeRename = item.Code;
      this.selectedRouteEdit = item.route_loc;
      this.selectedParentClassEdit = item.parent_class_key;
      this.parentClassChange(this.selectedParentClassEdit);
      this.selectedSubClassEdit = item.part_class_key;
      this.TBEEdit = item.TBE;
      this.BITEdit = item.T02;
      this.ENGEdit = item.A03;
      this.FRMEdit = item.R06;
      this.REPEdit = item.R01;
      this.IRPEdit = item.R05;
      this.ALNEdit = item.R02;
      this.FTTEdit = item.T03;
      this.INSEdit = item.I01;
      this.OBAEdit = item.A02;
      this.ForceOWEdit = item.iw_to_ow;
      this.bypassFAEEdit = item.fae_bypass;
      this.partRequestEdit = item.use4issue;
    },
    //Edit Repair Code Item
    editRepairCode(item) {
      this.editItem = [];
      this.editItem = item;
      this.repairCodesEdit = true;
      this.repairCodesDesc = item.Description;
      this.repairCodesRename = item.Code;
      this.popStatus = item.active;
    },
    //Edit Receiving Condition
    editReceivingCondition(item) {
      this.editItem = [];
      this.editItem = item;
      this.popStatus = item.active;
      this.receivingCondEdit = true;
      this.receCondDesc = item.Description;
      this.receCondRename = item.Code;
      this.receCondDesc = item.Description;
      this.receCondRename = item.Code;
      this.selectedRouteEdit = item.route_loc;
      this.ForceOWEdit = item.iw_to_ow;
    },
    //Edit Observed Problem code
    editObservedProbCode(item) {
      this.editItem = [];
      this.editItem = item;
      this.popStatus = item.active;
      this.observedProbEdit = true;
      this.obsProbDesc = item.Description;
      this.obsProbRename = item.Code;
      this.selectedRouteEdit = item.route_loc;
      this.ForceOWEdit = item.iw_to_ow;
    },
    editObservedDamage(item) {
      this.editItem = [];
      this.editItem = item;
      this.popStatus = item.active;
      this.observedDamageEdit = true;
      this.observedDamageDescEdit = item.Description;
      this.observedDamageCodeEdit = item.Code;
    },
    editSymptomCode(item){
      this.editItem = []; 
      this.editItem=item
      this.symptomCodeEdit=true;
      this.TBEEdit = item.TBE;
      this.BITEdit = item.T02;
      this.ENGEdit = item.A03;
      this.REPEdit = item.R01;
      this.IRPEdit = item.R05;
      this.ALNEdit = item.R02;
      this.FTTEdit = item.T03;
      this.INSEdit = item.I01;
      this.OBAEdit = item.A02;
      this.symptomCodeNameEdit=item.Code
      this.symptomCodeDescEdit=item.Description
    },
    //Submit of New tier 1 Edit
    async tier1FailureCodeChanged() {
      this.pass = 4;
      if (this.$refs.Tier1EditForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: parseInt(this.editItem["failc_id"]),
          type: this.editItem["attributetype"],
          code: this.tier1FailureCodeRename,
          desc: this.tier1FailureCodeRename,
          parent_fc_key: 0,
          route: "",
          parent_class_key: 0,
          part_class_key: 0,
          tbe: 0,
          t02: 0,
          a03: 0,
          r06: 0,
          r01: 0,
          r05: 0,
          r02: 0,
          i01: 0,
          a02: 0,
          active: 1,
          iw_to_ow: 0,
          fae_bypass: 0,
          use4issue: 0,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.resetEditDetails();
          this.$refs.Tier1EditForm?.resetValidation();
        }
      }
    },
    //Submit of New tier 1 Edit
    async tier2FailureCodeChanged() {
      this.pass = 4;
      if (this.$refs.Tier2EditForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: parseInt(this.editItem["failc_id"]),
          type: this.editItem["attributetype"],
          code: this.tier2FailureCodeRename,
          desc: this.tier2FailureCodeDesc,
          parent_fc_key: parseInt(this.tier1ParentFCEdit),
          route: this.selectedRouteEdit,
          parent_class_key: parseInt(this.selectedParentClassEdit),
          part_class_key: parseInt(this.selectedSubClassEdit),
          tbe: this.TBEEdit,
          t02: this.BITEdit,
          a03: this.ENGEdit,
          r06: this.FRMEdit,
          r01: this.REPEdit,
          r05: this.IRPEdit,
          r02: this.ALNEdit,
          t03: this.FTTEdit,
          i01: this.INSEdit,
          a02: this.OBAEdit,
          active: this.popStatus,
          iw_to_ow: this.ForceOWEdit,
          fae_bypass: this.bypassFAEEdit,
          use4issue: this.partRequestEdit,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.resetEditDetails();
          this.$refs.Tier2EditForm?.resetValidation();
        }
      }
    },
    //Submit of Repair Code Edit
    async repairCodeChanged() {
      this.pass = 4;
      if (this.$refs.RepairCodesEditForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: parseInt(this.editItem["failc_id"]),
          type: this.editItem["attributetype"],
          code: this.repairCodesRename,
          desc: this.repairCodesDesc,
          parent_fc_key: 0,
          route: "",
          parent_class_key: 0,
          part_class_key: 0,
          tbe: 0,
          t02: 0,
          a03: 0,
          r06: 0,
          r01: 0,
          r05: 0,
          r02: 0,
          i01: 0,
          a02: 0,
          active: 1,
          iw_to_ow: 0,
          fae_bypass: 0,
          use4issue: 0,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.resetEditDetails();
          this.$refs.RepairCodesEditForm?.resetValidation();
        }
      }
    },
    //Submit of Receiving Condition Edit
    async receivingCondChanged() {
      this.pass = 4;
      if (this.$refs.ReceivingCondEditForm?.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: parseInt(this.editItem["failc_id"]),
          type: this.editItem["attributetype"],
          code: this.receCondRename,
          desc: this.receCondDesc,
          parent_fc_key: 0,
          route: this.selectedRouteEdit,
          parent_class_key: 0,
          part_class_key: 0,
          tbe: 0,
          t02: 0,
          a03: 0,
          r06: 0,
          r01: 0,
          r05: 0,
          r02: 0,
          i01: 0,
          a02: 0,
          active: 1,
          iw_to_ow: this.ForceOWEdit,
          fae_bypass: 0,
          use4issue: 0,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.resetEditDetails();
          this.$refs.ReceivingCondEditForm?.resetValidation();
        }
      }
    },
    //Submit of Observed Problem Edit
    async observedProbChanged() {
      this.pass = 4;
      if (this.$refs.ObservedProbEditForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: parseInt(this.editItem["failc_id"]),
          type: this.editItem["attributetype"],
          code: this.obsProbRename,
          desc: this.obsProbDesc,
          parent_fc_key: 0,
          route: this.selectedRouteEdit,
          parent_class_key: 0,
          part_class_key: 0,
          tbe: 0,
          t02: 0,
          a03: 0,
          r06: 0,
          r01: 0,
          r05: 0,
          r02: 0,
          i01: 0,
          a02: 0,
          active: 1,
          iw_to_ow: this.ForceOWEdit,
          fae_bypass: 0,
          use4issue: 0,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.resetEditDetails();
          this.$refs.ObservedProbEditForm?.resetValidation();
        }
      }
    },
    async observedDamageChanged() {
      this.pass = 4;
      if (this.$refs.ObservedDamageEditForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: parseInt(this.editItem["failc_id"]),
          type: this.editItem["attributetype"],
          code: this.observedDamageCodeEdit,
          desc: this.observedDamageDescEdit,
          parent_fc_key: 0,
          route: "",
          parent_class_key: 0,
          part_class_key: 0,
          tbe: 0,
          t02: 0,
          a03: 0,
          r06: 0,
          r01: 0,
          r05: 0,
          r02: 0,
          i01: 0,
          a02: 0,
          active: 1,
          iw_to_ow: 0,
          fae_bypass: 0,
          use4issue: 0,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.resetEditDetails();
          this.$refs.ObservedProbEditForm?.resetValidation();
        }
      }
    },

    async symptomCodeChanged() {
      
      this.pass = 4;
      if (this.$refs.SymptomEditForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: parseInt(this.editItem["failc_id"]),
          type: this.editItem["attributetype"],
          code: this.symptomCodeNameEdit,
          desc: this.symptomCodeDescEdit,
          parent_fc_key: 0,
          route: "",
          parent_class_key: 0,
          part_class_key: 0,
          tbe: this.TBEEdit,
          t02: this.BITEdit,
          a03: this.ENGEdit,
          r01: this.REPEdit,
          r05: this.IRPEdit,
          r02: this.ALNEdit,
          i01: this.INSEdit,
          T03:this.FTTEdit,
          a02: this.OBAEdit,
          r06: 0,
          active: 1,
          iw_to_ow: 0,
          fae_bypass: 0,
          use4issue: 0,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.resetEditDetails();
          this.$refs.SymptomEditForm?.resetValidation();
        }
      }
    },

    //Reset Edit Details
    resetEditDetails() {
      this.onBusinessSelect();
      this.obsProbRename = "";
      this.obsProbDesc = "";
      this.symptomCodeEdit=false;
      this.symptomCodeNameEdit="";
      this.symptomCodeDescEdit="";
      this.observedDamageEdit=false;
      this.receivingCondEdit = false;
      this.selectedRouteEdit = "";
      this.ForceOWEdit = false;
      this.receCondRename = "";
      this.observedDamageCodeEdit="";
      this.observedDamageDescEdit="";
      this.receCondDesc = "";
      this.receivingCondEdit = false;
      this.ForceOWEdit = false;
      this.repairCodesRename = "";
      this.repairCodesDesc = "";
      this.repairCodesEdit = false;
      this.tier2FailureCodeRename = "";
      this.tier2FailureCodeEdit = false;
      this.tier2FailureCodeDesc = "";
      this.selectedParentClassEdit = "";
      this.selectedSubClassEdit = "";
      this.TBEEdit = false;
      this.BITEdit = false;
      this.ENGEdit = false;
      this.FRMEdit = false;
      this.REPEdit = false;
      this.IRPEdit = false;
      this.ALNEdit = false;
      this.FTTEdit = false;
      this.INSEdit = false;
      this.OBAEdit = false;
      this.bypassFAEEdit = false;
      this.partRequestEdit = false;
      this.tier1FailureCodeRename = "";
      this.tier1FailureCodeEdit = false;
      this.observedProbEdit = false;
    },
    //End of edit functionality

    //Start of New elements submit
    //New Tier 1 Failure Code Parent Submit
    async tier1FailureCodeSubmit() {
      this.pass = 5;
      if (this.$refs.Tier1AddForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: 0,
          type: "failcode_parent",
          code: this.tier1FailureCode,
          desc: this.tier1FailureCode,
          parent_fc_key: 0,
          route: "",
          parent_class_key: 0,
          part_class_key: 0,
          tbe: 0,
          t02: 0,
          a03: 0,
          r06: 0,
          r01: 0,
          r05: 0,
          r02: 0,
          i01: 0,
          a02: 0,
          active: 1,
          iw_to_ow: 0,
          fae_bypass: 0,
          use4issue: 0,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.onBusinessSelect();
          this.tier1FailureCode = "";
          this.$refs.Tier1AddForm?.resetValidation();
          this.closeAddDialog();
        }
      }
    },
    //New Failure Code submit for Tier 2 Failure Code
    async tier2FailureCodeSubmit(compData) {
      this.pass = 5;
      let obj = {
        pass: parseInt(this.pass),
        UserId: parseInt(this.userId),
        bu_key: parseInt(this.selectedBusiness),
        failc_id: 0,
        type: "failcode",
        code: compData.codeName,
        desc: compData.codeDesc,
        parent_fc_key: parseInt(compData.fc1Code),
        route: compData.route,
        parent_class_key: parseInt(compData.parent),
        part_class_key: parseInt(compData.subClass),
        tbe: compData.tbe,
        t02: compData.bit,
        a03: compData.eng,
        r06: compData.frm,
        r01: compData.rep,
        r05: compData.irp,
        r02: compData.aln,
        t03: compData.ftt,
        i01: compData.ins,
        a02: compData.oba,
        active: 1,
        iw_to_ow: compData.forceOw,
        fae_bypass: compData.bypass,
        use4issue: compData.partReq,
      };
      let showMessage = true;
      let response = await Utility.addNewItem(obj, showMessage);
      if (response !== null && response !== undefined) {
        this.onBusinessSelect();
        this.tier2FailureCode = "";
        this.showTier2Dialog = false;
      }
    },
    //New Repair Code Submit
    async repairCodeSubmit() {
      this.pass = 5;
      if (this.$refs.RepairAddForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: 0,
          type: "repair",
          code: this.repairCode,
          desc: this.repairCodeDesc,
          parent_fc_key: 0,
          route: "",
          parent_class_key: 0,
          part_class_key: 0,
          tbe: 0,
          t02: 0,
          a03: 0,
          r06: 0,
          r01: 0,
          r05: 0,
          r02: 0,
          i01: 0,
          a02: 0,
          active: 1,
          iw_to_ow: 0,
          fae_bypass: 0,
          use4issue: 0,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.repairCode = "";
          this.repairCodeDesc = "";
          this.onBusinessSelect();
          this.$refs.RepairAddForm?.resetValidation();
          this.closeAddDialog();
        }
      }
    },
    //New Receiving condition submit
    async receivingConditionSubmit() {
      this.pass = 5;
      if (this.$refs.ReceivingCondAddForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: 0,
          type: "rec_cond",
          code: this.receivingCondition,
          desc: this.receivingConditionDesc,
          parent_fc_key: 0,
          route: this.selectedRouteReceive,
          parent_class_key: 0,
          part_class_key: 0,
          tbe: 0,
          t02: 0,
          a03: 0,
          r06: 0,
          r01: 0,
          r05: 0,
          r02: 0,
          i01: 0,
          a02: 0,
          active: 1,
          iw_to_ow: this.ForceOW,
          fae_bypass: 0,
          use4issue: 1,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.receivingCondition = "";
          this.receivingConditionDesc = "";
          this.selectedRouteReceive = "";
          this.onBusinessSelect();
          this.$refs.ReceivingCondAddForm?.resetValidation();
          this.closeAddDialog();
        }
      }
    },
    //New observed problem submit
    async observedProbSubmit() {
      this.pass = 5;
      if (this.$refs.ObservedProbAddForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: 0,
          type: "rec_problem",
          code: this.observedProbCode,
          desc: this.observedProbDesc,
          parent_fc_key: 0,
          route: this.selectedRouteObserve,
          parent_class_key: 0,
          part_class_key: 0,
          tbe: 0,
          t02: 0,
          a03: 0,
          r06: 0,
          r01: 0,
          r05: 0,
          r02: 0,
          i01: 0,
          a02: 0,
          active: 1,
          iw_to_ow: this.ForceOW,
          fae_bypass: 0,
          use4issue: 1,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.observedProbCode = "";
          this.observedProbDesc = "";
          this.selectedRouteObserve = "";
          this.ForceOW = false;
          this.onBusinessSelect();
          this.$refs.ObservedProbAddForm?.resetValidation();
          this.closeAddDialog();
        }
      }
    },
    //New Symptom Code Submit
    async symptomCodeSubmit() {
      this.pass = 5;
      if (this.$refs.SymptomAddForm.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: 0,
          type: "symptom",
          code: this.symptomCode,
          desc: this.symptomCodeDesc,
          parent_fc_key: 0,
          route: "",
          parent_class_key: 0,
          part_class_key: 0,
          tbe: this.TBE,
          t02: this.BIT,
          a03: this.ENG,
          r06: 0,
          r01: this.REP,
          r05: this.IRP,
          r02: this.ALN,
          i01: this.INS,
          T03:this.FTT,
          a02: this.OBA,
          active: 1,
          iw_to_ow:0,
          fae_bypass: 0,
          use4issue: 0,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.observedProbCode = "";
          this.observedProbDesc = "";
          this.selectedRouteObserve = "";
          this.ForceOW = false;
          this.onBusinessSelect();
          this.$refs.SymptomAddForm?.resetValidation();
          this.$refs.SymptomAddForm?.reset();
          this.closeAddDialog();
        }
      }
    },
    //New Observed Damage (Repair) Submit
    async observedDamageSubmit() {
      this.pass = 5;
      if (this.$refs.ObservedDamageAddForm?.validate()) {
        let obj = {
          pass: parseInt(this.pass),
          UserId: parseInt(this.userId),
          bu_key: parseInt(this.selectedBusiness),
          failc_id: 0,
          type: "damage",

          code: this.observedDamageCode,
          desc: this.observedDamageDesc,
          parent_fc_key: 0,
          route: "",
          parent_class_key: 0,
          part_class_key: 0,
          tbe: 0,
          t02: 0,
          a03: 0,
          r06: 0,
          r01: 0,
          r05: 0,
          r02: 0,
          i01: 0,
          a02: 0,
          active: 1,
          iw_to_ow: 0,
          fae_bypass: 0,
          use4issue: 0,
        };
        let showMessage = true;
        let response = await Utility.addNewItem(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.observedProbCode = "";
          this.observedProbDesc = "";
          this.selectedRouteObserve = "";
          this.ForceOW = false;
          this.onBusinessSelect();
          this.$refs.ObservedProbAddForm?.resetValidation();
          this.closeAddDialog();
        }
      }
    },
    //New Grade B Reason submit
    async gradeBReasonSubmit() {},
    //MSR submit
    async MSRSubmit() {},
    //FAE Submit
    async FAESubmit() {},
    //PSC Submit
    async PSCSubmit() {},
    //Strike Submit
    async strikeSubmit() {},
    //NTF-RR Submit
    async NTFRRSubmit() {},
    //End of Submit of new elements
    //Close v-dialog's
    closeDialog() {
      if (this.tier1FailureCodeEdit) {
        this.$refs.Tier1EditForm?.reset();
        this.$refs.Tier1EditForm?.resetValidation();
        this.tier1FailureCodeEdit = false;
      } else if (this.tier2FailureCodeEdit) {
        this.$refs.Tier2EditForm?.reset();
        this.$refs.Tier2EditForm?.resetValidation();
        this.tier2FailureCodeEdit = false;
      } else if (this.repairCodesEdit) {
        this.$refs.RepairCodesEditForm?.reset();
        this.$refs.RepairCodesEditForm?.resetValidation();
        this.repairCodesEdit = false;
      } else if (this.receivingCondEdit) {
        this.$refs.ReceivingCondEditForm?.reset();
        this.$refs.ReceivingCondEditForm?.resetValidation();
        this.receivingCondEdit = false;
      } else if (this.observedProbEdit) {
        this.$refs.ObservedProbEditForm?.reset();
        this.$refs.ObservedProbEditForm?.resetValidation();
        this.observedProbEdit = false;
      }
      else if (this.symptomCodeEdit) {
        this.$refs.SymptomEditForm?.reset();
        this.$refs.SymptomEditForm?.resetValidation();
        this.symptomCodeEdit = false;
      }
        else if (this.observedDamageEdit) {
          this.observedDamageEdit=false;
          this.$refs.ObservedDamageEditForm?.reset();
          this.$refs.ObservedDamageEditForm?.resetValidation();
          this.observedProbEdit = false;
      } else if (this.statusChangeDialog) {
        this.statusChangeDialog = false;
      }
    },
  },
  components: {
    Tier2Comp,
    breadcrumbComp,
    BackToTop,
  },
};
