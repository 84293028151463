import EncryptUtility from "../../utility/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/addFav";
import adminService from "../../admin/js/admin-service";
import CommonDialog from "@/olp-components/common/common-dialog.vue";
import utility from "@/shared/utility";
import FilterComp from "@/olp-components/common/filter-comp.vue";
import DisplayTextData from "@/olp-components/common/display-text-data.vue";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      orderInfo:[],
      orderStatus:"",
      orderNotes:"",
      rejectNotes:"",
      showAdd: false,
      backSlash: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      cardsArray: [],
      orderHistoryList:[],
      rejectComments: "",
      orderHistoryHeader:[
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Comments",
          value: "comments",
        },
        { text: "Action By", value: "action_by" },
        {
          text: "Action Date",
          value: "action_date",
        },
      ],
      searchFilter:"",
      max100Rule: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(?=.*\S).+$/.test(v) || "Please enter at least one text or number",
        (v) =>
          (v || "").length <= 100 ||
          "Max Length must be 100 characters or less",
      ],
      headersApprove: [
        {
          text: "Order Number",
          value: "ord_no",
          class: "primary customwhite--text",
          width: "15%",
        },
        {
          text: "Business",
          value: "business",
          class: "primary customwhite--text",
          width: "15%",
        },
        {
          text: "Status",
          value: "order_status",
          class: "primary customwhite--text",
          width: "25%",
        },
        {
          text: "Ordered Date",
          value: "ordered_date",
          class: "primary customwhite--text",
          width: "24%",
        },
        {
          text: "Ordered By",
          value: "ordered_by",
          class: "primary customwhite--text",
          width: "13%",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
          width: "13%",
        },
      ],
      headersSpecificData: [
        {
          text: "Part Number",
          value: "part_num",
        },
        {
          text: "Description",
          value: "part_desc",
        },
        { text: "Qty", value: "qty" },
        {
          text: "Available Qty",
          value: "avail_qty",
        },
      ],
      orderList: [],
      arrayData:[],
      selectedCardId:1,
      showOrderDetails: false,
      showRejectDialog: false,
      partList: [],
      tempOrderList:[],
      uniqueBusinessList:[],
      uniqueOrderStatusList:[],
      uniqueOrderByList:[],
      orderId: "",
      totalRecords:0,
      addressObj: {
        company:"",
        address1: "",
        address2: "",
        city: "",
        state: "",
        pinCode: "",
        country: "",
        contactName: "",
        phone1: "",
        email: "",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  async mounted() {
    this.getDataOnLoad();
  },
  methods: {
    getDataOnLoad() {
      this.getTableData();
    },
    async getTableData() {
      let tableObj = {
        user_id: this.userId,
        pass: 1,    
        order_key: 0,
      };
      let obj = { json: JSON.stringify(tableObj) };
      let responseData = await adminService.postMyOrderListData(
        "post",
        obj,
        false
      );
      responseData?.forEach((element) => {
        element.ordered_date = utility.convertESTToLocal(element.ordered_date);
      });

      this.orderList = responseData;
      this.tempOrderList=responseData
      this.totalRecords=this.orderList?.length
      this.filterArrayData();
    },
    async getSpecificOrderDetails(order_key) {
      this.orderId = order_key;
      this.showOrderDetails = true;
      let tableObj = {
        user_id: this.userId,
        pass: 2,
        bu_key: 0,
        order_key: order_key,
        status_key: 0,
        comments: "",
      };
      let obj = { json: JSON.stringify(tableObj) };
      let responseData = await adminService.postMyOrderListData(
        "post",
        obj,
        false
      );

      this.orderInfo=[
        {label:"Order Number" ,value:responseData[0]?.ord_no},
        {label:"Business" ,value:responseData[0]?.business},
        {label:"Ordered By" ,value:responseData[0]?.username},
        {label:"Ordered Date" ,value:utility.convertESTToLocal(responseData[0]?.created_date)}        
    ],
    this.orderHistoryList=responseData[0].action_history;
    console.log(responseData[0])
    this.orderHistoryList?.forEach((element) => {
      element.action_date = utility.convertESTToLocal(element.action_date);
    });
    this.orderStatus=responseData[0]?.status_name;
    this.orderNotes=responseData[0]?.user_notes;
    this.rejectNotes=responseData[0]?.approver_comments;
      this.partList = responseData[0]?.part_details;
      this.addressObj = responseData.map((x) => ({
        company:x.Company,
        address1: x.address1,
        address2: x.address2,
        city: x.city,
        state: x.state,
        pinCode: x.zip,
        country: x.country,
        contactName: `${x.first} ${x.last}`,
        phone1: x.phone1,
        email: x.email,
      }));
    },
    filterArrayData() {
      this.uniqueBusinessList = [...new Map(this.orderList.map((item) => [item["business"], item.business])).values()];
      this.uniqueOrderStatusList = [...new Map(this.orderList.map((item) => [item["order_status"], item.order_status])).values()];
      this.arrayData = [
        { id: 2, label: "Status", array: this.uniqueOrderStatusList },
        { id: 1, label: "Business", array: this.uniqueBusinessList },
      ];
    },
    async filteredValues(value, type) {
      this.selectedFilterName = value;
      this.selectedFilterId = type;
      const filters = {
        0: () => this.tempOrderList,
        1: (value) => this.tempOrderList.filter((element) => element.business == value),
        2: (value) => this.tempOrderList.filter((element) => element.order_status == value),
        3: (value) => this.tempOrderList.filter((element) => element.ordered_by == value),

      };
      this.filterValueUpdate(filters[type](value));
    },
    filterValueUpdate(data) {
      this.totalRecords = data.length;
      this.orderList = data;
    },
  },

  components: {
    breadcrumbComp,
    CommonDialog,
    FilterComp,
    DisplayTextData
  },
};
