import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import AddFavourite from "../../utility/js/addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import inventoryService from "./inventory-service.js";
import html2pdf from "html2pdf.js";
import exportLimit from "../../common/export-limit.vue";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      controlID: "",
      controlIDList: [],
      disableControlID: false,
      unitIdentifier: "",
      disableUnitIdent: true,
      bin: "",
      binList: [],
      disableBin: true,
      lpn: "",
      lpnList: [],
      disableLPN: true,
      quantity: "",
      disableQuantity: true,
      disableRemoveUnit: true,
      disableScanLog: true,
      showScanDetails: false,
      logList: [],
      pdfName: "",
      exportDialog: false,
      totalRecords: 0,
      export50kData: false,
      excelName: "",
      logheaders: [
        { text: "Part#", align: "start", value: "Part#", class: "primary customwhite--text" },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        { text: "Serial#", value: "Serial#", class: "primary customwhite--text" },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        { text: "Bin", value: "Bin", class: "primary customwhite--text" },
        { text: "LPN", value: "LPN", class: "primary customwhite--text" },
        { text: "Quantity", value: "Qty", class: "primary customwhite--text" },
        { text: "Date", value: "Date", class: "primary customwhite--text" },
        { text: "Username", value: "Username", class: "primary customwhite--text" },
        { text: "Action", value: "Action", class: "primary customwhite--text" },
      ],
      apiObjClearUnit: {
        user_id: 0,
        pass: 0,
        controlID: 0,
        unit: "",
        bin_id: 0,
        lpn_id: 0,
        qty: 0,
      },
      json_fields: {
        "Part#": "Part#",
        BCN: "BCN",
        "Serial#": "Serial#",
        ESN: "ESN",
        Bin: "Bin",
        LPN: "LPN",
        Quantity: "Qty",
        Date: "Date",
        Username: "Username",
        Action: "Action",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getDropdownsList();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.controlID = "";
      this.controlIDList = [];
      this.disableControlID = false;
      this.unitIdentifier = "";
      this.disableUnitIdent = true;
      this.bin = "";
      this.binList = [];
      this.disableBin = true;
      this.lpn = "";
      this.lpnList = [];
      this.disableLPN = true;
      this.quantity = "";
      this.disableQuantity = true;
      this.disableRemoveUnit = true;
      this.disableScanLog = true;
      this.showScanDetails = false;
      this.logList = [];
      this.pdfName = "";
      this.export50kData = false;
      this.exportDialog = false;
      this.excelName = "";
      this.apiObjClearUnit = {
        user_id: 0,
        pass: 0,
        controlID: 0,
        unit: "",
        bin_id: 0,
        lpn_id: 0,
        qty: 0,
      };
      this.getDropdownsList();
    },
    //Clear button click event
    clearFunction() {
      this.unitIdentifier = "";
      this.disableUnitIdent = false;
      this.bin = "";
      this.binList = [];
      this.disableBin = true;
      this.lpn = "";
      this.lpnList = [];
      this.disableLPN = true;
      this.quantity = "";
      this.disableQuantity = true;
      this.disableRemoveUnit = true;
      this.showScanDetails = false;
      this.logList = [];
      this.pdfName = "";
      this.export50kData = false;
      this.exportDialog = false;
      this.excelName = "";
    },
    //Control Dropdowns API call
    async getDropdownsList() {
      this.apiObjClearUnit.pass = 1;
      this.apiObjClearUnit.user_id = parseInt(this.userId);
      let data = await inventoryService.postClearUnitScanLog("post", this.apiObjClearUnit, false);
      if (data.message !== "NA") {
        this.controlIDList = data;
      }
    },
    //On Change of Control ID
    async changeControlID() {
      this.apiObjClearUnit.pass = 2;
      this.apiObjClearUnit.user_id = parseInt(this.userId);
      this.apiObjClearUnit.controlID = parseInt(this.controlID);
      let data = await inventoryService.postClearUnitScanLog("post", this.apiObjClearUnit, false);
      if (data.message !== "NA") {
        this.disableScanLog = false;
        this.disableControlID = true;
        this.disableUnitIdent = false;
        this.disableBin = true;
        this.disableLPN = true;
        this.disableQuantity = true;
        this.disableRemoveUnit = true;
      }
    },
    //Change in Unit Identifier
    async changeUnitIdentifier() {
      this.apiObjClearUnit.pass = 3;
      this.apiObjClearUnit.user_id = parseInt(this.userId);
      this.apiObjClearUnit.controlID = parseInt(this.controlID);
      this.apiObjClearUnit.unit = this.unitIdentifier;
      let data = await inventoryService.postClearUnitScanLog("post", this.apiObjClearUnit, false);
      if (data.message !== "NA" && data.message !== null) {
        if (typeof data !== "string") {
          this.binList = data;
          this.disableBin = false;
          this.disableRemoveUnit = true;
        } else {
          this.disableBin = true;
          this.disableRemoveUnit = false;
        }
        this.disableScanLog = false;
        this.disableControlID = true;
        this.disableUnitIdent = true;
        this.disableLPN = true;
        this.disableQuantity = true;
      } else {
        this.disableUnitIdent = false;
        this.disableBin = true;
        let Alert = {
          type: "error",
          isShow: true,
          message: "No available Bins for selected Unit Identifier",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Change of Bin
    async changeBin() {
      this.apiObjClearUnit.pass = 4;
      this.apiObjClearUnit.user_id = parseInt(this.userId);
      this.apiObjClearUnit.controlID = parseInt(this.controlID);
      this.apiObjClearUnit.unit = this.unitIdentifier;
      this.apiObjClearUnit.bin_id = parseInt(this.bin);
      let data = await inventoryService.postClearUnitScanLog("post", this.apiObjClearUnit, false);
      if (data.message !== "NA") {
        this.disableScanLog = false;
        this.disableControlID = true;
        this.disableUnitIdent = true;
        this.disableBin = true;
        this.disableLPN = false;
        this.disableQuantity = true;
        this.disableRemoveUnit = true;
        this.lpnList = data;
      }
    },
    //Change of LPN
    async changeLPN() {
      this.apiObjClearUnit.pass = 6;
      this.apiObjClearUnit.user_id = parseInt(this.userId);
      this.apiObjClearUnit.controlID = parseInt(this.controlID);
      this.apiObjClearUnit.unit = this.unitIdentifier;
      this.apiObjClearUnit.bin_id = parseInt(this.bin);
      this.apiObjClearUnit.lpn_id = parseInt(this.lpn);
      let data = await inventoryService.postClearUnitScanLog("post", this.apiObjClearUnit, false);
      if (data.message !== "NA") {
        this.disableScanLog = false;
        this.disableControlID = true;
        this.disableUnitIdent = true;
        this.disableBin = true;
        this.disableLPN = true;
        this.disableQuantity = false;
        this.disableRemoveUnit = true;
      }
    },
    //Change in quantity
    changeQuantity() {
      this.disableScanLog = false;
      this.disableControlID = true;
      this.disableUnitIdent = true;
      this.disableBin = true;
      this.disableLPN = true;
      this.disableQuantity = true;
      this.disableRemoveUnit = false;
    },
    //Remove Unit
    async removeUnit() {
      if (this.$refs.removeUnitForm.validate()) {
        this.apiObjClearUnit.pass = 5;
        this.apiObjClearUnit.user_id = parseInt(this.userId);
        this.apiObjClearUnit.controlID = parseInt(this.controlID);
        this.apiObjClearUnit.unit = this.unitIdentifier;
        this.apiObjClearUnit.bin_id = parseInt(this.bin);
        this.apiObjClearUnit.lpn_id = parseInt(this.lpn);
        this.apiObjClearUnit.qty = parseInt(this.quantity);
        let data = await inventoryService.postClearUnitScanLog("post", this.apiObjClearUnit, true);
        if (data.message !== "NA") {
          this.clearFunction();
        }
      }
    },
    //Scan log button click event
    async scanLog() {
      let obj = {
        user_id: parseInt(this.userId),
        controlID: parseInt(this.controlID),
        latest: "",
      };
      let data = await inventoryService.postReportClearLog("post", obj, false);
      if (data.message !== "NA") {
        const newArr = data.map((obj) => {
          return {
            ...obj,
            Date: Utility.convertESTToLocal(obj.Date),
          };
        });
        this.logList = newArr;
        this.showScanDetails = true;
        this.totalRecords = newArr.length;
      }
    },
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    // to export the Execel file
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        this.startDownload();
        let obj = {
          user_id: parseInt(this.userId),
          controlID: parseInt(this.controlID),
          latest: "",
        };
        let data = await inventoryService.postReportClearLog("post", obj, false);
        this.excelName =
          "Remove_Unit_From_Scan_Log_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const newArr = data.map((obj) => {
          return {
            ...obj,
            Date: Utility.convertESTToLocal(obj.Date),
          };
        });
        this.export50kData = false;
        this.finishDownload();
        return newArr;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Export pdf
    printRecords() {
      this.startDownload();
      for (let i = 0; i < this.logList.length; i++) {
        delete this.logList[i].boldbit;
        delete this.logList[i].linecolor;
        delete this.logList[i].columncount;
      }
      let response = [];
      response = this.buildHtmlTable(this.logList);
      this.pdfName =
        "Remove_Unit_Scan_Log_Details" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(response, {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
      this.finishDownload();
    },
    buildHtmlTable(response) {
      this.tblTag = document.createElement("table");
      this.trTag = document.createElement("tr");
      this.thTag = document.createElement("th");
      this.tdTag = document.createElement("td");
      let table = this.tblTag.cloneNode(false);
      table.setAttribute("cellpadding", "0");
      table.setAttribute("cellspacing", "0");
      table.style.border = "1px solid black";
      table.style.borderRight = "none";
      table.style.borderTop = "none";
      let columns = this.addAllColumnHeaders(response, table);
      columns = columns.filter((column) => column !== "tid");
      for (var i = 0, maxi = response.length; i < maxi; ++i) {
        var tr = this.trTag.cloneNode(false);
        for (var j = 0, maxj = columns.length; j < maxj; ++j) {
          var td = this.tdTag.cloneNode(false);
          td.style.border = "1px solid black";
          td.style.padding = "10px";
          td.style.borderLeft = "none";
          td.style.borderBottom = "none";
          var cellValue = response[i][columns[j]];
          td.appendChild(document.createTextNode(cellValue || ""));
          tr.appendChild(td);
        }
        table.appendChild(tr);
      }
      return table;
    },
    addAllColumnHeaders(response, table) {
      let columnSet = [];
      let tr = this.trTag.cloneNode(false);
      for (var i = 0, l = response.length; i < l; i++) {
        for (var key in response[i]) {
          if (columnSet.indexOf(key) === -1) {
            if (key != "tid") {
              columnSet.push(key);
              var th = this.thTag.cloneNode(false);
              th.style.border = "1px solid black";
              th.setAttribute("padding", "5px");
              th.style.borderLeft = "none";
              th.style.borderBottom = "none";
              th.style.backgroundColor = "#005ba8";
              th.style.color = "white";
              if (key == "Part#") key = "Part#";
              if (key == "BCN") key = "BCN";
              if (key == "Serial#") key = "Serial#";
              if (key == "ESN") key = "ESN";
              if (key == "Bin") key = "Bin";
              if (key == "LPN") key = "LPN";
              if (key == "Qty") key = "Quantity";
              if (key == "Date") key = "Date";
              if (key == "Username") key = "Username";
              if (key == "Action") key = "Action";
              th.appendChild(document.createTextNode(key));
              tr.appendChild(th);
            }
          }
        }
      }
      table.appendChild(tr);
      return columnSet;
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    exportLimit,
  },
};
