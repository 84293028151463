import Vue from "vue";
import excel from "vue-excel-export";
import breadcrumbComp from "../../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../../../components/utility/js/encrypt-utility";
import AddFavourite from "../../../utility/js/addFav";
import Utility from "../../../../shared/utility";
import exportLimit from "../../../common/export-limit.vue";
import inventoryService from "../../js/inventory-service";
import DisplayTextData from "@/components/common/display-text-data.vue";
import CommonDialog from "@/components/common/common-dialog.vue";
Vue.use(excel);
export default {
  data: () => ({
    mainPage: "",
    subPage: "",
    subPageUrl: "",
    disableForm:true,
    appPageName: "",
    searchParent:"",
    searchWarehouse:"",
    searchLocation:"",
    pageUrl: "",
    statusMapping:{
      "#008000":"success--text f-15 f-bold",
      "#FFA500":"secondary--text f-15 f-bold",
      "default":"f-15 f-bold"
      
    },
    searchPart:"",
    backSlashParent: true,
    excelFileName: "",
    compCalled: false,
    showAdd: false,
    favouriteList: [],
    selectedPartType:[],
    isFormValid: false,
    actionList: [],
    totalRecords: 0,
    showBinList: false,
    selectAllPart:false,
    activeTab: 0,
    tabs: [
      "Part Type",
      "Business",
      "Parent Class",
      "Sub Class",
      "Warehouse",
      "Location",
    ],
    userId: EncryptUtility.localStorageDecrypt("userID"),
    refresh: true,
    projectList: [],
    scopeList: [],
    selectedItems: [],
    scopeDataList: [],
    reconciliationList: [],
    partTypeList: [],
    binList: [],
    businessList: [],
    parentList: [],
    warehouseList: [],
    locationList: [],
    subClassList: [],
    partArray: [],
    scopeDetails: [],
    selectedBusiness:[],
    totalBusiness:0,
    totalparentClass:0,
    totalsubClass:0,
    totalWarehouse:0,
    totalWorkCenter:0,
    totalPartCount:0,
    searchBusiness:"",
    disableTab: true,
    disableSubClassTab: true,
    partNumberSwitch: false,
    binSwitch: false,
    showDialog:false,
    message:"",
    allPart: false,
    allBin: false,
    rowDes:"",
    rowNum: "",
    minBin: "",
    maxBin: "",
    search: "",
    partNum: "",
    binNum: "",
    scopeListHeader: [
      {
        text: "Scope",
        align: "start",
        value: "Scope",
        class: "primary customwhite--text",
      },
      {
        text: "Project",
        align: "start",
        value: "Project",
        class: "primary customwhite--text",
      },
      {
        text: "Type",
        align: "start",
        value: "Type",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "actions",
        class: "primary customwhite--text",
      },
    ],
    partTypeHeader: [
      {
        text: "Selected",
        align: "start",
        value: "checked",
        class: "primary customwhite--text",
        sortable:false,
      },
      {
        text: "Part Type",
        align: "start",
        value: "Part Type",
        class: "primary customwhite--text",
      },

    ],
    warehouseHeader: [
      {
        text: "Warehouse",
        align: "start",
        value: "Warehouse",
        class: "primary customwhite--text",
      },
    ],
    businessHeader: [
      {
        text: "Selected",
        align: "start",
        value: "checked",
        class: "primary customwhite--text",
        sortable:false,
      },
      {
        text: "Business",
        align: "start",
        value: "Business",
        class: "primary customwhite--text",
      },
    ],
    binHeader: [
      {
        text: "Bin",
        align: "start",
        value: "Bin",
        class: "primary customwhite--text",
      },
    ],
    locationHeader: [
      {
        text: "Location",
        align: "start",
        value: "Location",
        class: "primary customwhite--text",
      },
    ],
    subClassHeader: [
      {
        text: "Sub Class",
        align: "start",
        value: "Class",
        class: "primary customwhite--text",
      },
    ],
    parentHeader: [
      {
        text: "Parent Class",
        align: "start",
        value: "Parent Class",
        class: "primary customwhite--text",
      },
    ],
    required: [(v) => !!v || "Field is required"],
    controlValidataion: [
      (v) => !!v || "Field is required",
      (v) =>
        /^(?:[ A-Za-z0-9\s\S]){5,25}$/.test(v) ||
        "Please enter at least one text or number, Field should be greater than 5 characters and less than 25 characters",
    ],
    accuracyValidation: [
      (v) => parseInt(v) >= 0 || "Field is required",
      (v) =>
        /^[1-9][0-9]?(\.[0-9]{1,2})?$/.test(v) ||
        "Between 1 to 99 Upto 2 decimal places are allowed",
    ],
    min1Rule: [
      (v) => parseInt(v) >= 0 || "Field should be greather than 0",
      (v) => !!v || "Field is required",
      
    ],
    binRule:[
      (v) => !!v || "Field is required",(v) =>
        /^[A-Z]{1,2}[0-9]{1,3}$/.test(v) ||
        "Please enter the correct format Eg:- A111,AB11",
    ],
    max999Rule: [
      (v) => parseInt(v) <= 999 || "Field should be less than than 999",
      (v) => !!v || "Field is required",
      
    ],
    only8CharAllowed: [
      (v) => !!v || "Field is required",
      (v) => (v || "").length == 8 || "Field should have 8 characters",
    ],
    backSlash: true,
    controlId: 0,
    saveObj: {
      Project: 0,
      PiTypeKey: 0,
      Control: "",
      AccuracyGoal: 0,
      Unlock: "",
      Zone: "",
      PreCounts: false,
      ReconcileMethod: 0,
      Serialized: false,
      NonSerialized: false,
      Business: [],
      ParentClass: [],
      SubClass: [],
      Warehouse: [],
      Location: [],
      WorkCenter: [],
      PartNumber: [],
      Bin: [],
    },
    editObj: {
      controlID: 0,
      json: {
        ControlID: 0,
        Control: "",
        Project: "",
        PiTypeKey: "",
        StatusKey: "",
        Unlock: "",
        Zone: "",
        AccuracyGoal: "",
        PreCounts: "",
        ReconcileMethod: -1,
        PartType: [],
        Serialized: "N",
        NonSerialized: "N",
        Business: [],
        SubBusiness: [],
        ParentClass: [],
        Class: [],
        Warehouse: [],
        WorkCenter: [],
        PartNumber: [],
        location:"",
      },
      user_key: 0,
      isnew: 0,
    },
  }),


  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.subPageUrl = data.subPageUrl;
    this.appPageName = data.appPageName;
    this.controlId = atob(this.$route?.params?.id);
    this.mappingData()
    this.disableTab = false;
  },
  watch: {
    // Watch for changes in individual checkboxes to sync with "Select All"
    partTypeList: {
      handler() {
        this.selectAllPart = this.partTypeList.every((item) => item.checked);
        console.log(this.selectAllPart)
      },
      deep: true,
    },
    businessList: {
      handler() {
        this.selectAllBusiness = this.businessList.every((item) => item.checked);
        console.log(this.selectAllBusiness)
      },
      deep: true,
    },
    
  },
  async mounted() {
    let obj = {
      user_id: this.userId,
      pass: 1,
    };
 

    let resp = await inventoryService.getAddData("post", obj);
    this.projectList = resp.Projects;
    this.scopeList = resp.Scope;
    this.reconciliationList = resp.Type;
  },
  methods: {
    actionSetter(action) {
      return Utility.actionSetter(this.actionList, action);
    },

    async onChangeType() {
      this.editObj.json.Project = this.saveObj.Project;
      this.editObj.json.PiTypeKey = this.saveObj.PiTypeKey;
      this.editObj.user_key = this.userId;
      let submitObj = JSON.parse(JSON.stringify(this.editObj));
      submitObj.json = JSON.stringify(submitObj.json);
      let resp = await inventoryService.postScopeEdit("post", submitObj, false);
      this.partTypeList = resp.parttype;
      this.businessList = resp.Business;
    },
    shouldDisableTab(tab) {
      const disabledTabs = [
        "Warehouse",
        "Location",
        "Parent Class",
        "Sub Class",
      ];

      if (!this.disableTab || !disabledTabs.includes(tab)) {
        return false;
      }

      if (tab === "Sub Class") {
        return (
          !this.editObj.ParentClass || this.editObj.ParentClass.length === 0
        ); // Disabled by default unless ParentClass is selected
      }

      return true;
    },
    async onChangeBusinessSelect(item) {
      console.log(item)
      item.checked=!item.checked
        if (item.checked) {   
          // Add to selectedItems if checked
          this.selectedBusiness.push(item);
        } else {
          // Remove if unchecked
          this.selectedBusiness= this.selectedBusiness.filter(
            (selected) => selected.bu_key !== item.bu_key
          );
    }
    console.log(this.selectedBusiness)
      if (this.editObj.json.Business.length > 0) {
        this.editObj.json.ParentClass=[];
        this.editObj.json.SubClass=[];
        this.editObj.json.Warehouse=[];
        this.editObj.json.Location=[];
        this.disableTab = false;
        let submitObj = JSON.parse(JSON.stringify(this.editObj));
        submitObj.json.PartType = this.selectedPartType.map(
          (item) => item.ptype_key
        );
        submitObj.json.Business = this.selectedBusiness.map(
          (item) => item.bu_key
        );
        submitObj.json = JSON.stringify(submitObj.json);
        let resp = await inventoryService.postScopeEdit(
          "post",
          submitObj,
          false
        );
        this.parentList = resp.Parentclass;
        this.locationList = resp.Location;
        this.warehouseList = resp.Warehouse;
      } else {
        this.disableTab = true;
      }
    },
    
    toggleSelectAll(tableName){
      if(tableName=="Part Type"){
      this.selectAllPart=!this.selectAllPart; // Update selectAll state
      this.partTypeList.forEach((item) => {
        item.checked = this.selectAllPart; // Set all row checkboxes
      });
    }
    else {
      this.selectAllBusiness=!this.selectAllBusiness; // Update selectAll state
      this.businessList.forEach((item) => {
        item.checked = this.selectAllBusiness; // Set all row checkboxes
      });
      }
    },
    

    async onChangeParentSelect() {
      this.editObj.json.Class=[]
      if (this.editObj.json.Business.length > 0) {
        this.disableTab = false;
        let submitObj = JSON.parse(JSON.stringify(this.editObj));
        submitObj.json.PartType = submitObj.json.PartType.map(
          (item) => item.ptype_key
        );
        submitObj.json.Business = submitObj.json.Business.map(
          (item) => item.bu_key
        );
        submitObj.json.ParentClass = submitObj.json.ParentClass.map(
          (item) => item.parent_class_key
        );
        submitObj.json.Warehouse = submitObj.json.Warehouse.map(
          (item) => item.ware_key
        );
        submitObj.json.WorkCenter = submitObj.json.Location.map(
          (item) => item.loc
        );
        submitObj.json.Location=[];
        submitObj.json = JSON.stringify(submitObj.json);
        let resp = await inventoryService.postScopeEdit(
          "post",
          submitObj,
          false
        );
        this.parentList = resp.Parentclass;
        this.locationList = resp.Location;
        this.warehouseList = resp.Warehouse;
        this.subClassList = resp.Class;
      }
    },

    async onClickValidatePart() {
      let scope = JSON.parse(JSON.stringify(this.saveObj));
      scope.NonSerialized ? "Y" : "N";
      scope.Serialized ? "Y" : "N";
      scope.PreCounts ? "Y" : "N";
      let validateObj = {
        json: JSON.stringify(scope),
        user_key: this.userId,
        part_num: this.partNum,
      };
      let resp = await inventoryService.postValidate(
        "post",
        validateObj,
        false
      );
      resp ? this.partArray.push(this.partNum) : "";
    },
    async onClickValidateBin(loc){
      let isValid = false;
      this.loc=loc

  if (loc === "Num") {
    isValid = await this.$refs.numForm.validate(); // Validate 'Num' form
    this.rowDes="";
    this.minBin="";
    this.maxBin="";
    this.$refs?.binRangeForm?.resetValidation()
  } else {
    isValid = await this.$refs.binRangeForm.validate(); // Validate 'Other' form
    this.binNum=""
    this.$refs?.numForm?.resetValidation()
  }
  if(isValid)
        {let binObj={
            pass:loc=="Num"?2:1,
            controlID:0,
            row:this.rowDes,
            low:this.minBin,
            high:this.maxBin,
            bin:this.binNumber
        }
        let resp= await inventoryService.postValidateBin("post",binObj,false)
        this.binList=resp
        if(this.binList.length>0){
            this.showBinList=true
            this.selectedItems=this.binList
        }}

    },

    onClickUndo(){
      this.showDialog=true;
      this.message="Are you sure you want to Undo the item?";
      this.location="Undo"
    },
    onClickPrepare(){
      this.showDialog=true;
      this.message="Are you sure you want to Prepare the item?";
      this.location="Prepare"
    },

    onClickReconcile(){
      this.showDialog=true;
      this.message="Are you sure you want to Reconcile  this item?";
      this.location="Reconcile"
    },

    onClickAbortCount(){
      this.showDialog=true;
      this.message="Are you sure you want to Abort the count  this item?";
      this.location="Abort"
    },
    onClickStartCount(){
      this.showDialog=true;
      this.message="Are you sure you want to Start the Count of this item?";
      this.location="Start"
    },
    onClickDeleteScope(){
      this.showDialog=true;
      this.message="Are you Sure you want to delete this item?";
      this.location="Delete"
    },

    onClickDialogOk(){
      this.location=="Undo"?this.resetFunction():"";
      this.location=="Abort"?this.onClickAbort():"";
      this.location=="Prepare"?this.onClickPrep():"";
      this.location=="Reconcile"?this.onClickReconile():"";
      this.location=="Delete"?this.onClickDelete():"";
      this.location=="Start"?this.onClickScan():"";
      this.showDialog=false;
      this.location=""
    },
    onClickDialogCancel(){
      this.location=""
      this.showDialog=false;
    },

    resetFunction(){
      this.mappingData()
    },
    async mappingData(){
    let obj = {
      user_id: this.userId,
      control_id: this.controlId,
    };
    let resp = await inventoryService.getScopeDetails("post", obj, false);
    resp.PartNumber.length==0?this.allPart=true:this.partNumberSwitch=true;
    this.partNum=resp?.PartNumber[0]?.partNum;
    this.editObj.controlID=this.controlId
    this.editObj.isnew=1
    this.editObj.json.ControlID=this.controlId
    this.editObj.user_key=this.userId
    resp?.Bin.length==0?this.allBin=true:this.binSwitch=true;
    this.binList=resp?.Bin;
    this.selectedItems=this.binList;
    this.binList.length>0?this.showBinList = true:"";
    let header = resp.Header[0];
    header?.status =="In-Active"?this.disableForm=false:this.disableForm=true
   
    this.scopeDetails = [
      { label: "Project", value: header?.project },
      { label: "Type", value: header?.pi_type },
      { label: "Status", value: header?.status,valueCss:this.statusCss(header?.statusfontcolor) },
    ];
    this.saveObj = {
      Project: header.proj_key || 0,
      PiTypeKey: header.pi_type_key || 0,
      Control: header.control || "",
      AccuracyGoal: header.accuracy_goal || 0,
      Unlock: header.unlock || "",
      Zone: header.zone || "",
      PreCounts: header.count_bcn || false,
      ReconcileMethod: header.reconcile_method || 0,
      Serialized: header.count_bcn || false,
      NonSerialized: header.count_non_bcn || false,
      Business: [], // Placeholder for array mapping
      ParentClass: [], // Placeholder for array mapping
      SubClass: [], // Placeholder for array mapping
      Warehouse: [], // Placeholder for array mapping
      Location: [], // Placeholder for array mapping
      WorkCenter: [], // Placeholder for array mapping
      PartNumber: [], // Placeholder for array mapping
      Bin: [], // Placeholder for array mapping
    };
    const mappings = {
      Business: "businessList",
      PartType: "partTypeList",
      ParentClass: "parentList",
      Class: "subClassList",
      Warehouse: "warehouseList",
      Location: "locationList",
    };

    // Assign values dynamically
    Object.keys(mappings).forEach((key) => {
      const listName = mappings[key];
      this[listName] = resp[key];
      this.editObj.json[key] = resp[key].filter((x)=>x.checked==true);
    });
    this.selectedPartType=resp.PartType.filter((x)=>x.checked==true)
    resp.PartType==["All"]?this.selectAllPart=true:this.selectAllPart=false
    this.selectedBusiness=resp.Business.filter((x)=>x.checked==true)
    this.totalBusiness= resp.Business.length,
    this.totalparentClass=resp.ParentClass,
    this.totalsubClass=resp.Class,
    this.totalWarehouse=resp.Warehouse,
    this.totalWorkCenter=resp.Location,
    this.totalPartCount=resp.PartType
    },
    async onClickSubmit() {
      const validateSelection = (key, tabId, message) => {
        if (this.editObj?.json[key]?.length == 0) {
          document.getElementById(tabId).click();
          let Alert = {
            type: "error",
            isShow: true,
            message: message,
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        } else {
          return false;
        }
      };
      if (
        validateSelection("PartType", "tab-0", "Select at least one Part Type")
      );
      if (
        validateSelection("Business", "tab-1", "Select at least one Business")
      );
      if (this.disableTab) {
        if (
          validateSelection(
            "ParentClass",
            "tab-2",
            "Select at least one Parent Class"
          )
        );
        if (
          validateSelection("Class", "tab-3", "Select at least one Sub Class")
        );
        if (
          validateSelection(
            "Warehouse",
            "tab-4",
            "Select at least one Warehouse"
          )
        );
        if (
          validateSelection("Location", "tab-5", "Select at least one Location")
        );
      }
      if (this.$refs.editScopeForm.validate()) {
        this.selectedPartType=this.partTypeList.filter((x)=>x.checked==true)
        this.selectedBusiness=this.businessList.filter((x)=>x.checked==true)
        const mapKeys = (items, key) => items.map((item) => item[key]);
        const jsonObj = {
          Project: this.saveObj.Project,
          PiTypeKey: this.saveObj.PiTypeKey,
          ControlID: this.controlId,
          Control: this.saveObj.Control,
          AccuracyGoal: this.saveObj.AccuracyGoal,
          Unlock: this.saveObj.Unlock,
          Zone: this.saveObj.Zone,
          PreCounts: this.saveObj.PreCounts ? "Y" : "N",
          ReconcileMethod: this.saveObj.ReconcileMethod,
          Serialized: this.saveObj.Serialized ? "Y" : "N",
          NonSerialized: this.saveObj.NonSerialized ? "Y" : "N",
          PartType:this.selectAllPart?["All"]: mapKeys(this.selectedPartType, "ptype_key"),
          Business: this.selectAllBusiness?["All"]: mapKeys(this.selectedBusiness, "bu_key"),
          ParentClass:this.totalparentClass== this.editObj?.json?.ParentClass.length?["All"]: mapKeys(this.editObj?.json?.ParentClass, "parent_class_key"),
          SubClass:this.totalsubClass== this.editObj?.json?.Class.length?["All"] :mapKeys(this.editObj?.json?.Class, "class_key"),
          Warehouse:this.totalWarehouse== this.editObj?.json?.Warehouse.length?["All"] :mapKeys(this.editObj?.json.Warehouse, "ware_key"),
          WorkCenter:this.totalWorkCenter== this.editObj?.json?.Location.length?["All"] :mapKeys(this.editObj?.json.Location, "loc"),
          PartNumber: this.allPart ? ["All"] : this.partArray,
          Bin: this.allBin
            ? ["All"]
            : this.selectedItems?.map((item) => item.bin_key),
        };
        const submitObj = {
          user_id: this.userId,
          json: JSON.stringify(jsonObj),
          controlID: this.controlId,
        };

         await inventoryService.updateScope(
          "post",
          submitObj,
          true
        );
      }
    },

    statusCss(statusColor){
      return this.statusMapping[statusColor] || this.statusMapping["default"];

    },
    async onClickAbort(){
      let obj={
        user_id:this.userId,
        controlID:this.controlId
      }
      await inventoryService.postAbortAction("post",obj,true)
      this.mappingData()
    },
    async onClickScan(){
      let obj={
        user_id:this.userId,
        controlID:this.controlId
      }
      await inventoryService.postScanAction("post",obj,true)
      this.mappingData()
    },
    async onClickPrep(){
      let obj={
        user_id:this.userId,
        controlID:this.controlId
      }
      await inventoryService.postPrepAction("post",obj,true)
      this.mappingData()
    },
    async onClickDelete(){
      let obj={
        user_id:this.userId,
        controlID:this.controlId
      }
     let res= await inventoryService.postDeleteAction("post",obj,true)
     res?this.$router.push(`/scope-manager-list`):""
    },
    async onClickReconile(){
      let obj={
        user_id:this.userId,
        controlID:this.controlId
      }
      await inventoryService.postReconileAction("post",obj,true)
      this.mappingData()
    }
  },
  components: {
    breadcrumbComp,
    DisplayTextData,
    exportLimit,
    CommonDialog
  },
};
