import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import shippingService from "../js/shipping-service";
import PrinterDetails from "../../common/printer-details";
import commonAPIService from "../../common/js/common-api-service";
import Utility from "../../../shared/utility.js";
export default {
  data: () => ({
    userId: EncryptUtility.localStorageDecrypt("userID"),
    dropLPN: false,
    lpnDrop: "",
    actionList: [],
    mainPage: "",
    favouriteList: [],
    pageUrl: "",
    subPage: "",
    showAdd: false,
    subPageUrl: "",
    backSlash: true,
    backSlashParent: true,
    showRefreshDialog: true,
    pickId: 0,
    processType: 0,
    headerData: [],
    lineData: [],
    singleLineId: 0,
    pickDetailsDialog: false,
    pickHeaderData: [],
    pickOptionsData: [],
    pickBinData: [],
    targetInTransitLPN: "",
    disableTargetLPN: false,
    unitID: "",
    disableUnitId: true,
    sourceBinLPN: "",
    disableSourceBinLPN: true,
    disableSourceLPN: true,
    quantity: "",
    disableQuantity: true,
    disableSubmitPick: true,
    customerId1: "",
    customerId2: "",
    disablePickBin: true,
    onPageLoad: false,
    scanPartNum: "",
    userAssignedLPN: "",
    dropOffLPNDialog: false,
    stagingLocationLPN: "",
    debouncedGetPartList: [],
    showPartNumDetails: false,
    partResultList: [],
    pickedPartNum: "",
    isSerialized: false,
    availableQty: 0,
    enableShortPick: false,
    enableDropLPN: false,
    mainCustID1: "",
    mainCustID2: "",
    refUnitID: null,
    dropPickedLPN: true,
    showUnitID: false,
    showBinLPN: false,
    showQuantity: false,
    showSubmit: false,
    max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required"],
    lpnRule: [
      (v) => (v || "").length <= 39 || "Max Length of 39 character",
      (v) => !!v || "Field is required",
      (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000",
    ],
    headersPick: [
      { text: "Line Number", value: "line_num", class: "primary customwhite--text", align: "start", sortable: false },
      { text: "Part Number", value: "part_num", class: "primary customwhite--text", sortable: false },
      { text: "Order Quantity", value: "order_qty", class: "primary customwhite--text", sortable: false },
      { text: "Picked Quantity", value: "picked_qty", class: "primary customwhite--text", sortable: false },
      { text: "Assigned To", value: "assigned_to", class: "primary customwhite--text", sortable: false },
      { text: "Actions", value: "Actions", class: "primary customwhite--text", sortable: false },
      { text: "Exit Line", value: "Exit", class: "primary customwhite--text", sortable: false },
    ],
    pickLineHeaders: [
      { text: "Line#", value: "line_num", class: "primary customwhite--text", align: "start", sortable: false },
      { text: "Part", value: "partdetails", class: "primary customwhite--text", sortable: false },
      { text: "Warehouse", value: "warehouse", class: "primary customwhite--text", sortable: false },
      { text: "Order Quantity", value: "ordered_qty", class: "primary customwhite--text", sortable: false },
      { text: "Picked", value: "picked_qty", class: "primary customwhite--text", sortable: false },
    ],
    pickBinHeaders: [
      { text: "", value: "pull", class: "primary customwhite--text", align: "start" },
      { text: "Bin", value: "bin", class: "primary customwhite--text" },
      { text: "LPN", value: "lpn", class: "primary customwhite--text" },
      { text: "Quantity", value: "qty", class: "primary customwhite--text" },
      { text: "Avg. Age(Days)", value: "avg_age", class: "primary customwhite--text" },
    ],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    let actionData = await AddFavourite.getParentPageAction(this.userId, this.subPageUrl);
    this.actionList = actionData?.actionList;
    if (this.$route.params.id !== undefined && this.$route.params.processType !== undefined) {
      this.pickId = atob(this.$route.params.id);
      this.processType = atob(this.$route.params.processType);
    } else {
      this.$router.push(`/fulfillment-picking-queue`);
    }
    this.onPageLoad = true;
    this.dropPickedLPN = false;
    this.getPickingDetails(this.onPageLoad, this.dropPickedLPN);
    this.debouncedGetPartList = Utility.debounce(this.getPartPickingData, 500);
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.onPageLoad = true;
      this.dropPickedLPN = false;
      this.getPickingDetails(this.onPageLoad, this.dropPickedLPN);
    },
    //Part number search event
    async searchPartNum() {
      this.scanPartNum = this.scanPartNum?.toUpperCase();
      if (this.scanPartNum?.length > 2) {
        this.debouncedGetPartList();
      }
    },
    //Page Data
    async getPickingDetails(pageload, dropPickedLPN) {
      let obj = {
        user_key: parseInt(this.userId),
        process_type: this.processType,
        id: parseInt(this.pickId),
      };
      let showMessage = false;
      let data = await shippingService.pickOrderLoad("post", obj, showMessage);
      if (data.message !== "NA") {
        this.headerData = data.header;
        this.lineData = data.lines;
        if (this.headerData !== "" && this.headerData !== undefined) {
          const splitStrings = this.headerData[0]?.customer_id.split(" ");
          this.mainCustID1 = splitStrings[0];
          this.mainCustID2 = splitStrings[1];
          if (this.headerData[0]?.drop_lpn !== undefined) {
            this.dropLPN = this.headerData[0]?.drop_lpn;
          }
          if (this.headerData[0]?.lpn !== undefined) {
            this.lpnDrop = this.headerData[0]?.lpn;
            if (dropPickedLPN) {
              this.onClickDropLPN();
            }
          }
        }
        if (this.lineData.length == 1 && pageload) {
          this.singleLineId = this.lineData[0].line_id;
          if (this.lineData[0].line_id !== 0 && this.lineData[0].allow_pick) {
            this.getPickingData(this.lineData[0].line_id);
          }
        } else {
          this.singleLineId = 0;
          this.$refs.refScanPartNum.focus();
        }
      }
    },
    //Vacate button click event
    async exitLineItem(item) {
      let obj = {
        user_key: parseInt(this.userId),
        process_type: this.processType,
        line_id: parseInt(item.line_id),
      };
      let showMessage = true;
      let data = await shippingService.pickExitLineSearch("post", obj, showMessage);
      if (data == "Line Assignment Vacated") {
        this.onPageLoad = false;
        this.dropPickedLPN = false;
        this.getPickingDetails(this.onPageLoad, this.dropPickedLPN);
      }
    },
    //Pick button click event
    async getPickingData(lineId) {
      if (lineId !== undefined) {
        this.singleLineId = lineId;
      }
      let obj = {
        user_id: parseInt(this.userId),
        process_type: this.processType,
        order_key: parseInt(this.pickId),
        part_num: "",
        line_id: parseInt(this.singleLineId),
      };
      let showMessage = false;
      let data = await shippingService.pickQueueFindPart("post", obj, showMessage);
      if (data.message !== "NA") {
        this.pickHeaderData = data?.header;
        if (this.pickHeaderData[0]?.ordered_qty == this.pickHeaderData[0]?.picked_qty) {
          this.pickDetailsDialog = false;
        } else {
          this.pickDetailsDialog = true;
          this.userAssignedLPN = this.pickHeaderData?.lpn;
          const splitStrings = this.pickHeaderData[0]?.customer_id.split(" ");
          this.customerId1 = splitStrings[0];
          this.customerId2 = splitStrings[1];
          this.isSerialized = this.pickHeaderData[0]?.IsSerialized;
          if (!this.isSerialized) {
            this.pickedPartNum = this.pickHeaderData[0]?.part_num;
          } else {
            this.pickedPartNum = "";
          }
          this.pickOptionsData = data?.options;
          this.pickBinData = data?.bins;
          if (this.pickBinData !== undefined && this.pickBinData !== null) {
            this.pickBinData = this.pickBinData.map((item) => ({
              ...item,
              active: false,
            }));
          }
          if (this.pickHeaderData[0]?.lpn !== "" && this.pickHeaderData[0]?.lpn !== undefined) {
            this.targetInTransitLPN = this.pickHeaderData[0]?.lpn;
            let obj = {
              userID: parseInt(this.userId),
              lpn: this.targetInTransitLPN,
            };
            let showMessage = false;
            let data = await shippingService.pickQueueLPNValidate("post", obj, showMessage);
            if (data.message !== "NA") {
              this.disableTargetLPN = true;
              this.disableUnitId = false;
              if (!this.isSerialized) {
                this.pickedValidateUnitID(this.pickedPartNum);
              } else {
                this.showUnitID = true;
                this.$refs.refUnitID.focus();
              }
            }
          }
        }
      }
    },
    //Pick button click event
    async getPartPickingData() {
      if (this.scanPartNum !== null && this.scanPartNum !== "") {
        let obj = {
          UserId: parseInt(this.userId),
          process_type: this.processType,
          order_key: parseInt(this.pickId),
          SearchStr: this.scanPartNum == null ? "" : this.scanPartNum,
        };
        let showMessage = false;
        let data = await shippingService.pickQueuePartSearch("post", obj, showMessage);
        if (data.message !== "NA") {
          this.partResultList = data.Resultset;
          this.showPartNumDetails = true;
        } else {
          this.partResultList = [];
          this.showPartNumDetails = false;
        }
      }
    },
    //Clearing part search results
    clearPartShip() {
      this.showPartDetails = false;
      this.scanPartNum = "";
      this.partResultList = [];
    },
    //Part Details on click of part number
    async partDetails(item) {
      let obj = {
        user_id: parseInt(this.userId),
        process_type: this.processType,
        order_key: parseInt(this.pickId),
        part_num: item.part_num,
        line_id: 0,
      };
      let showMessage = false;
      let data = await shippingService.pickQueueFindPart("post", obj, showMessage);
      if (data.message !== "NA") {
        this.pickHeaderData = data?.header;
        const splitStrings = this.pickHeaderData[0]?.customer_id.split(" ");
        this.customerId1 = splitStrings[0];
        this.customerId2 = splitStrings[1];
        this.pickOptionsData = data?.options;
        this.pickBinData = data?.bins;
        if (this.pickBinData !== undefined && this.pickBinData !== null) {
          this.pickBinData = this.pickBinData.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.pickDetailsDialog = true;
        if (this.pickHeaderData[0]?.lpn !== "" && this.pickHeaderData[0]?.lpn !== undefined) {
          this.targetInTransitLPN = this.pickHeaderData[0]?.lpn;
          this.userAssignedLPN = this.targetInTransitLPN;
          let obj = {
            userID: parseInt(this.userId),
            lpn: this.targetInTransitLPN,
          };
          let showMessage = false;
          let data = await shippingService.pickQueueLPNValidate("post", obj, showMessage);
          if (data.message !== "NA") {
            this.disableTargetLPN = true;
            this.disableUnitId = false;
            if (!this.isSerialized) {
              this.pickedValidateUnitID(this.pickedPartNum);
            } else {
              this.showUnitID = true;
              this.$refs.refUnitID.focus();
            }
          }
        }
      } else {
        this.clearPartShip();
      }
    },
    //Drop Off Check
    async dropOffCheck() {
      this.dropOffLPNDialog = true;
    },
    //Drop Off LPN
    lpnDropOff() {
      if (this.$refs.stagingLocationCheckForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let obj = {
          user_key: parseInt(this.userId),
          bin: this.stagingLocationLPN,
        };
        this.axios
          .post("sh/pick_queue_dropoff_lpn", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              let resData = JSON.parse(response.data.body.message);
              this.stagingLocationLPN = "";
              this.$refs.stagingLocationCheckForm.resetValidation();
              this.dropOffLPNDialog = false;
              this.resetFunction();
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              if (resData.Complete == 1) {
                this.$router.push(`/fulfillment-picking-queue`);
              }
            } else {
              this.stagingLocationLPN = "";
              this.$refs.stagingLocationCheckForm.resetValidation();
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            this.stagingLocationLPN = "";
            this.$refs.stagingLocationCheckForm.resetValidation();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Close Drop Off LPN Dialog
    closeDropLPNDialog() {
      this.dropOffLPNDialog = false;
      this.$refs.stagingLocationCheckForm.resetValidation();
    },
    //Short Pick
    async shortPick() {
      let obj = {
        user_id: parseInt(this.userId),
        process_type: this.processType,
        line_key: parseInt(this.pickId),
      };
      let showMessage = false;
      let data = await shippingService.pickQueueShortPick("post", obj, showMessage);
      if (data.message !== "NA") {
        this.closeDialog();
        this.resetFunction();
      }
    },
    //Bin button click
    pickBin(item) {
      if (item.lpn !== undefined && item.lpn !== "") {
        this.sourceBinLPN = item.lpn;
      } else {
        this.sourceBinLPN = item.bin;
      }
      this.disablePickBin = true;
      this.validateSourceBinLPN();
    },
    //Close button click
    closeDialog() {
      if (this.pickDetailsDialog) {
        this.pickDetailsDialog = false;
        this.pickHeaderData = [];
        this.pickOptionsData = [];
        this.pickBinData = [];
        this.refreshPickDetails();
        this.onPageLoad = false;
        this.dropPickedLPN = false;
        this.getPickingDetails(this.onPageLoad, this.dropPickedLPN);
      }
    },
    //Refresh in Picking Queue popup
    refreshPickDetails(submit) {
      this.targetInTransitLPN = "";
      this.disableTargetLPN = false;
      this.unitID = "";
      this.disableUnitId = true;
      this.sourceBinLPN = "";
      this.disableSourceBinLPN = true;
      this.disableSourceLPN = true;
      this.quantity = "";
      this.disableQuantity = true;
      this.disableSubmitPick = true;
      const pickData = this.pickBinData.find((element) => element.active == true);
      if (pickData) {
        pickData.active = false;
      }
      this.disablePickBin = true;
      this.showUnitID = false;
      this.showBinLPN = false;
      this.showQuantity = false;
      this.showSubmit = false;
      if (submit) {
        this.getPickingData(this.singleLineId);
      }
      this.$refs.lineNumForm.resetValidation();
      this.$refs.targetLPNForm.resetValidation();
    },
    //to check if its a number with hyphen of not
    isNumberWithHypen(event) {
      let value = Utility.isNumberWithHypen(event);
      return value;
    },
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    //Get next available LPN number
    async getLPNNumber() {
      let lpnData = await commonAPIService.getNextAvailableLPN("get", this.userId);
      if (lpnData.message !== "NA") {
        this.targetInTransitLPN = lpnData[0].lpn;
        let obj = {
          userID: parseInt(this.userId),
          lpn: this.targetInTransitLPN,
        };
        let showMessage = false;
        let data = await shippingService.pickQueueLPNValidate("post", obj, showMessage);
        if (data.message !== "NA") {
          this.disableTargetLPN = true;
          this.disableUnitId = false;
          if (!this.isSerialized) {
            this.pickedValidateUnitID(this.pickedPartNum);
          } else {
            this.showUnitID = true;
            this.$refs.refUnitID.focus();
          }
        }
      }
    },
    //Target LPN validation
    async validateTargetLPN() {
      if (this.$refs.targetLPNForm.validate()) {
        let obj = {
          userID: parseInt(this.userId),
          lpn: this.targetInTransitLPN,
        };
        let showMessage = false;
        let data = await shippingService.pickQueueLPNValidate("post", obj, showMessage);
        if (data.message !== "NA") {
          this.disableTargetLPN = true;
          this.disableUnitId = false;
          if (!this.isSerialized) {
            this.pickedValidateUnitID(this.pickedPartNum);
          } else {
            this.showUnitID = true;
            this.$refs.refUnitID.focus();
          }
        }
      }
    },
    //Unit ID validation
    async validateUnitID() {
      if (this.unitID !== "" && this.unitID !== undefined) {
        let obj = {
          userID: parseInt(this.userId),
          unit: this.unitID,
        };
        let showMessage = false;
        let data = await shippingService.pickQueueUnitIDValidate("post", obj, showMessage);
        if (data.message !== "NA") {
          this.disableUnitId = true;
          this.disableSourceBinLPN = !data[0].enable_bin;
          this.disableSubmitPick = !data[0].enable_submit;
          this.showBinLPN = true;
          if (data[0].enable_submit) {
            this.quantity = 1;
            this.showQuantity = true;
            this.showSubmit = true;
            this.disablePickBin = true;
            this.showBinLPN = true;
            this.submitPick();
          } else if (data[0].enable_bin) {
            this.showBinLPN = true;
            if (this.pickBinData.length > 0) {
              this.disablePickBin = false;
            } else {
              this.disablePickBin = true;
            }
          } else {
            this.quantity = 0;
          }
        } else {
          this.unitID = "";
          this.showUnitID = true;
          this.$refs.refUnitID.focus();
        }
      }
    },
    //Unit ID based on backend
    async pickedValidateUnitID(pickedPartNum) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        userID: parseInt(this.userId),
        unit: pickedPartNum,
      };
      this.axios
        .post("/sh/pick_queue_validate_unit", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let data = JSON.parse(response.data.body.message);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.disableUnitId = true;
            this.showUnitID = true;
            this.unitID = pickedPartNum;
            this.disableSourceBinLPN = !data[0].enable_bin;
            this.disableSubmitPick = !data[0].enable_submit;
            this.showBinLPN = true;
            if (data[0].enable_submit) {
              this.quantity = 1;
              this.showSubmit = true;
              this.showQuantity = true;
              this.submitPick();
            }
            if (data[0].enable_bin) {
              this.showBinLPN = true;
              if (this.pickBinData.length > 0) {
                this.disablePickBin = false;
              } else {
                this.disablePickBin = true;
              }
            } else {
              this.quantity = 0;
            }
          } else {
            this.unitID = "";
            this.showUnitID = true;
            this.$refs.refUnitID.focus();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.unitID = "";
          this.showUnitID = true;
          this.$refs.refUnitID.focus();
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          this.errorMessage = error.message;
        });
    },
    //Source Bin LPN validation
    async validateSourceBinLPN() {
      if (this.sourceBinLPN !== "" && this.sourceBinLPN !== undefined) {
        let obj = {
          userID: parseInt(this.userId),
          bin: this.sourceBinLPN,
        };
        let showMessage = false;
        let data = await shippingService.pickQueueSourceBinLPNValidate("post", obj, showMessage);
        if (data.message !== "NA") {
          this.disableSourceBinLPN = true;
          this.disableQuantity = data[0].enable_qty == 1 ? false : true;
          this.availableQty = data[0].avail_qty;
          this.showQuantity = true;
          this.quantity = 1;
          this.showSubmit = true;
          this.disableSubmitPick = false;
          this.disablePickBin = true;
          this.submitPick();
        }
      }
    },
    //Quantity validation
    validateQuantity() {
      if (this.quantity !== null && this.quantity !== "") {
        this.quantity = parseInt(this.quantity);
        if (this.quantity !== 0) {
          if (this.availableQty >= this.quantity) {
            this.disableQuantity = true;
            this.disableSubmitPick = false;
            this.showSubmit = true;
          } else {
            this.quantity = 0;
            this.showSubmit = false;
            let Alert = {
              type: "error",
              isShow: true,
              message: "Available quantity is " + this.availableQty,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Quantity should be greater than 0",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //Submit Pick
    async submitPick() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        userID: parseInt(this.userId),
        to_lpn: this.targetInTransitLPN,
        unit: this.unitID,
        source_binlpn: this.sourceBinLPN,
        qty: parseInt(this.quantity),
      };
      this.axios
        .post("sh/pick_queue_pick_submit", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let resData = JSON.parse(response.data.body.message);
            this.enableShortPick = resData[0].drop_lpn;
            this.enableDropLPN = resData[0].short_pick;
            this.userAssignedLPN = this.targetInTransitLPN;
            this.onPageLoad = false;
            if (resData[0].order_openqty == 0) {
              this.closeDialog();
              this.dropPickedLPN = true;
            } else {
              this.dropPickedLPN = false;
              let submit = true;
              this.refreshPickDetails(submit);
            }
            this.getPickingDetails(this.onPageLoad, this.dropPickedLPN);
            if (resData[0].note !== "" && resData[0].note !== undefined) {
              let Alert = {
                type: "success",
                isShow: true,
                message: resData[0].note,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.closeDialog();
          this.onPageLoad = true;
          this.dropPickedLPN = false;
          this.getPickingDetails(this.onPageLoad, this.dropPickedLPN);
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Vacate button in popup
    async vacateLine(item) {
      let obj = {
        user_key: parseInt(this.userId),
        process_type: this.processType,
        line_id: item.line_id,
      };
      let showMessage = true;
      let data = await shippingService.pickExitLineSearch("post", obj, showMessage);
      if (data == "Line Assignment Vacated") {
        this.closeDialog();
        this.onPageLoad = false;
        this.dropPickedLPN = false;
        this.getPickingDetails(this.onPageLoad, this.dropPickedLPN);
      }
    },
    //On click of Drop LPN from breadcrumb
    onClickDropLPN() {
      this.userAssignedLPN = this.lpnDrop;
      this.dropOffLPNDialog = true;
    },
    //to check if its a number with hyphen of not
    lpnNumFormat(event, num) {
      this.targetInTransitLPN = Utility.formatLPNNumber(event, num);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
