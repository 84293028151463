import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  getInitalList(call, userId) {
    return this.getData(`pi/scope_manager_dropdowns?user_id=${userId}`, call);
  },
  postSearch(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_search`, call, obj, showMessage);
  },
  postScopeEdit(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_edit_scope`, call, obj, showMessage);
  },
  getAddData(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_new`, call, obj, showMessage);
  },
  postValidate(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_validate_part`, call, obj, showMessage);
  },
  postValidateBin(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_validate_bin`, call, obj, showMessage);
  },
  submitScope(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_add_new`, call, obj, showMessage);
  },
  updateScope(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_update`, call, obj, showMessage);
  },
  getScopeDetails(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_get_scope`, call, obj, showMessage);
  },
  postPrepAction(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_prep`, call, obj, showMessage);
  },
  postScanAction(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_scan`, call, obj, showMessage);
  },
  postReconileAction(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_reconcile`, call, obj, showMessage);
  },
  postAbortAction(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_abort`, call, obj, showMessage);
  },
  postDeleteAction(call, obj, showMessage) {
    return this.getData(`pi/scope_manager_delete_scope`, call, obj, showMessage);
  },

  //Scan Units
  getActiveScopes(call, userId) {
    return this.getData(`pi/get_active_scopes?UserId=${userId}`, call);
  },
  postActiveScopesSelected(call, obj, showMessage) {
    return this.getData(`pi/scan_init`, call, obj, showMessage);
  },
  postActiveScopesSubmit(call, obj, showMessage) {
    return this.getData(`pi/scan_pi`, call, obj, showMessage);
  },
  postReconcileBin(call, obj, showMessage) {
    return this.getData(`pi/scan_bin_quarantine`, call, obj, showMessage);
  },
  postBinCounts(call, obj, showMessage) {
    return this.getData(`pi/scan_bin_counts`, call, obj, showMessage);
  },
  postSessionsCounts(call, obj, showMessage) {
    return this.getData(`pi/scan_session_counts`, call, obj, showMessage);
  },
  postScanBinInfo(call, obj, showMessage) {
    return this.getData(`pi/scan_bin_info`, call, obj, showMessage);
  },
  postScanUnitsSubmit(call, obj, showMessage) {
    return this.getData(`pi/scan_pi_submit`, call, obj, showMessage);
  },

  //Remove units from scan log
  postClearUnitScanLog(call, obj, showMessage) {
    return this.getData("pi/clear_unit_scan_log", call, obj, showMessage);
  },
  postReportClearLog(call, obj, showMessage) {
    return this.getData("pi/report_clear_log", call, obj, showMessage);
  },
  // Bin Tabular Data
  getBinTabularData(call, obj, showMessage) {
    return this.getData(`pi/report_bin_counts`, call, obj, showMessage);
  },
  getBinDetails(call, obj, showMessage) {
    return this.getData(`pi/report_bin_details`, call, obj, showMessage);
  },
  //Clear Bins From Scan
  postClearBin(call, obj, showMessage) {
    return this.getData("pi/clear_bin", call, obj, showMessage);
  },
  // Scan part
  postBinPart(call, obj, showMessage) {
    return this.getData("pi/scanned_bin_by_part", call, obj, showMessage);
  },

  // MIsisng inventory
  getMissingControl(call, obj, showMessage) {
    return this.getData("pi/report_missing", call, obj, showMessage);
  },

  //Print Audit Sheets
  postPrintAuditSheets(call, obj, showMessage) {
    return this.getData("pi/print_audit_sheets", call, obj, showMessage);
  },

  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res?.data?.body?.message != undefined || res?.data?.body?.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data?.body?.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: this.isJson(res?.data?.body?.message) ? JSON.parse(res?.data?.body?.message).Message : "" || res?.data?.body?.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        if (this.isJson(res?.data?.body?.message)) return JSON.parse(res?.data?.body?.message);
        else return `{"Status":200,"Message":"${res?.data?.body?.message}"}`;
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else if (res?.data?.body?.message == null) {
      if (res?.data?.body !== null) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return res?.data?.body;
      } else {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      }
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res?.data?.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
};
