import EncryptUtility from "../../utility/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/addFav";
import adminService from "../../admin/js/admin-service";
import CommonDialog from "@/olp-components/common/common-dialog.vue";
import utility from "@/shared/utility";
import FilterComp from "@/olp-components/common/filter-comp.vue";
import { bus } from "@/main";
export default {
  data() {
    return {
      actionList: [],
      cartListData:[],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      backSlash: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      cardsArray: [],
      rejectComments: "",
      searchFilter:"",
      max100Rule: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(?=.*\S).+$/.test(v) || "Please enter at least one text or number",
        (v) =>
          (v || "").length <= 100 ||
          "Max Length must be 100 characters or less",
      ],
      headerCart: [
        {
          text: "Business",
          value: "business",
          class: "primary customwhite--text",
          width: "15%",
        },
        {
          text: "Created Date",
          value: "created_date",
          class: "primary customwhite--text",
          width: "24%",
        },
        {
          text: "Modified Date",
          value: "modified_date",
          class: "primary customwhite--text",
          width: "13%",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
          width: "13%",
        },
      ],
      headersSpecificData: [
        {
          text: "Part Number",
          value: "part_num",
        },
        {
          text: "Description",
          value: "part_desc",
        },
        { text: "Qty", value: "qty" },
        {
          text: "Available Qty",
          value: "available_qty",
        },
      ],
      cartList: [],
      showDialogCart:false,
      message:"Are you sure you want to clear Cart?",
      arrayData:[],
      selectedCardId:1,
      showOrderDetails: false,
      showRejectDialog: false,
      partList: [],
      tempCartList:[],
      specificData:"",
      uniqueBusinessList:[],
      orderId: "",
      totalRecords:0,
      addressObj: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        pinCode: "",
        country: "",
        contactName: "",
        phone1: "",
        email: "",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  async mounted() {
    this.getDataOnLoad();
  },
  methods: {
    getDataOnLoad() {
      this.getTableData();
    },
    async getTableData() {
      let responseData = await adminService.getCartList(
        "get",
        this.userId,
        0,
        false
      );
      if(responseData!="NA"){
      responseData?.forEach((element) => {
        element.created_date = utility.convertESTToLocal(element.created_date);
        element.modified_date = utility.convertESTToLocal(element.modified_date);
      });
      this.cartList = responseData;
      this.tempCartList=responseData
      this.totalRecords=this.cartList?.length
      this.filterArrayData();}
      else{
        this.cartList=[];
        this.totalRecords=0;
      }
    },
    async getSpecificOrderDetails(item) {
      this.showOrderDetails=true
      this.partList=item.cart_line_details
      this.specificData=item
      
    },
    onClickClearCart(){
      this.showDialogCart=true;
    },
    onClickCloseDialog(){
      this.showDialogCart=false;
      this.specificData=""
    },
    async clearCart(){
      let updateObj = {
        user_id: this.userId,
        pass: 4,
        cart_key: this.cartList[0].cart_id,
        cartlines: [],
        bu_key: EncryptUtility.localStorageDecrypt("olpBusinessSelected"),
      };
   
      let submitObj = { json: JSON.stringify(updateObj) };
      let res = await adminService.postCartUpdate("post", submitObj, true);
      if(res=="Cart removed"){
        this.showDialogCart=false;
        bus.$emit("cart","");
        bus.$emit("refreshProduct",true)
        this.showOrderDetails=false
        this.getTableData();
      }
    },
    filterArrayData() {
      this.uniqueBusinessList = [...new Map(this.cartList.map((item) => [item["business"], item.business])).values()];
      this.arrayData = [
        { id: 1, label: "Business", array: this.uniqueBusinessList },
      ];
    },
    async filteredValues(value, type) {
      this.selectedFilterName = value;
      this.selectedFilterId = type;
      const filters = {
        0: () => this.tempCartList,
        1: (value) => this.tempCartList.filter((element) => element.business == value),
      };
      this.filterValueUpdate(filters[type](value));
    },
    filterValueUpdate(data) {
      this.totalRecords = data.length;
      this.cartList = data;
    },
   async goToCheckout() {
      EncryptUtility.localStorageEncrypt( "olpBusinessSelected",String(this.specificData.bu_key));
      EncryptUtility.localStorageEncrypt( "olpBusinessName",this.specificData.business);
      this.showOrderDetails = true;
      let resp = await adminService.getCartList("get",this.userId,this.specificData.bu_key,false)
      if (resp != "NA") {
        this.cartListData = resp?.map((x) => ({
          cart_id:x.cart_id,
          part_id: x.part_key,
          Part_Number: x.part_num,
          img: x.img,
          Description: x.part_desc,
          qty: x.qty,
          available_qty: x.available_qty,
          cart_line_key: x.cart_line_id,
        }));
        bus.$emit("cart", this.cartListData);
        resp[0]?.cart_id
          ? (this.cartId = resp[0]?.cart_id)
          : (this.cartId = "");
      } else {
        bus.$emit("cart", "");
        this.cartListData = [];
      }
      this.$router.push({
        name: "orderCheckOut",
      });
    },
  },

  components: {
    breadcrumbComp,
    CommonDialog,
    FilterComp
  },
};
