import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import AddFavourite from "../../utility/js/addFav";
import customerService from ".././js/customer-list-service.js";
import Utility from "../../../shared/utility";
export default {
  data: () => ({
    max10Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 10 || "Max Length must be 10 characters or less"],
    stateRule: [(v) => !!v || "Field is required", (v) => /^[a-zA-Z0-9 ]+$/.test(v) || "special character are not allowed"],
    required: [(v) => !!v || "Field is required"],
    emailRule: [(v) => /.+@.+\..+/.test(v) || "Enter valid Email"],
    zipcode: [(v) => /^\d{5}(-\d{4})?$/.test(v) || "Enter valid Postal"],
    postalCodeNonUS: [(v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Invalid Postal Code"],
    backSlash: true,
    refresh: true,
    userId: EncryptUtility.localStorageDecrypt("userID"),
    actionList: [],
    mainPage: "",
    favouriteList: [],
    pageUrl: "",
    subPage: "",
    showAdd: false,
    subPageUrl: "",
    backSlashParent: true,
    totalRecords: 0,
    IsActive: true,
    customerSearch: "",
    customerId: 0,
    excelName: "",
    disableValidate: true,
    showCustomerDialog: false,
    editShowCustomerDialog: false,
    title: "",
    lastName: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    selectedState: "",
    postal: "",
    selectedCountry: "",
    phone: "",
    phone2: "",
    fax: "",
    cell: "",
    email: "",
    sh_title: "",
    sh_lastName: "",
    sh_company: "",
    sh_address1: "",
    sh_address2: "",
    sh_city: "",
    sh_selectedState: "",
    sh_postal: "",
    sh_selectedCountry: "USA",
    sh_phone: "",
    sh_phone2: "",
    sh_fax: "",
    sh_cell: "",
    sh_email: "",
    editcustomerNum: "",
    editTitle: "",
    editLastName: "",
    editCompany: "",
    editAddress1: "",
    editAddress2: "",
    editCity: "",
    editselectedState: "",
    editPostal: "",
    editSelectedCountry: "",
    editPhone: "",
    editPhone2: "",
    editFax: "",
    editCell: "",
    editEmail: "",
    sh_edittitle: "",
    sh_editlastName: "",
    sh_editcompany: "",
    sh_editaddress1: "",
    sh_editaddress2: "",
    sh_editcity: "",
    sh_editselectedState: "",
    sh_editpostal: "",
    sh_editselectedCountry: "",
    sh_editphone: "",
    sh_editphone2: "",
    sh_editfax: "",
    sh_editcell: "",
    sh_editemail: "",
    copyBillToData: false,
    copyBillToAddData: false,
    deleteCustomerDialog: false,
    editIsActive: false,
    editDisable: true,
    exportDisable: true,
    json_fields: {
      CustomerNumber: "custno",
      Company: "Company",
      Address: "address1",
      Address2: "address2",
      City: "city",
      State: "state",
      ZIP: "zip",
      Country: "country",
      Phone1: "phone1",
      Status: "active",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    headers: [
      {
        text: "Customer No#",
        value: "custno",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "Company",
        value: "Company",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Address",
        value: "address1",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "Address 2",
        value: "address2",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "City",
        value: "city",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "State",
        value: "state",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "ZIP",
        value: "zip",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Country",
        value: "country",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "Phone 1",
        value: "phone1",
        class: "primary customwhite--text",
        width: "10%",
      },

      {
        text: "Status",
        value: "active",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Actions",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
        width: "5%",
      },
    ],
    customerList: [],
    addCustomerDialog: false,
    panel: 0,
    editCustomerBillPanel: true,
    editCustomerShipPanel: false,
    addCustomerNum: "",
    selectedProject: "",
    editSelectedProject: "",
    projectData: [],
    custNumValid: false,
    showCountryDrop: false,
    showEditCountryDrop: false,
    showShipCountryDrop: false,
    showShipEditCountryDrop: false,
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    let countryStateData = await customerService.countryList(this.userId, "", 1, "get");
    this.selectedCountry = "USA";
    this.countryData = JSON.parse(countryStateData).CountryInfo;
    let response = await customerService.getProject(this.userId);
    this.projectData = JSON.parse(response).ProjectInfo;
  },
  mounted() {},
  methods: {
    //to check if its a number of not
    isNumber(event, loc) {
      if (loc == "number") {
        let value = Utility.isNumber(event);
        return value;
      } else {
        let value = Utility.isNumberWithHypen(event);
        return value;
      }
    },
    //Reset Function
    resetFunction() {
      this.customerSearch = "";
      this.IsActive = true;
      this.customerList = [];
      this.totalRecords = 0;
    },
    //Adding a new customer
    async addCustomer() {
      this.selectedState = "";
      this.selectedCountry = "USA";
      let data1 = await customerService.countryList(this.userId, this.selectedCountry, 2, "get");
      this.stateData = JSON.parse(data1).StateInfo;
      if (this.stateData.length !== 0) {
        this.showCountryDrop = true;
      } else {
        this.showCountryDrop = false;
      }
      this.sh_selectedState = "";
      this.sh_selectedCountry = "USA";
      let data2 = await customerService.countryList(this.userId, this.sh_selectedCountry, 2, "get");
      this.stateDataShip = JSON.parse(data2).StateInfo;
      if (this.stateDataShip.length !== 0) {
        this.showShipCountryDrop = true;
      } else {
        this.showShipCountryDrop = false;
      }
      this.addCustomerDialog = true;
    },
    //Editing the Customer
    async editItem(item) {
      this.editShowCustomerDialog = true;
      this.customerId = item.cust_id;
      let response = await customerService.getCustomerDataById(item.cust_id, this.userId);
      if (response.message !== "NA" && response !== 400) {
        let data = JSON.parse(response);
        this.editcustomerNum = data[0].custno;
        this.editSelectedProject = data[0].proj_key;
        this.editTitle = data[0].title;
        this.editLastName = data[0].last;
        this.editCompany = data[0].company;
        this.editAddress1 = data[0].address1;
        this.editAddress2 = data[0].address2;
        this.editCity = data[0].city;
        this.editSelectedCountry = data[0].country;
        this.countrySelected();
        this.editselectedState = data[0].state;
        this.editPostal = data[0].zip;
        this.editPhone = data[0].phone1;
        this.editPhone2 = data[0].phone2;
        this.editFax = data[0].fax;
        this.editCell = data[0].cell;
        this.editEmail = data[0].email;
        this.sh_edittitle = data[0].sh_title;
        this.sh_editlastName = data[0].sh_last;
        this.sh_editcompany = data[0].sh_company;
        this.sh_editaddress1 = data[0].sh_address1;
        this.sh_editaddress2 = data[0].sh_address2;
        this.sh_editcity = data[0].sh_city;
        this.sh_editselectedCountry = data[0].sh_country;
        this.countrySelectedShipping();
        this.sh_editselectedState = data[0].sh_state;
        this.sh_editpostal = data[0].sh_zip;
        this.sh_editphone = data[0].sh_phone1;
        this.sh_editphone2 = data[0].sh_phone2;
        this.sh_editfax = data[0].sh_fax;
        this.sh_editcell = data[0].sh_cell;
        this.sh_editemail = data[0].sh_email;
        this.editIsActive = data[0].active == 1 ? true : false;
      }
    },
    //Adding bill to shipping
    addCopyBillToShipTo() {
      if (this.copyBillToAddData) {
        this.sh_title = this.title;
        this.sh_lastName = this.lastName;
        this.sh_company = this.company;
        this.sh_address1 = this.address1;
        this.sh_address2 = this.address2;
        this.sh_city = this.city;
        this.sh_selectedState = this.selectedState;
        this.sh_postal = this.postal;
        this.sh_selectedCountry = this.selectedCountry;
        this.sh_phone = this.phone;
        this.sh_phone2 = this.phone2;
        this.sh_fax = this.fax;
        this.sh_cell = this.cell;
        this.sh_email = this.email;
      } else {
        this.sh_title = "";
        this.sh_lastName = "";
        this.sh_company = "";
        this.sh_address1 = "";
        this.sh_address2 = "";
        this.sh_city = "";
        this.sh_selectedState = "";
        this.sh_postal = "";
        this.sh_selectedCountry = "";
        this.sh_phone = "";
        this.sh_phone2 = "";
        this.sh_fax = "";
        this.sh_cell = "";
        this.sh_email = "";
      }
    },
    //Copying bill to shipping
    copyBillToToShipTo() {
      if (this.copyBillToData) {
        this.sh_edittitle = this.editTitle;
        this.sh_editlastName = this.editLastName;
        this.sh_editcompany = this.editCompany;
        this.sh_editaddress1 = this.editAddress1;
        this.sh_editaddress2 = this.editAddress2;
        this.sh_editcity = this.editCity;
        this.sh_editselectedState = this.editselectedState;
        this.sh_editpostal = this.editPostal;
        this.sh_editselectedCountry = this.editSelectedCountry;
        this.sh_editphone = this.editPhone;
        this.sh_editphone2 = this.editPhone2;
        this.sh_editfax = this.editFax;
        this.sh_editcell = this.editCell;
        this.sh_editemail = this.editEmail;
      } else {
        this.sh_edittitle = "";
        this.sh_editlastName = "";
        this.sh_editcompany = "";
        this.sh_editaddress1 = "";
        this.sh_editaddress2 = "";
        this.sh_editcity = "";
        this.sh_editselectedState = "";
        this.sh_editpostal = "";
        this.sh_editselectedCountry = "";
        this.sh_editphone = "";
        this.sh_editphone2 = "";
        this.sh_editfax = "";
        this.sh_editcell = "";
        this.sh_editemail = "";
      }
    },
    //Validate button click event
    async onClickValidate() {
      if (this.$refs.CustomerNumForm.validate()) {
        let validateData = await customerService.validateCustomerDataById(this.userId, this.addCustomerNum);
        if (validateData.message != "NA") {
          this.custNumValid = false;
          this.disableValidate = false;
        }
      }
    },
    //Submit New user details
    async submitAddCustomer() {
      if (this.$refs.AddressForm.validate()) {
        let custObj = {
          cust_id: 0,
          proj_key: this.selectedProject,
          title: this.title,
          first: "",
          custno: this.addCustomerNum,
          last: this.lastName,
          company: this.company,
          address1: this.address1,
          address2: this.address2,
          city: this.city,
          state: this.selectedState,
          zip: this.postal,
          country: this.selectedCountry,
          phone1: this.phone,
          phone2: this.phone2,
          cell: this.cell,
          email: this.email,
          UserId: this.userId,
          fax: this.fax,
          sh_title: this.sh_title,
          sh_last: this.sh_lastName,
          sh_company: this.sh_company,
          sh_address1: this.sh_address1,
          sh_address2: this.sh_address2,
          sh_city: this.sh_city,
          sh_state: this.sh_selectedState,
          sh_zip: this.sh_postal,
          sh_country: this.sh_selectedCountry,
          sh_phone1: this.sh_phone,
          sh_phone2: this.sh_phone2,
          sh_fax: this.sh_fax,
          sh_cell: this.sh_cell,
          sh_email: this.sh_email,
        };
        let obj = {
          CuJson: JSON.stringify(custObj),
          pass: 2, //Do not remove
        };
        let showMessage = true;
        let response = await customerService.postAddCustomer("post", obj, showMessage);
        if (response !== null && response !== undefined) {
          this.$refs.AddressForm.resetValidation();
          this.$refs.CustomerNumForm.resetValidation();
          if (this.customerList?.length > 0) {
            this.getCustomerList();
          }
          this.addCustomerDialog = false;
          this.closeAddCustomerDialog();
        }
      }
    },
    //Update customer details
    async submitEditCustomer() {
      if (this.$refs.AddressEditForm.validate()) {
        let custObj = {
          cust_id: this.customerId,
          proj_key: this.editSelectedProject,
          title: this.editTitle,
          first: "",
          custno: this.editcustomerNum,
          last: this.editLastName,
          company: this.editCompany,
          address1: this.editAddress1,
          address2: this.editAddress2,
          city: this.editCity,
          state: this.editselectedState,
          zip: this.editPostal,
          country: this.editSelectedCountry,
          phone1: this.editPhone,
          phone2: this.editPhone2,
          cell: this.editCell,
          email: this.editEmail,
          UserId: this.userId,
          fax: this.editFax,
          sh_title: this.sh_edittitle,
          sh_last: this.sh_editlastName,
          sh_company: this.sh_editcompany,
          sh_address1: this.sh_editaddress1,
          sh_address2: this.sh_editaddress2,
          sh_city: this.sh_editcity,
          sh_state: this.sh_editselectedState,
          sh_zip: this.sh_editpostal,
          sh_country: this.sh_editselectedCountry,
          sh_phone1: this.sh_editphone,
          sh_phone2: this.sh_editphone2,
          sh_fax: this.sh_editfax,
          sh_cell: this.sh_editcell,
          sh_email: this.sh_editemail,
          active: this.editIsActive == true ? 1 : 0,
        };
        let obj = {
          CuJson: JSON.stringify(custObj),
          pass: 1, //Do not remove
        };
        let showMessage = true;
        let response = await customerService.postAddCustomer("post", obj, showMessage);
        if (response !== null && response !== undefined) {
          this.$refs.AddressEditForm.resetValidation();
          this.getCustomerList();
          this.editShowCustomerDialog = false;
          this.editDisable = true;
        }
      }
    },
    //To get sales order list based on the project
    async getCustomerList() {
      this.customerList = [];
      this.totalRecords = 0;
      let obj = {
        SearchString: this.customerSearch,
        strActive: this.IsActive == true ? 1 : 0,
        UserId : this.userId
      };
      let response = await customerService.postCustomerListSearch("post", obj);
      if (response.message !== "NA" && response !== 400) {
        this.customerList = JSON.parse(response);
        this.totalRecords = this.customerList.length;
      }
      if (this.totalRecords == 0) {
        this.exportDisable = true;
      } else {
        this.exportDisable = false;
      }
    },
    //Closing Add customer popup
    closeAddCustomerDialog() {
      this.title = "";
      this.selectedProject = "";
      this.lastName = "";
      this.company = "";
      this.address1 = "";
      this.address2 = "";
      this.city = "";
      this.selectedState = "";
      this.postal = "";
      this.selectedCountry = "USA";
      this.phone = "";
      this.phone2 = "";
      this.fax = "";
      this.cell = "";
      this.email = "";
      this.sh_title = "";
      this.sh_lastName = "";
      this.sh_company = "";
      this.sh_address1 = "";
      this.sh_address2 = "";
      this.sh_city = "";
      this.sh_selectedState = "";
      this.sh_postal = "";
      this.sh_selectedCountry = "USA";
      this.sh_phone = "";
      this.sh_phone2 = "";
      this.sh_fax = "";
      this.sh_cell = "";
      this.sh_email = "";
      this.addCustomerNum = "";
      this.showCountryDrop = false;
      this.showShipCountryDrop = false;
      this.disableValidate = true;
      this.addCustomerDialog = false;
      this.copyBillToAddData = false;
      this.$refs.AddressForm.resetValidation();
      this.$refs.CustomerNumForm.resetValidation();
    },
    //Closing Edit customer popup
    closeEditCustomerDialog() {
      this.editShowCustomerDialog = false;
      this.editTitle = "";
      this.editSelectedProject = "";
      this.editLastName = "";
      this.editCompany = "";
      this.editAddress1 = "";
      this.editAddress2 = "";
      this.editCity = "";
      this.editselectedState = "";
      this.editPostal = "";
      this.editSelectedCountry = "USA";
      this.editPhone = "";
      this.editPhone2 = "";
      this.editFax = "";
      this.editCell = "";
      this.editEmail = "";
      this.sh_edittitle = "";
      this.sh_editlastName = "";
      this.sh_editcompany = "";
      this.sh_editaddress1 = "";
      this.sh_editaddress2 = "";
      this.sh_editcity = "";
      this.sh_editselectedState = "";
      this.sh_editpostal = "";
      this.sh_editselectedCountry = "USA";
      this.sh_editphone = "";
      this.sh_editphone2 = "";
      this.sh_editfax = "";
      this.sh_editcell = "";
      this.sh_editemail = "";
      this.editDisable = true;
      this.copyBillToData = false;
      this.showEditCountryDrop = false;
      this.showShipEditCountryDrop = false;
      this.$refs.AddressEditForm.resetValidation();
    },
    //Delete the reason
    deleteCustomer() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("/cu/delete_customer_by_id?CustId=" + this.customerId)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.deleteCustomerDialog = false;
            this.editShowCustomerDialog = false;
            this.getCustomerList();
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.getCustomerList();
            this.deleteCustomerDialog = false;
            this.editShowCustomerDialog = false;
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Once country selected
    async countrySelected() {
      if (this.editShowCustomerDialog && this.editSelectedCountry !== "") {
        this.editselectedState = "";
        this.editPostal = "";
        this.$refs.AddressEditForm.resetValidation();
        let countryStateData = await customerService.countryList(this.userId, this.editSelectedCountry, 2, "get");
        this.stateData = JSON.parse(countryStateData).StateInfo;
        if (this.stateData.length !== 0) {
          this.showEditCountryDrop = true;
        } else {
          this.showEditCountryDrop = false;
        }
      } else if (this.addCustomerDialog && this.selectedCountry !== "") {
        this.selectedState = "";
        this.postal = "";
        this.$refs.AddressForm.resetValidation();
        let countryStateData = await customerService.countryList(this.userId, this.selectedCountry, 2, "get");
        this.stateData = JSON.parse(countryStateData).StateInfo;
        if (this.stateData.length !== 0) {
          this.showCountryDrop = true;
        } else {
          this.showCountryDrop = false;
        }
      }
    },
    //Country selected in shipping
    async countrySelectedShipping() {
      if (this.editShowCustomerDialog && this.sh_editselectedCountry !== "") {
        this.sh_editselectedState = "";
        this.sh_editpostal = "";
        this.$refs.AddressEditForm.resetValidation();
        let countryStateData = await customerService.countryList(this.userId, this.sh_editselectedCountry, 2, "get");
        this.stateDataShip = JSON.parse(countryStateData).StateInfo;
        if (this.stateDataShip.length !== 0) {
          this.showShipEditCountryDrop = true;
        } else {
          this.showShipEditCountryDrop = false;
        }
      } else if (this.addCustomerDialog && this.sh_selectedCountry !== "") {
        this.sh_selectedState = "";
        this.sh_postal = "";
        this.$refs.AddressForm.resetValidation();
        let countryStateData = await customerService.countryList(this.userId, this.sh_selectedCountry, 2, "get");
        this.stateDataShip = JSON.parse(countryStateData).StateInfo;
        if (this.stateDataShip.length !== 0) {
          this.showShipCountryDrop = true;
        } else {
          this.showShipCountryDrop = false;
        }
      }
    },
    //Add or remove Favourtite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Excel download file name
    fileName() {
      this.excelName = "Customer_List_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //To check if its a number of not
    postalCodeFormatPostal(event, num) {
      this.postal = Utility.formatPostalCode(event, num);
    },
    //To check if its a number of not
    postalCodeFormatShipPostal(event, num) {
      this.sh_postal = Utility.formatPostalCode(event, num);
    },
    //To check if its a number of not
    postalCodeFormatEditPostal(event, num) {
      this.editPostal = Utility.formatPostalCode(event, num);
    },
    //To check if its a number of not
    postalCodeFormatShipEditPostal(event, num) {
      this.sh_editpostal = Utility.formatPostalCode(event, num);
    },
  },
  components: {
    breadcrumbComp,
  },
};
