import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";

export default {
  data() {
    return {
      name: "newRoleAdd",
      max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character"],
      max500Rule: [(v) => (v || "").length <= 500 || "Max Length of 500 character"],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      role: false,
      txtDescription: true,
      mainPage: "",
      backSlash: true,
      openIds: [],
      open: [],
      backSlashParent: true,
      openMenus: [],
      subPage: "",
      subPageUrl: "",
      pageUrl: "",
      appPageName: "",
      roleName: "",
      roleMenuData: [],
      selectedCheckbox: [],
      roleData: [],
      selectedIds: [],
      projectLists: [],
      projectSelected: false,
      addRoleBtn: true,
      businessLists: [],
      allProjectSelect: false,
      allBusinessSelect: false,
      allProjects: [],
      selProjects: [],
      selBusiness: [],
      allIds: [],
      list: [
        { name: "Menu Selection", active: false },
        { name: "Project and Business Selection", active: false },
      ],
      finalSelList: [],
      IsActive: true,
      isActive: 1,
      menuSelected: false,
      refresh: true,
      projectBusValid: false,
      searchMaskedPermission: "",
      maskedPermissionList: [],
      finalPermList: [],
      headersMaskedPermissions: [
        { text: "Action", align: "start", value: "Action", class: "primary customwhite--text" },
        { text: "FriendlyName", value: "FriendlyName", class: "primary customwhite--text" },
        { text: "Description", value: "Description", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.subPageUrl = data.subPageUrl;
    this.appPageName = data.appPageName;
    this.getMaskedPermissionData();
    this.getRoleMenuData();
  },

  mounted() {
    this.selectInstaller = parseInt(EncryptUtility.localStorageDecrypt("installId"));
    this.getProjectList();
  },
  methods: {
    //get Role Menu data
    getRoleMenuData() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.axios
        .get("ap/rolemenu_list?UserId=" + this.userId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.roleMenuData = responseData;
            this.getAllIds();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Validate Role Name
    roleValid() {
      this.axios
        .get("ap/role_validate?user_id=" + this.userId + "&rolename=" + this.roleName + "&installid=" + this.selectInstaller)
        .then((response) => {
          if (response.status == 200) {
            this.txtDescription = false;
            this.role = true;
          } else {
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Add Role
    addRole() {
      this.getSelectedIds();
      this.selProjects = [...new Set(this.selProjects)];
      this.selBusiness = this.selBusiness.filter((obj) => this.selProjects.includes(obj.ProjectId));
      this.selProjects = this.businessLists.map((item) => item.ProjectId);
      if (this.selectInstaller !== null || this.selectInstaller !== undefined || this.selectInstaller !== "") {
        if (this.roleName.length > 0 && this.roleName !== undefined) {
          if (this.selectedIds?.length > 0 == true) {
            if (this.selBusiness.length !== 0) {
              this.addRoleBtn = false;
              if (this.selProjects.length !== 0) {
                if (this.selBusiness.length !== 0) {
                  this.projectBusValid = this.selProjects.every((projectId) => this.selBusiness.some((project) => project.ProjectId === projectId));
                  if (this.projectBusValid) {
                    this.finalSelList = [];
                    this.finalSelList = this.selBusiness.map((obj) => {
                      return {
                        ProjectId: obj.ProjectId,
                        BusinessId: obj.BusinessId,
                        IsActive: this.isActive,
                      };
                    });
                    //to check form
                    if (this.$refs.addRoleForm.validate()) {
                      let LoaderDialog = {
                        visible: true,
                        title: "Please Wait...",
                      };
                      let rpbObject = JSON.stringify({
                        isAllProjects: parseInt(this.allProjectSelect == true ? 1 : 0),
                        isAllBusiness: parseInt(this.allBusinessSelect == true ? 1 : 0),
                        RPBInfo: this.finalSelList,
                      });
                      if (this.finalSelList == null || this.finalSelList == undefined || this.finalSelList == "") {
                        let Alert = {
                          type: "error",
                          isShow: true,
                          message: "Please select any business",
                        };
                        this.$store.commit("ConfigModule/Alert", Alert);
                      }
                      // Data for post
                      let groupdata = {
                        RoleName: this.roleName,
                        Description: this.description,
                        InstallId: this.selectInstaller,
                        CreatedBy: this.userId,
                        MenuIds: this.selectedIds,
                        IsActive: this.IsActive,
                        RPBJson: rpbObject,
                        MaskingJson: JSON.stringify(this.finalPermList),
                      };
                      // API call
                      this.axios
                        .post("/ap/role_upsert", groupdata)
                        .then((response) => {
                          if (response.status == 200 || response.status == 204) {
                            LoaderDialog.visible = false;
                            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                            let Alert = {
                              type: "success",
                              isShow: true,
                              message: response.data.body.message,
                            };
                            this.$store.commit("ConfigModule/Alert", Alert);
                            this.reset();
                            this.$router.push("/app-role");
                          } else {
                            LoaderDialog.visible = false;

                            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                            let Alert = {
                              type: "error",
                              isShow: true,
                              message: response.data.message,
                            };
                            this.selectedCheckbox = [];
                            this.$store.commit("ConfigModule/Alert", Alert);
                          }
                          LoaderDialog.visible = false;
                          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                        })
                        .catch((error) => {
                          LoaderDialog.visible = false;
                          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                          let Alert = {
                            type: "error",
                            isShow: true,
                            message: Utility.apiError(error),
                          };
                          this.$store.commit("ConfigModule/Alert", Alert);
                          this.$store.commit("ConfigModule/Alert", Alert);
                          this.errorMessage = error.message;
                          this.selectedCheckbox = [];
                        });
                    }
                  } else {
                    let Alert = {
                      type: "error",
                      isShow: true,
                      message: "Please select any Business",
                    };
                    this.$store.commit("ConfigModule/Alert", Alert);
                  }
                } else {
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: "Please select any Business",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
              } else {
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: "Please select any Project",
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "Please fill the Project/Business field",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          } else {
            this.addRoleBtn = true;
            let Alert = {
              type: "error",
              isShow: true,
              message: "Please select any roles",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        } else {
          this.addRoleBtn = true;
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please provide any Role Name",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },

    toggleChildren(item, state) {
      if (item.children) {
        item.children.forEach((child) => {
          child.selected = state;
          this.toggleChildren(child, state);
        });
      }
      this.updateParentState(item);
    },
    getSelectedIds() {
      this.selectedIds = [];
      this.collectSelectedIds(this.roleMenuData, this.selectedIds);
    },
    updateParentState(item) {
      let parent = this.findParent(this.roleMenuData, item);
      while (parent) {
        if (parent.children.every((child) => !child.selected)) {
          parent.selected = false;
        }
        parent = this.findParent(this.roleMenuData, parent);
      }
    },
    collectSelectedIds(items, selectedIds) {
      items.forEach((item) => {
        if (item.selected) {
          selectedIds.push(item.id);
        }
        if (item.children) {
          this.collectSelectedIds(item.children, selectedIds);
        }
      });
    },

    isParentDisabled(item) {
      let parent = this.findParent(this.roleMenuData, item);
      while (parent) {
        if (!parent.selected) {
          return true;
        }
        parent = this.findParent(this.roleMenuData, parent);
      }
      return false;
    },
    findParent(items, child) {
      for (const item of items) {
        if (item.children && item.children.includes(child)) {
          return item;
        } else if (item.children) {
          const found = this.findParent(item.children, child);
          if (found) return found;
        }
      }
      return null;
    },
    getAllIds() {
      this.allIds = [];
      this.collectAllIds(this.roleMenuData, this.allIds);
    },
    collectAllIds(items, allIds) {
      items.forEach((item) => {
        allIds.push(item.id);
        if (item.children) {
          this.collectAllIds(item.children, allIds);
        }
      });
    },

    //Reset Page
    reset() {
      this.role = false;
      this.txtDescription = true;
      this.$refs.addRoleForm.reset();
      this.$refs.addRoleForm.resetValidation();
      this.projectSelected = false;
      this.addRoleBtn = true;
      this.selBusiness = [];
      this.selProjects = [];
      this.selectedCheckbox = [];
      this.projectLists = [];
      this.businessLists = [];
      this.finalSelList = [];
      this.getProjectList();
      this.addRoleButton();
    },
    //Get Project List
    getProjectList() {
      this.projectLists = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("ap/role_projectlist?User_Id=" + this.userId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
              };
            });
            this.projectLists = newArr;
            for (var i = 0; i < this.projectLists.length; i++) {
              this.allProjects.push(this.projectLists[i].ProjKey);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get Business List
    getBusinessList(ProjKeyList) {
      this.businessLists = [];
      this.allBusinessSelect = false;
      this.axios
        .get("ap/role_businesslist?User_Id=" + this.userId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.projectSelected = true;
            for (var i = 0; i < ProjKeyList.length; i++) {
              for (var key = 0; key < responseData.length; key++) {
                if (ProjKeyList[i] == responseData[key].ProjectId) {
                  this.businessLists.push(responseData[key]);
                }
              }
            }
            this.businessLists = [...new Set(this.businessLists)];
          } else {
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Selected Project
    projectSel() {
      if (this.selProjects.length == 0) {
        this.projectSelected = false;
      } else if (this.selProjects.length !== 0) {
        if (this.projectLists.length == this.selProjects.length) {
          this.allProjectSelect = true;
        } else {
          this.allProjectSelect = false;
        }
        this.projectSelected = true;
        this.getBusinessList(this.selProjects);
      } else {
        this.businessLists = [];
        this.allProjectSelect = false;
        this.projectSelected = false;
      }
    },
    //Business Selected
    businessSel() {
      this.addRoleButton();
      if (this.selBusiness.length == this.businessLists.length) {
        this.allBusinessSelect = true;
      } else {
        this.allBusinessSelect = false;
      }
    },
    //Select all projects
    allProjectSel() {
      if (this.allProjectSelect == true) {
        for (var i = 0; i < this.projectLists.length; i++) {
          this.selProjects.push(this.projectLists[i].ProjKey);
        }
        this.getBusinessList(this.selProjects);
      } else {
        this.selProjects = [];
        this.businessLists = [];
      }
    },
    //Select all Business
    allBusinessSel() {
      if (this.allBusinessSelect == true) {
        for (var i = 0; i < this.businessLists.length; i++) {
          this.selBusiness.push(this.businessLists[i]);
        }
        this.addRoleButton();
      } else {
        this.selBusiness = [];
      }
    },
    //Add Role Button Disable check
    addRoleButton() {
      if (this.selectInstaller != null || this.selectInstaller != undefined) {
        if (this.roleName != null || this.roleName != undefined) {
          if (this.menuSelected == true) {
            if (this.selBusiness.length !== 0) {
              this.addRoleBtn = false;
            }
          } else {
            this.addRoleBtn = true;
          }
        }
      }
    },
    //Reset Function
    resetFunction() {
      this.role = false;
      this.txtDescription = true;
      this.$refs.addRoleForm.reset();
      this.$refs.addRoleForm.resetValidation();
      this.projectSelected = false;
      this.addRoleBtn = true;
      this.selBusiness = [];
      this.selProjects = [];
      this.selectedCheckbox = [];
      this.projectLists = [];
      this.businessLists = [];
      this.finalSelList = [];
      this.getProjectList();
      this.addRoleButton();
    },
    //Get Masked Permission Data
    getMaskedPermissionData() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.axios
        .get("ap/role_maskingmodulelist?user_id=" + this.userId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            console.log("responseData", responseData);
            this.maskedPermissionList = responseData;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Mask checkbox change event
    permissionMaskorUnMask() {
      this.finalPermList = [];
      this.finalPermList = this.maskedPermissionList.map(item => ({
        ModuleId: item.ModuleId,
        Selected: item.Selected == true ? 1 : 0,
      }));
    },
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList !== null) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    onClickGroup(obj) {
      const element = document.getElementById(obj.name);
      element.scrollIntoView();
      document.documentElement.scrollTop = 0;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
  },
};
