import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  getBusinessList(call,obj) {
    return this.getData(`/cp/business_list`, call, obj);
  },

  postBusinessUpsert(call,obj,showMessage)
  {
    return this.getData(`/olp/re_business_upsert`, call, obj, showMessage);
  },

  getCompanyList(call, obj, showMessage) {
    return this.getData(`olp/re_company_list`, call, obj, showMessage);
  },


  //<-------Part List---------------->

  getPartClass(call, userId) {
    return this.getData(`olp/re_parts_partclass_search?user_id=${userId}`, call);
  },
  postPartSearchResult(call, obj, showMessage) {
    return this.getData(`/olp/re_parts_new_search`, call, obj, showMessage);
  },

  getPartDetails(call, userId, partId, showMessage) {
    return this.getData(`olp/re_parts_details_byid?user_id=${userId}&part_key=${partId}`, call, "", showMessage);
  },

  postPartAdd(call, obj, showMessage) {
    return this.getData(`/olp/re_parts_create`, call, obj, showMessage);
  },
  postPartUpdate(call, obj, showMessage) {
    return this.getData(`/olp/re_parts_Update`, call, obj, showMessage);
  },

  //         <-------Product List---------------->

  getUserListBusiness(call,userId){
    return this.getData(`/olp/re_business_list_by_user?user_id=${userId}`,call)
  },

  postProductList(call, obj, showMessage) {
    return this.getData(`/olp/re_order_parts`, call, obj, showMessage);
  },

  // <------Cart----->
  postPlaceOrder(call, obj, showMessage) {
    return this.getData(`/olp/re_order_cart_create`, call, obj, showMessage);

  },

  postCartUpdate(call, obj, showMessage) {
    return this.getData(`/olp/re_order_cart_update`, call, obj, showMessage);

  },
  getCartList(call, userId,buKey) {
    return this.getData(`/olp/re_order_cart_list?user_id=${userId}&bu_key=${buKey}`, call);
  },

  postSubmitOrder(call, obj, showMessage) {
    return this.getData(`/olp/re_order_create`, call, obj, showMessage);
  },

  postDefaultAddress(call,obj,showMessage){
    return this.getData(`/olp/re_order_shipping_address`, call, obj, showMessage);
  },
  postMyCartList(call, obj, showMessage) {
    return this.getData(`/olp/re_order_my_orders`, call, obj, showMessage);
  },

//  <---------Approve-------------->
postOrderReportData(call, obj, showMessage) {
  return this.getData(`/olp/re_order_report`, call, obj, showMessage);
},
postOrderListData(call, obj, showMessage) {
  return this.getData(`/olp/re_order_action`, call, obj, showMessage);
},
//  <---------Dashboard-------------->
postPartOrderSummary(call,obj,showMessage){
  return this.getData(`/olp/re_parts_order_summary`, call, obj, showMessage);
},
//  <---------my Orders-------------->
postMyOrderListData(call, obj, showMessage) {
  return this.getData(`/olp/re_order_my_orders`, call, obj, showMessage);
},


  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res?.data?.body?.message != undefined || res?.data?.body?.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data?.body?.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: this.isJson(res?.data?.body?.message) ? JSON.parse(res?.data?.body?.message).Message : "" || res?.data?.body?.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        if (this.isJson(res?.data?.body?.message))
          return JSON.parse(res?.data?.body?.message)
        else
          return res?.data?.body?.message
            ;
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else if (res?.data?.body?.message == null) {
      if (res?.data?.body !== null) {
        LoaderDialog.visible = false;
        return res?.data?.body;
      } else {
        LoaderDialog.visible = false;
        return JSON.parse('{"message":"NA"}');
      }
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res?.data?.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};

      }
    }


  },
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

};
