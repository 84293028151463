import EncryptUtility from "../../utility/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/addFav";
import adminService from "../../admin/js/admin-service";
import CommonDialog from "@/olp-components/common/common-dialog.vue";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      backSlash: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      cardsArray: [],
      rejectComments: "",
      max100Rule: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(?=.*\S).+$/.test(v) || "Please enter at least one text or number",
        (v) =>
          (v || "").length <= 100 ||
          "Max Length must be 100 characters or less",
      ],
      headerPartList: [
        {
          text: "Part Number",
          value: "part_num",
          class: 'primary customwhite--text',
        },
        { text: "Qty", value: "ord_qty",class: 'primary customwhite--text', },
        {
          text: "Available Qty",
          value: "avail_qty",
          class: 'primary customwhite--text',
        },
      ],
      partList: [],
      
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  async mounted() {
    this.getDataOnLoad();
  },
  methods: {
    getDataOnLoad() {
      this.getReportData();
      this.getPartListData();
    },
    async getReportData() {
      let reqObj = {
        user_id: this.userId,
        pass: 1,
        bu_key: 0,
        status_key: 0,
        sdate: "",
        edate: "",
      };
      let obj = { json: JSON.stringify(reqObj) };
      let responseData = await adminService.postOrderReportData(
        "post",
        obj,
        false
      );
      this.cardsArray = [
        {
          id: 1,
          icon: "mdi mdi-cog-outline",
          title: "New",
          data: responseData[0]?.New,
          background: "",
          iconColor: "primary",
        },
        {
          id: 2,
          icon: "mdi-check-circle-outline",
          title: "Approved",
          data: responseData[0]?.Approved,
          background: "",
          iconColor: "success",
        },
        {
          id: 3,
          icon: "mdi mdi-close-circle-outline",
          title: "Rejected",
          data: responseData[0]?.Rejected,
          background: "",
          iconColor: "error",
        },
      ];
    },
    async getPartListData() {
      let obj={
        user_id: this.userId,
        pass:1,
        bu_key: 0  
      }
      let submitObj = { json: JSON.stringify(obj) };
      let responseData= await adminService.postPartOrderSummary("post",submitObj,false)
      console.log(responseData)
      this.partList = responseData;
    },
    goToApprove(){
      this.$router.push({
        name: "approver",
      });
    }
  },

  components: {
    breadcrumbComp,
    CommonDialog,
  },
};
