<template>
    <div>
      <v-row justify="center">
        <v-col sm="12" md="6" lg="6">
          <v-dialog v-model="showDialog" max-width="500px">
            <v-card class="add-menu containerbox">
              <v-card-title class="text-subtitle-1 mx-auto">{{ message }}</v-card-title>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <template>
                  <v-btn color="primarytext" outlined dense class="ma-2 btn-style" elevation="1" @click="onClickOk">OK</v-btn>
                  <v-btn color="secondary" outlined dense class="ma-2 btn-style" elevation="1" @click="onClickCancel">Cancel
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
  </template>
  <script>
  export default {
    name: 'dialogBox',
    props: {
      showDialog: Boolean,
      message: String,
      onClickOkay: Function,
      onClickCancelled: Function
  
    },
    data() {
      return {
        fab: false,
      }
    },
    methods: {
      onClickOk() {
        this.$emit("onClickOkay");
      },
      onClickCancel() {
        this.$emit("onClickCancelled");
      },
  
    },
  }
  </script>
  