import { render, staticRenderFns } from "./scan-bin-part.vue?vue&type=template&id=040672ea&scoped=true&"
import script from "./js/scan-bin-part.js?vue&type=script&lang=js&"
export * from "./js/scan-bin-part.js?vue&type=script&lang=js&"
import style0 from "./scan-bin-part.vue?vue&type=style&index=0&id=040672ea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040672ea",
  null
  
)

export default component.exports