import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  // Add Starts here
  getOrderTypeList(call, obj) {
    return this.getData("/po/po_order_types", call, obj);
  },
  getStatusData(call, userId) {
    return this.getData(`/po/po_order_status?UserId=${userId}`, call);
  },
  getVendorData(call, obj, showMessage, disableLoader) {
    return this.getData("/co/vendor_get_data", call, obj, showMessage, disableLoader);
  },
  getReceiveData(call, obj, showMessage, disableLoader) {
    return this.getData("/po/po_rec_address", call, obj, showMessage, disableLoader);
  },
  validatePONumber(call, purno, ProjKey) {
    return this.getData(`/po/po_purchase_validate?Purno=${purno}&ProjId=${ProjKey}`, call);
  },
  postCarrierData(call, obj) {
    return this.getData("/po/po_service_levels", call, obj);
  },
  getValidateVendor(call, obj) {
    return this.getData("/co/vendor_vendno_check", call, obj);
  },
  postAddVendor(call, obj, showMessage) {
    return this.getData("/co/vendor_upsert", call, obj, showMessage);
  },
  postOrderTypeData(call, obj) {
    return this.getData("/po/po_linetype_list", call, obj);
  },
  postReturnSearch(call, obj, showMessage, disableLoader) {
    return this.getData("/po/po_receivepart_search", call, obj, showMessage, disableLoader);
  },
  matrixEntryOperations(call, obj) {
    return this.getData("/po/po_matrix_operations", call, obj);
  },
  postWarehouseData(call, obj) {
    return this.getData("/po/po_ppid_validate", call, obj);
  },
  postShipSearch(call, obj, showMessage, disableLoader) {
    return this.getData("/po/po_shippart_search", call, obj, showMessage, disableLoader);
  },
  postPoSubmitUpsert(call, obj, showMessage) {
    return this.getData("po/po_order_create", call, obj, showMessage);
  },
  getPurchaseOrderDetails(call, userId, purchaseOrder) {
    return this.getData(`/po/po_get?UserId=${userId}&po_id=${purchaseOrder}`, call);
  },
  getLineItemData(call, userId, line_id) {
    return this.getData(`/po/po_lineitem_details?UserId=${userId}&line_id=${line_id}`, call);
  },
  postAction(call, obj, showMessage) {
    return this.getData("/po/po_order_actions_del_hld", call, obj, showMessage);
  },
  postVendorListSearch(call, obj) {
    return this.getData("co/vendor_get_data", call, obj);
  },
  getVendorDataById(VenId,userId) {
    let call = "get";
    return this.getData(`/po/vendor_data_by_id?VenId=${VenId}&UserId=${userId}`, call);
  },
  validateVendorDataById(userId, VendNo) {
    let call = "get";
    return this.getData(`po/vendno_validate?UserId=${userId}&VendNo=${VendNo}`, call);
  },
  countryList(call) {
    return this.getData("/cp/country_state_details", call);
  },
  postVendorUpsert(call, obj, showMessage) {
    return this.getData("/po/vendor_upsert", call, obj, showMessage);
  },
  deleteVendorDataById(VendId) {
    let call = "get";
    return this.getData(`/po/delete_vendor_by_id?VendId=${VendId}`, call);
  },
  getPOActionLog(po_key, UserId) {
    let call = "get";
    return this.getData(`/po/po_actionlog?po_key=${po_key}&UserId=${UserId}`, call, "");
  },
  getWarrentyData(call, userId) {
    return this.getData(`/po/po_warrantytype?UserId=${userId}`, call);
  },
  postPoTempUpsert(call, obj, showMessage) {
    return this.getData("/po/po_temp_upsert", call, obj, showMessage);
  },
  addBookMark(call, userId, po_id, showMessage) {
    return this.getData(`/po/po_order_bookmark?UserId=${userId}&po_id=${po_id}&Pass=2`, call, "", showMessage);
  },
  getDeleteBookmarkPO(call, userId, po_id, showMessage) {
    return this.getData(`/po/po_order_bookmark?UserId=${userId}&po_id=${po_id}&Pass=3`, call, "", showMessage);
  },
  getOrderDraftById(call, userId, po_id) {
    return this.getData(`/po/po_temp_listbyid?UserId=${userId}&po_id=${po_id}&Pass=2`, call);
  },
  postPoUpdate(call, obj, showMessage) {
    return this.getData("/po/po_order_update", call, obj, showMessage);
  },
  getPurchaseOrderPickedData(call, po_id, line_id, user_id) {
    return this.getData(`po/po_pick_details?po_key=${po_id}&line_num=${line_id}&UserId=${user_id}`, call, "");
  },
  getPurchaseOrderReceivedData(call, po_id, line_id, user_id) {
    return this.getData(`/po/po_receipt_details?po_key=${po_id}&line_num=${line_id}&UserId=${user_id}`, call, "");
  },
  getPurchaseOrderShippedData(call, po_id, line_id, user_id) {
    return this.getData(`po/po_shipment_details?po_key=${po_id}&line_num=${line_id}&UserId=${user_id}`, call, "");
  },
  postPODelete(call, po_id, user_id, showMessage) {
    return this.getData(`po/po_temp_delete?UserId=${user_id}&po_id=${po_id}`, call, showMessage);
  },
  managePOTypesList(obj, showMessage) {
    let call = "post";
    return this.getData("/po/po_manage_po_types", call, obj, showMessage);
  },
  async getData(url, call, obj, showMessage, disableLoader) {

    if (!disableLoader) {
      var LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    } else {
      LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
    }
    let res = {};
    // Get Call
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    // Post Calle
    else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
        return error;
      });
    }
    if (res?.data?.body?.message != undefined || res?.data?.body?.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.body.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: JSON.parse(res?.data?.body?.message).Result[0].Message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.body?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.body?.message);
      }
    } else if (res?.message) {
      return res?.response?.status;
    } else if (res.data.body.message == null) {
      LoaderDialog.visible = false;
      store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      return JSON.parse('{"message":"NA"}');
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data?.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res?.data?.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return JSON.parse('{"message":"NA"}');
      }
    }
  },
};
